import {callApi, getDefaultEditActions, addExtraFilterToQuery, pushUrlToBrowserHistory} from './helpers'
import {redirect} from 'redux-first-router'
import {addUrlQuery} from "./uiActions";

export const changeListYear = (newParams) => (dispatch) => {
    dispatch(addUrlQuery(newParams))
}

export const loadUrbumi = (query) => (dispatch, getState) => {
  const mapSize = getState().gui['urbumi_saraksts_karte_size']
  return dispatch(callApi({
      viewName: 'urbumi_list',
      crudMethod: 'listPaging',
      crudParam: addExtraFilterToQuery(query, mapSize)
  }))
}

export const loadMerijumi = (query, payload) => (dispatch, getState) => {
    const filters = query.f ? JSON.parse( query.f) : {}
    filters.id = Number(query.payload.id)
    filters.gads = Number(query.gads)
    return dispatch(callApi({
        viewName: 'urbuma_merijums_view',
        crudMethod: 'listPaging',
        // crudParam: {params: {...query.query}, ps: 10, f: query.f, p: query.p || 1}
        crudParam: {...query, f: JSON.stringify(filters)}
    }))
}

export const loadParaugi = (query, payload) => (dispatch, getState) => {
    const filters = query.f ? JSON.parse( query.f) : {}
    filters.id = Number(query.payload.id)
    return dispatch(callApi({
        viewName: 'urbuma_paraugi_list',
        crudMethod: 'listPaging',
        crudParam: {...query, f: JSON.stringify(filters)}
    }))
}

export const loadNoverotieLimeni = (query, payload) => (dispatch, getState) => {
    const filters = query.f ? JSON.parse( query.f) : {}
    filters.id = Number(query.payload.id)
    filters.gads = Number(query.gads)
    return dispatch(callApi({
        viewName: 'urbuma_noverotie_limeni_view',
        crudMethod: 'listPaging',
        crudParam: {...query, f: JSON.stringify(filters)}
    }))
}

export const loadIeguvesLimeni = (query, payload) => (dispatch, getState) => {
    const filters = query.f ? JSON.parse( query.f) : {}
    filters.id = Number(query.payload.id)
    filters.gads = Number(query.gads)
    return dispatch(callApi({
        viewName: 'urbuma_ieguves_limeni_view',
        crudMethod: 'listPaging',
        crudParam: {...query, f: JSON.stringify(filters)}
    }))
}

export const urbumiActions = getDefaultEditActions({
    actionTypeName : 'urbums',
    stateName: 'urbums',
    apiViewName: 'urbumi_view',
    hasKoordsField: true,
    fetchArgsToCrudParams: ({id, gads}, getState) => {
        const { query } = getState().location
        let y = gads
        if (!y) {
            if (!query) {
                y = new Date().getFullYear()
            } else y = query.gads
        }
        return ['id', id, 'gads', y]
    }
})

export const merijumsActions = getDefaultEditActions({
    actionTypeName: 'merijums',
    stateName: 'merijums',
    apiViewName: 'urbuma_merijums_edit',
    afterSave: (dispatch, getState, result, savingNew) => {
        dispatch(merijumsActions.fetchAct({numurs: result.urbumi_id, id: result.id}))
    }

})

export const paraugsActions = getDefaultEditActions({
    actionTypeName: 'urbuma_paraugs',
    stateName: 'urbuma_paraugs',
    apiViewName: 'urbuma_paraugs_edit',
    afterSave: (dispatch, getState, result, savingNew) => {
        if(savingNew) {
            const {type, payload, query} = getState().location
            dispatch(redirect({
                type,
                payload: payload ? {...payload, id: result.id} : {id: result.id},
                query
            }))
        } else {
            dispatch({
                type: 'urbuma_paraugs_clear'
            })
            dispatch(paraugsActions.fetchAct({numurs: result.urbumi_id, id: result.id}))
        }
    }
})
  
export const urbumsSelected = (id) => {
   return { type: 'urbumi_selected', payload:id }
}

export const urbumsDoubleSelected = (id) => (dispatch, getState) => {
  //remember prev url
  // pushUrlToBrowserHistory(getState)
  // dispatch(redirect( {
  //     type: 'loc_urbums',
  //     payload: {
  //         id
  //     }
  // }))
}

export const loadUrbumuAtradnes = (query) => (dispatch, getState) => {
  const mapSize = getState().gui['urbumu_atradnes_saraksts_karte_size']
  return dispatch(callApi({
      viewName: 'urbumi_atradnes_list',
      crudMethod: 'listPaging',
      crudParam: addExtraFilterToQuery(query, mapSize)
  }))
}

export const urbumuAtradnesActions = getDefaultEditActions({
    actionTypeName : 'urbumu_atradne',
    stateName: 'urbumu_atradne',
    apiViewName: 'urbumi_atradnes_view'
})
  
export const urbumuAtradneSelected = (id) => {
   return { type: 'urbumi_atradnes_selected', payload:id }
}

export const urbumuAtradneDoubleSelected = (id) => (dispatch, getState) => {
  //remember prev url
  pushUrlToBrowserHistory(getState)
  dispatch(redirect( {
      type: 'loc_urbumu_atradne',
      payload: {
          id
      }
  }))
}

export const urbumuAtradneUrbumsSelected = (payload) => {
  return { type: 'urbumi_atradnes_urbums_selected', payload }
}

export const urbumuIeguveSelected = (id) => {
  return { type: 'urbumu_ieguves_selected', payload:id }
}

export const urbumuIeguveDoubleSelected = (id) => (dispatch, getState) => {
 //remember prev url
 pushUrlToBrowserHistory(getState)
 dispatch(redirect( {
     type: 'loc_urbumu_ieguve',
     payload: {
         id
     }
 }))
}

export const loadUrbumuIeguves = (query) => (dispatch, getState) => {
  const mapSize = getState().gui['urbumu_ieguves_saraksts_karte_size']
  return dispatch(callApi({
      viewName: 'urbumi_ieguves_vietas_list',
      crudMethod: 'listPaging',
      crudParam: addExtraFilterToQuery(query, mapSize)
  }))
}

export const urbumuIeguvesActions = getDefaultEditActions({
    actionTypeName : 'urbumu_ieguve',
    stateName: 'urbumu_ieguve',
    apiViewName: 'urbumi_ieguves_vietas_view',
    fetchArgsToCrudParams: (obj, getState) => {
        const query = getState().location.query ?? {}
        const filters = query.f ? JSON.parse(query.f) : {}

        let gadsParams = []
        let urbIdParams = []
        let pKodsParams = []

        if (filters.gads && filters.gads_comparator) {
            gadsParams = [
                'gads', filters.gads, 
                'gads_comparator', filters.gads_comparator,
            ]
        }

        if (filters.urb_id) {
            urbIdParams = ['urb_id', filters.urb_id]
        }

        if (filters.p_kods) {
            pKodsParams = ['p_kods', filters.p_kods]
        }

        const queryParams = [
            'id', obj.id,
            ...gadsParams,
            ...urbIdParams, 
            ...pKodsParams
        ];

        return queryParams
    }
})

export const urbumuIeguveRowSelected = (payload) => {
  return { type: 'urbumi_ieguve_urbums_selected', payload }
}
