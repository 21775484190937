import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import Form from '../forms/Form'
import {virszemesUdensObjektsSlodzesActions} from '../../actions/virszemesUdensObjektiActions'
import NextPrevBox from "../forms/NextPrevBox";

const getFormFieldCfgs = ({ kodif, user }) => () => {
  const fields = [{
    name: 'gads_sagatavots',
    renderClassName: "bold",
  }, {
    name: 'periods',
    renderClassName: "bold"
  }, {
    name: 'punktveida',
  }, {
    name: 'izkliedeta',
  }, {
    name: 'hidro',
  }, {
    name: 'citas',
  }]

  const irLietotajsAutentificets = user.avots !== 'G'
  if(irLietotajsAutentificets){
    fields.push({
      name: 'veidi',
    }, {
      name: 'virzitajspeki',
    }, {
      name: 'ietekmes',
    })
  }

  fields.push({
      name: 'piezimes',
      type: 'textarea'
  })

  return fields
}

class SlodzesComp extends Component {

  render() {

    const {virszemesUdensObjektsSlodzes, user, getFormFieldCfgsFunc, kodif} = this.props

    const { selectedItem } = virszemesUdensObjektsSlodzes

    // loading while we have no virszemesUdensObjekts
    if (!selectedItem) {
      return (<Loading/>)
    }

    const onNextPrevYear = (gads) => {
      this.props.fetchAct({ ...selectedItem, gads })
    }

    // we cache form field cfgs
    // reload form fields if virszemesUdensObjekts changes
    if(!this.formFieldCfgs || virszemesUdensObjektsSlodzes.id !== this.prevUoId){
      const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs({ kodif, user })
      this.formFieldCfgs = f({
        kodif,
        user
      })
      this.prevUoId = selectedItem.id
    }

    return <div className="form-container">
      <NextPrevBox currentValue={selectedItem.gads} onChange={onNextPrevYear}></NextPrevBox>
      <Form
        editable={false}
        dataObj={selectedItem}
        formFieldCfgs={this.formFieldCfgs}
        onSubmit={this.props.saveAct}
        onReset={this.props.stopEditAct}
        onStartEdit={this.props.startEditAct}
        // formWrapperComp={Row}
        metadataFormCfg="virszemes_udens_objekts_slodzes_tab_view"
        // tiesibasLabot="BAIS_VU_PAMAT_LABOT"
      />
    </div>
  }
}

const mapStateToProps = ({virszemesUdensObjektsSlodzes, kodif, metadata, user}) => ({virszemesUdensObjektsSlodzes, kodif, metadata, user})
const VirszemesSlodzes =  connect(mapStateToProps, { ...virszemesUdensObjektsSlodzesActions })(SlodzesComp)
export default VirszemesSlodzes

