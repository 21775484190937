export const DEFAULT_SARAKSTS_PAGE_SIZE = 100

export const TIME_FORMAT = 'HH:mm'

export const DATE_FORMAT = 'yyyy.MM.dd'

export const DATE_TIME_FORMAT = 'yyyy.MM.dd HH:mm'

export const YEAR_FORMAT = 'yyyy'

export const TEXT_ERR_DERIGIE = 'Izvēlētai atradnei vai atradnes daļai pamatinformācijā nav aizpildīti "Derīgie izrakteņi" vai arī tie nav izmantojami šajā formā'

export const OTHER_VIEWNAME = 'other'

export const ATRADNES_PAZEMES_UDENU_OBJEKTI_NOT_FOUND = 'no'