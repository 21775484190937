import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import Form from '../forms/Form'
import {virszemesUdensObjektsRiskiActions} from '../../actions/virszemesUdensObjektiActions'
import {getBooleanFormatter} from "../grid/ColumnHelpers";
import NextPrevBox from "../forms/NextPrevBox";

const getFormFieldCfgs = ({ metadata, kodif }) => () => {
  return [
    {
      name: 'gads_sagatavots',
      renderClassName: "bold",
    },
    {
      name: 'periods',
      renderClassName: "bold"
    }, {
      name: 'biogeni',
      renderer: (val) => {
        if (val === 'x') {
          return 'Biogēnu problēma'
        } if (val) {
          return val.toUpperCase()
        }
        return 'Nē'
      }
    }, {
      name: 'bistamas',
      renderer: (val) => {
        if (val === '?') {
          return val
        }
        return getBooleanFormatter()(!!val)
      }
    }, {
      name: 'hidro',
    }, {
      name: 'parrobezu',
    }, {
      name: 'pludi',
    }, {
      name: 'cits',
    }, {
      name: 'piezimes',
      type: 'textarea'
    }
  ]
}

class RiskiComp extends Component {

  render() {

    const {virszemesUdensObjektsRiski, user, getFormFieldCfgsFunc, kodif,
      metadata} = this.props

    const { selectedItem } = virszemesUdensObjektsRiski

    // loading while we have no virszemesUdensObjekts
    if (!selectedItem) {
      return (<Loading/>)
    }

    const onNextPrevYear = (gads) => {
      this.props.fetchAct({ ...selectedItem, gads })
    }

    // we cache form field cfgs
    // reload form fields if virszemesUdensObjekts changes
    if(!this.formFieldCfgs || virszemesUdensObjektsRiski.id !== this.prevUoId){
      const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs({ metadata, kodif })
      this.formFieldCfgs = f({
        kodif,
        user
      })
      this.prevUoId = selectedItem.id
    }

    return <div className="form-container">
      <NextPrevBox currentValue={selectedItem.gads} onChange={onNextPrevYear}></NextPrevBox>
      <Form
        editable={false}
        dataObj={selectedItem}
        formFieldCfgs={this.formFieldCfgs}
        onSubmit={this.props.saveAct}
        onReset={this.props.stopEditAct}
        onStartEdit={this.props.startEditAct}
        // formWrapperComp={Row}
        metadataFormCfg="virszemes_udens_objekts_risks_un_iznemumi_tab_view"
        // tiesibasLabot="BAIS_VU_PAMAT_LABOT"
      />
    </div>
  }
}

const mapStateToProps = ({virszemesUdensObjektsRiski, kodif, metadata, user}) => ({virszemesUdensObjektsRiski, kodif, metadata, user})
const VirszemesRiski =  connect(mapStateToProps, { ...virszemesUdensObjektsRiskiActions })(RiskiComp)
export default VirszemesRiski

