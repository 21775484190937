import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import GridWithForm from '../grid/GridWithForm'
import {datnuApmainaActions, datnuApmainaSelected, loadDatnuApmaina} from '../../actions/apmainaActions';
import { getDateFilterCfg, getTextFilterCfg, dateTimeFormatter} from '../grid/ColumnHelpers'
import { getDefaultTypeaheadOptionsFromKodif} from "../forms/FormHelpers";
import {addUrlQuery} from '../../actions/uiActions'
import {showErrorMsg} from '../../actions/uiActions'
import * as api from "../../api";
import {Button} from "reactstrap";
import {filesize} from "filesize";

const getColumns = ({kodif}) => {
  return [
    {
      dataField: 'file_reg_num',
      text: 'Reģistrācijas numurs',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'filename',
      text: 'Files',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'nosaukums',
      text: 'Pārskata nosaukums',
      filterCfg: getTextFilterCfg()
    },{
      dataField: 'size',
      text: 'Izmērs',
      filterCfg: getTextFilterCfg(),
      formatter: (fieldValue) => filesize(fieldValue, {separator: ","}),
    },{
      dataField: 'autors',
      text: 'Autors',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'statuss',
      text: 'Statuss',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'lietotajs',
      text: 'Lietotājs',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'iesniegsanas_laiks',
      text: 'Iesniegšanas laiks',
      formatter: dateTimeFormatter,
      filterCfg: getDateFilterCfg()
    }
  ]
};

const getFormFieldCfgs = ({kodif}) => {
  return [
    {
      name: 'file_reg_num',
      label: 'Reģistrācijas numurs',
      editable: false
    }, {
      name: 'nosaukums',
      label: 'Pārskata nosaukums',
      required: true
    }, {
      name: 'autors',
      label: 'Autors',
      required: true
    }, {
      name: 'informacija',
      label: 'Iestāde/uzņēmums u.c. informācija',
    }, {
      name: 'lietotajs',
      label: 'Lietotājs',
      editable: false
    }, {
      name: 'parskata_veids',
      label: 'Pārskata veids',
      type: 'autocomplete',
      required: true,
      autocompleteCfg: {
        options: getDefaultTypeaheadOptionsFromKodif(kodif.PARSKATA_VEIDS),
        multiple: false,
        keyField: 'parskata_veids',
      },
    }, {
      name: 'statuss',
      label: 'Statuss',
      editable: false
    }, {
      // TODO kas par datumu?
      name: 'sagatavosanas_laiks',
      label: 'Sagatavošanas laiks',
      renderer: dateTimeFormatter,
      editable: false
    }, {
      name: 'iesniegsanas_laiks',
      label: 'Iesniegšanas laiks',
      renderer: dateTimeFormatter,
      editable: false
    }, {
      name: 'arhivesanas_laiks',
      label: 'Arhivēšanas laiks',
      renderer: dateTimeFormatter,
      editable: false
    }, {
      name: 'dzesanas_laiks',
      label: 'Dzēšanas laiks',
      renderer: dateTimeFormatter,
      editable: false
    }, {
      name: 'file_info_id',
      label: 'Faila ielāde',
      type: 'largefile',
      required: true,
      toolTip: "Augšupielādei piemērots viens fails (vēlams arhivēts .zip vai .rar formātā) līdz 5 GB.\n\nFails ar statusu “Sagatave” sistēmā tiek uzglabāts 7 kalendārās dienas. Pēc termiņa beigām sagatave tiek automātiski dzēsta."
    }, {
      name: 'size',
      label: 'Faila izmērs',
      type: 'text',
      renderer: (fieldValue) => filesize(fieldValue, {separator: ","}),
      hiddenFunc: ({ currentValues }) => currentValues.size === null,
      editable: false
    }
  ]
}

class DatnuApmaina extends Component {

  doIesniegt = (data) => () => {

    api.saveApi('do_datnu_apmaina_iesniegt', data)
      .then((responseData) => {
        this.props.loadDatnuApmaina(this.props.query).then(() => {
          if (data.id) {
            this.props.selectedAct(data.id)
          }
        })
      })
  }
  
  doArhivet = (data) => () => {
    api.saveApi('do_datnu_apmaina_arhivet', data)
      .then((responseData) => {
        this.props.loadDatnuApmaina(this.props.query).then(() => {
          if (data.id) {
            this.props.selectedAct(data.id)
          }
        })
      })
  }

  render() {
    const { datnu_apmaina, saveAct, stopEditAct, startEditAct, deleteAct, user } = this.props

    if(datnu_apmaina.loading) {
      return (<Loading/>)
    }

    if(!this.columns){
      this.columns = getColumns({
        kodif: this.props.kodif
      })
    }

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({
        kodif: this.props.kodif
      })
    }

    const renderIesniegtButton = (data) => {
      const label = 'Iesniegt'
      if (!this.props.datnu_apmaina.selectedItem || !this.props.datnu_apmaina.selectedItem.id || this.props.datnu_apmaina.editable || ['Iesniegts', 'Arhivēts'].includes(this.props.datnu_apmaina.selectedItem.statuss)) {
        return <></>
      } else if (this.props.datnu_apmaina.selectedItem.loading) {
        return <Button color="default">{ label }</Button>
      } else {
        return <Button color="success" onClick={this.doIesniegt(data)}>{ label }</Button>
      }
    }

    const renderArhivetButton = (data) => {
      const label = 'Arhivēt'
      if (!this.props.datnu_apmaina.selectedItem || !this.props.datnu_apmaina.selectedItem.id || this.props.datnu_apmaina.editable || ['Sagatave', 'Arhivēts'].includes(this.props.datnu_apmaina.selectedItem.statuss)) {
        return <></>
      } else if (this.props.datnu_apmaina.selectedItem.loading) {
        return <Button color="default">{ label }</Button>
      } else {
        return <Button color="warning" onClick={this.doArhivet(data)}>{ label }</Button>
      }
    }

    return (
          <GridWithForm
            {...this.props}
            columns={this.columns}
            formFieldCfgs={this.formFieldCfgs}
            storeObj={this.props.datnu_apmaina}
            saveAct={saveAct}
            stopEditAct={stopEditAct}
            startEditAct={this.props.datnu_apmaina.selectedItem?.statuss === "Sagatave" || user.lomas.includes("ADMIN") ? startEditAct : null}
            deleteAct={deleteAct}
            formWidthGuiOption="datnu_apmaina_form_width"
            metadataFormCfg="datnu_apmaina_view"
            tiesibasLabot="DATNU_APMAINA_LABOT"
            handleDelete={null}
            onDelete={user.tiesibas.includes('DATNU_APMAINA_DZEST') ? this.props.deleteAct : null}
            editable={false}
            extraElementsFormButtons={[renderIesniegtButton(this.props.datnu_apmaina.selectedItem), renderArhivetButton(this.props.datnu_apmaina.selectedItem)]}
          />
    )
  }
}

const mapStateToProps = ({datnu_apmaina, kodif, location, user}) => ({datnu_apmaina, kodif, query: location.query, user})

export default connect(mapStateToProps, {
  selectedAct : datnuApmainaSelected,
  loadDatnuApmaina,
  addUrlQuery,
  ...datnuApmainaActions,
  showErrorMsg})(DatnuApmaina)