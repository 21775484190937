import React from 'react'
import Link from 'redux-first-router-link'
import { redirect } from 'redux-first-router'
import WaterBottleIcon from './components/WaterBottleIcon'
import {
    atradnes, atradneActions, atradnesDalaActions, atrasanasActions,
    dalaAtrasanasActions, geologijaActions, dalaGeologijaActions, kvalitateActions, dalaKvalitateActions, uzzinasActions,
    fetchAtradnesDoks, fetchAtradnesDalaDoks, krajumiActions, dalaKrajumiActions, izmainasActions,
    dalaIzmainasActions
} from './actions/atradnesActions'
import { loadKodifikatorsIfNeeded, loadKodifikatoraIeraksti, loadKodifikatoruGrupasIfNeeded } from './actions/kodifikatorsActions'
import { loadUploadSizeLimitIfNeeded } from './actions/uploadSizeLimitActions'
import { loadAdresati } from './actions/adresatsActions'
import { guiSet, loadMetadataIfNeeded } from './actions/uiActions'
import { loadArcgisServicesIfNeeded } from './actions/arcgisActions'
import { loadLietotaji } from './actions/lietotajsActions'
import { loadWiseParametri } from './actions/wiseActions'
import { loadParisValidacijasRezultati } from './actions/parisActions'
import { loadUzzinas, uzzinaActions } from './actions/uzzinasActions'
import { loadDatnuApmaina } from './actions/apmainaActions'
import {
    loadAtradneAudit,
    loadAudit,
    loadAtradneDalaAudit,
    loadAuditsAtradnesAtskaite,
    loadAuditPersonasDatuAudits
} from './actions/auditActions'
import { loadPases, paseActions, loadLimiti, loadLicences, limitsActions, licenceActions, loadLimituAtlikumi } from './actions/dokumentacijaActions'
import {
    loadGeofondParskats,
    loadGeofondParskatsMap,
    parskatsActions,
    loadGeofondRubrikas,
    rubrikaActions
} from './actions/geofondActions'
import { loadKrajumi, krajumsActions } from './actions/krajumsActions'
import { izmainaActions, izmainaActionsGrouped, loadIzmainas, loadBilance, loadKomersantaIzmainas } from './actions/izmainaActions'
import { loadPielikumi } from './actions/pielikumiActions'
import { loadPazinojumi, getJaunuPazinojumuSkaits } from './actions/pazinojumiActions'
import {
    virszemesUdensObjekti,
    virszemesUdensObjektiActions,
    virszemesUdensObjektsAtrasanasActions,
    virszemesUdensObjektsEkoActions, virszemesUdensObjektsKimActions,
    virszemesUdensObjektsRiskiActions, virszemesUdensObjektsSlodzesActions,
    uoPasakumiActions
} from './actions/virszemesUdensObjektiActions'
import {
    pazemesUdensObjekti,
    pazemesUdensObjektiActions,
    pazemesUdensObjektiKvantitateActions,
    pazemesUdensObjektiRiskiUnIznemumiActions,
    pazemesUdensObjektsAtrasanasActions,
    pazemesUdensObjektiSlodzesActions,
    pazemesUdensObjektiPapildinformacijaActions,
    pazemesUdensObjektsKimActions,
    uoPazemesPasakumiActions
} from './actions/pazemesUdensObjektiActions'
import { upjuBaseinuApgabali } from './actions/upjuBaseinuApgabaliActions'
import {
    getAtradneNosaukumsFromNumurs,
    getAtradneNosaukumsFromId,
    getUdensObjNosaukumsForKods,
    getStacijasNosaukumsFromId,
    getDzeramaUdensSatecesBaseinsNosaukumsFromId
} from './api'
import { loadPasakumuKlasif, pasakumi, pasakumsVirszemesActions, pasakumsPazemesActions } from './actions/pasakumiActions'
import { loadRaditajiEkologija, loadRaditajiKimiska, loadRaditajiQl } from './actions/raditajiActions'
import {
    loadUrbumi,
    loadMerijumi,
    loadNoverotieLimeni,
    loadIeguvesLimeni,
    urbumiActions,
    merijumsActions,
    loadUrbumuAtradnes,
    urbumuAtradnesActions,
    loadUrbumuIeguves,
    urbumuIeguvesActions,
    loadParaugi, paraugsActions
} from './actions/hidrogeoActions'
import _ from 'lodash'
import { loadBaisVirszemesImports, loadBaisPazemesImports, loadHidrogeoImports } from "./actions/importsActions";
import { loadGeofondImports } from "./actions/geofondImportsActions";
import {
    loadUdensAi, loadUdensNov, loadUdensNv,
    loadUdensParskati, udensParskatsActions,
    loadParisVkn
} from "./actions/udens2Actions";
import { loadNobides } from "./actions/nobidesActions";
import { loadStacijasMeteo, stacijaActions } from "./actions/stacijasActions";
import { loadAdIeraksti, adIerakstsActions } from './actions/adIerakstiActions'
import { adServissActions, loadAdServisi, loadDataSources } from './actions/adServisiActions'
import { loadAdAudit } from './actions/adAuditActions'
import { loadVvisKodifikatorsIfNeeded } from "./actions/vvisActions";
import { vnkIesniegumi, vnkIesniegumsActions } from "./actions/vnkActions";
import { loadTroksni } from "./actions/troksniActions";
import { loadSistemasPazinojumi } from "./actions/sistemasPazinojumiActions";
import { loadFonaSlanuAdministresanaSaraksts, loadKartesFonaSlanuAdministresanaSarakstsByLocation } from './actions/fonaSlanuAdministresanaActions'
import { dzeramaUdensSatecesBaseinsActions, loadDzeramaUdensSatecesBaseini } from './actions/dzeramaUdensSatecesBaseiniActions'
import { loadUdensapgadesSistemas } from './actions/udensapgadesSistemasActions'
import { loadPazemesSlodzuButiskumi, loadVirszemesSlodzuButiskumi, pazemesSlodzuButiskumiActions, virszemesSlodzuButiskumiActions } from './actions/slodzuButiskumsActions'
import { loadPazemesRiskiUnIznemumi, loadVirszemesRiskiUnIznemumi, pazemesRiskiUnIznemumiActions, virszemesRiskiUnIznemumiActions } from './actions/riskiUnIznemumiActions'

let lastQuery = {}
let lastType = ""
let listTimerId = null
let lastForcedAtTime = 1
let forced = false
let loadAtradneAuditNextTime = false
let loadAtradneDalaAuditNextTime = false

// these are routes for frontend to display only a specific subApp navigation links on left side
export const subAppRoutes = {
    bais: 'loc_sakums_bais',
    zdz: 'loc_sakums_zdz',
    vnk: 'loc_sakums_vnk'
}

export const subAppRouteNames = {
    bais: 'BAIS',
    zdz: 'ZDzIS',
    vnk: 'VNK',
    hidro: 'Hidroģeoloģija',
    troksni: 'Trokšņu karte',
    udens2: 'Ūdens-2',
    meteo: 'Novērojumu stacijas',
    ad: 'AD'
}

// if coming with path for specific subApp, we set this global variable
export let CURRENT_SUBAPP;

// return sakums location considering current subApp
export const getSakumsLocation = () => CURRENT_SUBAPP ? subAppRoutes[CURRENT_SUBAPP] : 'loc_sakums'

const newListFetchNeeded = ({ newQuery, newType, prevQuery, prevType }) => {
    if (prevType !== newType || (newQuery && newQuery.exportOds === true)) {
        return true
    }
    const newQueryN = newQuery ? newQuery : {}
    const prevQueryN = prevQuery ? prevQuery : {}
    const propsNeededForFetch = ['p', 'ps', 'f', 's', 'b', 'ef', 'pno', 'plidz']
    // map coords and zoom is important only if bbox filter (b) is enabled
    if (newQueryN.b === 't') {
        propsNeededForFetch.push('x', 'y', 'z')
    }
    const diffs = propsNeededForFetch.filter(key => {
        return String(newQueryN[key]) !== String(prevQueryN[key])
    })
    return diffs.length > 0
}

export const fetchListIfNeededTimeouted = (fetchAction) => (dispatch, getState) => {
    // we should figure out whether new fetch is needed
    // lets compare with the last query,
    // we can't use prev location - it don't get updated when old and new urls are equal
    // for not too often calls we wait for a 0.4 sec

    //fetch is always needed if forced
    const forcedFromGui = getState().gui.force_reload
    const { payload } = getState().location
    const forcedFromRedirect = payload && payload.forcedAtTime && payload.forcedAtTime !== lastForcedAtTime
    if (forcedFromRedirect) {
        lastForcedAtTime = payload.forcedAtTime
    }
    forced = forced || forcedFromGui || forcedFromRedirect
    const timeOutFunc = () => {
        const { query: newQuery, type: newType, payload: newPayload } = getState().location
        const newFetch = newListFetchNeeded({ newQuery, newType, prevQuery: lastQuery, prevType: lastType })
        lastQuery = { ...newQuery }
        lastType = newType
        listTimerId = null
        if (forced || newFetch) {
            dispatch(fetchAction({ ...newQuery, payload: newPayload }))
            forced = false
        }
    }
    if (listTimerId) {
        clearTimeout(listTimerId)
    }
    listTimerId = setTimeout(timeOutFunc, 400)
}

const checkEditable = (stateName, state) => {
    if (typeof stateName === "function") {
        return stateName(state)
    } else if (state[stateName].editable === true) {
        return 'Ir nesaglabātas izmaiņas. Vai aiziet no esošās lapas?'
    }
    return undefined
}

const confirmLeave = (stateName) => (state, action) => {
    if (action.type === state.location.type && _.isEqual(action.payload, state.location.payload)) {
        return undefined
    }
    if (Array.isArray(stateName)) {
        return stateName.reduce((prev, curr) => prev || checkEditable(curr, state), undefined)
    } else {
        return checkEditable(stateName, state)
    }
}

//this object will hold last executed query, for each action
//used in breadcrumb to navigate back on specific state e.g. map view, search..
export const lastQueryForEachAction = {}

export const viewNameFromMetadata = ({ viewname, metadata }) => viewname === 'from_gis' ? 'Darbvirsmas ĢIS' : metadata[viewname] && metadata[viewname].nosaukums ? metadata[viewname].nosaukums : viewname

export const getLinkFromViewname = (props) => {
    const { viewname, entity_id, metadata } = props
    let extraParams = {}
    let locationType;
    Object.keys(routesMap).filter(k => routesMap[k].viewname).forEach(k => {
        const routeViewName = routesMap[k].viewname
        if (typeof routeViewName === "function") {
            const routeViewNameParams = routeViewName(props)
            //if route viewname returns params then we have found location
            if (routeViewNameParams) {
                locationType = k
                extraParams = routeViewNameParams
            }
        } else if (viewname === routeViewName) {
            locationType = k
            if (entity_id) {
                extraParams = { payload: { id: entity_id } }
            }
        }
    })
    if (locationType) {
        return <Link to={{ type: locationType, ...extraParams }}>
            {viewNameFromMetadata({ viewname, metadata })}
        </Link>
    } else {
        return viewNameFromMetadata({ viewname, metadata })
    }
}

// a single config object for :
// redux-first-router,
// breadcrumb
// AppSidebarNav
// component rendering in defaultlayout
export const routesMap = {
    loc_sakums: {
        path: '/',
        name: 'Sākums',
        skipTitle: true,
    },
    loc_sakums_zdz: {
        path: '/zdz',
        name: 'Sākums',
        skipTitle: true,
    },
    loc_sakums_bais: {
        path: '/bais',
        name: 'Sākums',
        skipTitle: true,
    },
    loc_sakums_vnk: {
        path: '/vnk',
        name: 'Sākums',
        skipTitle: true,
    },
    loc_login: {
        path: '/login',
        publicRoute: true,
        skipTitle: true,
    },
    loc_zemes_dziles: {
        subApp: 'zdz',
        isOpen: true,
        menu: true,
        name: 'Zemes dzīles',
        icon: 'fa fa-industry',
    },
    loc_atradnes: {
        path: '/atradnes',
        parent: 'loc_zemes_dziles',
        subApp: 'zdz',
        menu: true,
        name: 'Atradņu saraksts',
        icon: 'fa fa-industry',
        comp: 'AtradnesSaraksts',
        thunk: fetchListIfNeededTimeouted(atradnes),
        viewname: 'atradne_list'
    },
    loc_atradne: {
        path: '/atradnes/:numurs',
        subApp: 'zdz',
        //always use in page title (also when this is not last component in url)
        alwaysInTitle: true,
        fetchName: (payload) => {
            if (payload.numurs === 'jauns') {
                return new Promise(resolve => {
                    resolve('Jauna atradne')
                })
            }
            return getAtradneNosaukumsFromNumurs(payload.numurs).then(nos => `${nos.length > 16 ? nos.substring(0, 12) + '...' : nos} (${payload.numurs})`)
        },
        comp: 'Atradne',
        thunk: (dispatch, getState) => {
            const { payload, query } = getState().location
            const currentAtradne = getState().atradne.selectedItem
            let fetchNeeded = false
            // if we are navigating from different atradne, clear atradne before show
            if (!currentAtradne || (currentAtradne && currentAtradne.numurs !== payload.numurs) || !!payload.forcedAtTime) {
                dispatch({ type: 'atradne_clear' }) // this action also clears all the tabs
                dispatch(atradneActions.fetchAct(payload))
                fetchNeeded = true
            }
            const tab = query ? query.tab : ''
            if (tab === 'atrasanas') {
                if (fetchNeeded || !getState().atradneAtrasanas.selectedItem) {
                    dispatch(atrasanasActions.fetchAct(payload))
                }
            }
            if (tab === 'geologija') {
                if (fetchNeeded || !getState().atradneGeologija.selectedItem) {
                    dispatch(geologijaActions.fetchAct(payload))
                }
            }
            if (tab === 'kvalitate') {
                if (fetchNeeded || !getState().atradneKvalitate.selectedItem) {
                    dispatch(kvalitateActions.fetchAct(payload))
                }
            }
            if (tab === 'uzzinas') {
                if (fetchNeeded || !getState().atradneUzzinas.selectedItem) {
                    dispatch(uzzinasActions.fetchAct(payload))
                }
            }
            if (tab === 'dok') {
                if (fetchNeeded || !getState().atradneDoks.selectedItem) {
                    dispatch(fetchAtradnesDoks(payload))
                }
            }
            if (tab === 'krajumi') {
                if (fetchNeeded || !getState().atradneKrajumi.selectedItem) {
                    dispatch(krajumiActions.fetchAct(payload))
                }
            }
            if (tab === 'izmainas') {
                if (fetchNeeded || !getState().atradneIzmainas.selectedItem) {
                    dispatch(izmainasActions.fetchAct(payload))
                }
            }
            if (tab === 'vesture') {
                if (fetchNeeded || loadAtradneAuditNextTime) {
                    forced = true
                }
                dispatch(fetchListIfNeededTimeouted(loadAtradneAudit))
            } else {
                loadAtradneAuditNextTime = true
            }
        },
        onBefore: (dispatch, getState) => {
            // if we come from different url, clear atradne, otherwise it will  show prev atradne while loading
            if (getState().location.type !== 'loc_atradne') {
                dispatch({ type: 'atradne_clear' })
            }
        },
        // specific case for atradnesUzzinas
        confirmLeave: confirmLeave(['atradne', 'atradneAtrasanas', 'atradneGeologija', 'atradneKvalitate', (state) => {
            if (state['atradneUzzinas'].editable === true) {
                const serverArr = state['atradneUzzinas'].selectedItem.uzzinas
                const gridArr = state['atradneUzzinas'].uzzinasInGrid
                if (serverArr.length !== gridArr.length || (_.differenceWith(serverArr, gridArr, (a1, a2) => a1.id === a2.id)).length > 0) {
                    return 'Ir nesaglabātas izmaiņas. Vai aiziet no esošās lapas?'
                }
            }
        }]),
        viewname: ({ viewname, atr_numurs }) => {
            const payload = { numurs: atr_numurs }
            switch (viewname) {
                case 'atradne_view':
                    return { payload }
                case 'atradne_atrasanas_view':
                    return { payload, query: { tab: 'atrasanas' } }
                case 'atradne_geologija_view':
                    return { payload, query: { tab: 'geologija' } }
                case 'atradne_kvalitate_view':
                    return { payload, query: { tab: 'kvalitate' } }
                case 'atradne_uzzinas_view':
                    return { payload, query: { tab: 'uzzinas' } }
                case 'atradne_krajums_view':
                    return { payload, query: { tab: 'krajumi' } }
                case 'atradne_izmaina_view':
                    return { payload, query: { tab: 'izmainas' } }
                case 'atradne_izmaina_view_grouped':
                    return { payload, query: { tab: 'izmainas' } }
                default:
                    return undefined
            }
        }
    },
    loc_atradnes_dala: {
        path: '/atradnes/:numurs/dala/:dala',
        subApp: 'zdz',
        alwaysInTitle: true,
        fetchName: (payload) => {
            if (payload.dala === 'jauns') {
                return new Promise(resolve => {
                    resolve('Jauna daļa')
                })
            }
            return getAtradneNosaukumsFromId(payload.dala).then(nos => `${nos.length > 16 ? nos.substring(0, 12) + '...' : nos}`)
        },
        comp: 'AtradnesDala',
        thunk: (dispatch, getState) => {
            const { payload, query } = getState().location
            const currentAtradnesDala = getState().atradnesDala.selectedItem
            let fetchNeeded = false
            // if we are navigating from different atradnes dala, clear atradnes dala before show
            if (!currentAtradnesDala || (currentAtradnesDala && currentAtradnesDala.id !== Number(payload.dala)) || !!payload.forcedAtTime) {
                fetchNeeded = true
                dispatch({ type: 'atradnes_dala_clear' })
                dispatch(atradnesDalaActions.fetchAct({ numurs: payload.numurs, id: payload.dala }))
            }
            const tab = query ? query.tab : ''
            if (tab === 'atrasanas') {
                if (fetchNeeded || !getState().atradnesDalaAtrasanas.selectedItem) {
                    dispatch(dalaAtrasanasActions.fetchAct({ id: payload.dala }))
                }
            }
            if (tab === 'geologija') {
                if (fetchNeeded || !getState().atradnesDalaGeologija.selectedItem) {
                    dispatch(dalaGeologijaActions.fetchAct({ id: payload.dala }))
                }
            }
            if (tab === 'kvalitate') {
                if (fetchNeeded || !getState().atradnesDalaKvalitate.selectedItem) {
                    dispatch(dalaKvalitateActions.fetchAct({ id: payload.dala }))
                }
            }
            if (tab === 'dok') {
                if (fetchNeeded || !getState().atradnesDalaDoks.selectedItem) {
                    dispatch(fetchAtradnesDalaDoks({ id: payload.dala }))
                }
            }
            if (tab === 'krajumi') {
                if (fetchNeeded || !getState().atradnesDalaKrajumi.selectedItem) {
                    dispatch(dalaKrajumiActions.fetchAct({ id: payload.dala }))
                }
            }
            if (tab === 'izmainas') {
                if (fetchNeeded || !getState().atradnesDalaIzmainas.selectedItem) {
                    dispatch(dalaIzmainasActions.fetchAct({ id: payload.dala }))
                }
            }
            if (tab === 'vesture') {
                if (fetchNeeded || loadAtradneDalaAuditNextTime) {
                    forced = true
                }
                dispatch(fetchListIfNeededTimeouted(loadAtradneDalaAudit))
            } else {
                loadAtradneDalaAuditNextTime = true
            }
        },
        onBefore: (dispatch, getState) => {
            if (getState().location.type !== 'loc_atradnes_dala') {
                dispatch({ type: 'atradnes_dala_clear' })
            }
        },
        confirmLeave: confirmLeave(['atradnesDala', 'atradnesDalaAtrasanas', 'atradnesDalaGeologija', 'atradnesDalaKvalitate']),
        viewname: ({ viewname, atr_numurs, atr_id }) => {
            const payload = { numurs: atr_numurs, dala: atr_id }
            switch (viewname) {
                case 'atradnes_dala_view':
                    return { payload }
                case 'atradnes_dala_atrasanas_view':
                    return { payload, query: { tab: 'atrasanas' } }
                case 'atradnes_dala_geologija_view':
                    return { payload, query: { tab: 'geologija' } }
                case 'atradnes_dala_kvalitate_view':
                    return { payload, query: { tab: 'kvalitate' } }
                case 'atradnes_dala_krajums_view':
                    return { payload, query: { tab: 'krajumi' } }
                case 'atradnes_dala_izmaina_view':
                    return { payload, query: { tab: 'izmainas' } }
                case 'atradnes_dala_izmaina_view_grouped':
                    return { payload, query: { tab: 'izmainas' } }
                default:
                    return undefined
            }
        }
    },
    loc_udens: {
        subApp: 'bais',
        menu: true,
        name: 'BAIS',
        icon: 'fa fa-ship'
    },
    loc_udens_virszemes: {
        subApp: 'bais',
        parent: 'loc_udens',
        menu: true,
        path: '/virszemes-udens',
        name: 'Virszemes ūdeņi',
        redirectType: 'loc_virszemes_udens_objekti',
        icon: 'fa fa-ship'
    },
    loc_udens_pazemes: {
        subApp: 'bais',
        parent: 'loc_udens',
        menu: true,
        path: '/pazemes-udens',
        name: 'Pazemes ūdeņi',
        redirectType: 'loc_pazemes_udens_objekti',
        icon: 'fa fa-tint'
    },
    loc_wise: {
        subApp: 'bais',
        parent: 'loc_udens',
        menu: true,
        path: '/wise',
        name: 'WISE',
        icon: 'fa fa-table',
        comp: 'WiseParskats',
        scrollable: true,
        viewname: 'wise_6_export_ods',
    },
    loc_upju_baseinu_apgabali: {
        subApp: 'bais',
        menu: true,
        parent: 'loc_udens',
        path: '/upju-baseinu-apgabali',
        name: 'Upju baseinu apgabali',
        icon: 'fa fa-object-group',
        comp: 'UpjuBaseinuApgabali',
        thunk: fetchListIfNeededTimeouted(upjuBaseinuApgabali),
        viewname: 'upju_baseinu_apgabali_list'
    },
    loc_virszemes_udens_objekti: {
        subApp: 'bais',
        parent: 'loc_udens_virszemes',
        path: '/virszemes-udens/udens-objekti',
        menu: true,
        name: 'Ūdensobjekti (virszemes)',
        icon: 'fa fa-cubes',
        comp: 'VirszemesUdensObjekti',
        thunk: fetchListIfNeededTimeouted(virszemesUdensObjekti),
        viewname: 'virszemes_udens_objekti_list'
    },
    loc_virszemes_udens_objekts: {
        subApp: 'bais',
        path: '/virszemes-udens/udens-objekti/:kods',
        alwaysInTitle: true,
        comp: 'VirszemesUdensObjekts',
        fetchName: (payload) => {
            if (payload.kods === 'jauns') {
                return new Promise(resolve => {
                    resolve('Jauns ūdensobjekts')
                })
            }
            return getUdensObjNosaukumsForKods(payload.kods)
        },
        thunk: (dispatch, getState) => {
            const { payload, query } = getState().location
            const currentObjekts = getState().virszemesUdensObjekts.selectedItem
            let fetchNeeded = false
            if (!currentObjekts || (currentObjekts && currentObjekts.kods !== payload.kods) || !!payload.forcedAtTime) {
                dispatch({ type: 'virszemes_udens_objekts_clear' })
                dispatch(virszemesUdensObjektiActions.fetchAct(payload))
                fetchNeeded = true
            }
            const tab = query ? query.tab : ''
            if (tab === 'atrasanas') {
                if (fetchNeeded || !getState().virszemesUdensObjektsAtrasanas.selectedItem) {
                    dispatch(virszemesUdensObjektsAtrasanasActions.fetchAct(payload))
                }
            } else if (tab === 'pasakumi') {
                if (fetchNeeded || !getState().uoPasakumi.selectedItem) {
                    dispatch(uoPasakumiActions.fetchAct(payload))
                }
            } else if (tab === 'ekologija') {
                if (fetchNeeded || !getState().virszemesUdensObjektsEko.selectedItem) {
                    dispatch(virszemesUdensObjektsEkoActions.fetchAct(payload))
                }
            } else if (tab === 'kimija') {
                if (fetchNeeded || !getState().virszemesUdensObjektsKim.selectedItem) {
                    dispatch(virszemesUdensObjektsKimActions.fetchAct(payload))
                }
            } else if (tab === 'riski') {
                if (fetchNeeded || !getState().virszemesUdensObjektsRiski.selectedItem) {
                    dispatch(virszemesUdensObjektsRiskiActions.fetchAct(payload))
                }
            } else if (tab === 'slodzes') {
                if (fetchNeeded || !getState().virszemesUdensObjektsSlodzes.selectedItem) {
                    dispatch(virszemesUdensObjektsSlodzesActions.fetchAct(payload))
                }
            }
        },
        onBefore: (dispatch, getState) => {
            if (getState().location.type !== 'loc_virzemes_udens_objekts') {
                dispatch({ type: 'virszemes_udens_objekts_clear' })
            }
        },
        viewname: ({ viewname, kods }) => {
            const payload = { kods: kods }
            switch (viewname) {
                case 'virszemes_udens_objekts_view':
                    return { payload }
                case 'virszemes_udens_objekts_atrasanas_view':
                    return { payload, query: { tab: 'atrasanas' } }
                case 'uo_pasakumi_view':
                    return { payload, query: { tab: 'pasakumi' } }
                default:
                    return undefined
            }
        },
        fetchArgsToCrudParams: ({ kods }) => ['kods', 'test']
    },
    loc_pazemes_udens_objekti: {
        subApp: 'bais',
        parent: 'loc_udens_pazemes',
        path: '/pazemes-udens/udens-objekti',
        menu: true,
        name: 'Ūdensobjekti (pazemes)',
        icon: 'fa fa-fw',
        comp: 'PazemesUdensObjekti',
        thunk: fetchListIfNeededTimeouted(pazemesUdensObjekti),
        viewname: 'pazemes_udens_objekti_list'
    },
    loc_pazemes_udens_objekts: {
        subApp: 'bais',
        path: '/pazemes-udens/udens-objekti/:kods',
        alwaysInTitle: true,
        comp: 'PazemesUdensObjekts',
        fetchName: (payload) => {
            if (payload.kods === 'jauns') {
                return new Promise(resolve => {
                    resolve('Jauns ūdensobjekts')
                })
            }
            return getUdensObjNosaukumsForKods(payload.kods)
        },
        thunk: (dispatch, getState) => {
            const { payload, query } = getState().location
            const currentObjekts = getState().pazemesUdensObjekts.selectedItem
            let fetchNeeded = false
            if (!currentObjekts || (currentObjekts && currentObjekts.kods !== payload.kods) || !!payload.forcedAtTime) {
                dispatch({ type: 'pazemes_udens_objekts_clear' })
                dispatch(pazemesUdensObjektiActions.fetchAct(payload))
                fetchNeeded = true
            }
            const tab = query ? query.tab : ''
            if (tab === 'atrasanas') {
                if (fetchNeeded || !getState().pazemesUdensObjektsAtrasanas.selectedItem) {
                    dispatch(pazemesUdensObjektsAtrasanasActions.fetchAct(payload))
                }
            } else if (tab === 'stavoklis') {
                if (fetchNeeded || !getState().pazemesUdensObjektsKvantitate.selectedItem) {
                    dispatch(pazemesUdensObjektiKvantitateActions.fetchAct(payload))
                }
            } else if (tab === 'pasakumi') {
                if (fetchNeeded || !getState().uoPazemesPasakumi.selectedItem) {
                    dispatch(uoPazemesPasakumiActions.fetchAct(payload))
                }
                // } else if(tab === 'ekologija') {
                //     if(fetchNeeded || !getState().udensObjektsEko.selectedItem){
                //         dispatch(virszemesUdensObjektsEkoActions.fetchAct(payload))
                //     }
            } else if (tab === 'kimija') {
                if (fetchNeeded || !getState().pazemesUdensObjektsKim.selectedItem) {
                    dispatch(pazemesUdensObjektsKimActions.fetchAct(payload))
                }
            } else if (tab === 'riski') {
                if (fetchNeeded || !getState().pazemesUdensObjektsRiskiUnIznemumi.selectedItem) {
                    dispatch(pazemesUdensObjektiRiskiUnIznemumiActions.fetchAct(payload))
                }
            } else if (tab === 'slodzes') {
                if (fetchNeeded || !getState().pazemesUdensObjektsSlodzes.selectedItem) {
                    dispatch(pazemesUdensObjektiSlodzesActions.fetchAct(payload))
                }
            } else if (tab === 'papildinformacija') {
                if (fetchNeeded || !getState().pazemesUdensObjektsPapildinformacija.selectedItem) {
                    dispatch(pazemesUdensObjektiPapildinformacijaActions.fetchAct(payload))
                }
            }
        },
        onBefore: (dispatch, getState) => {
            if (getState().location.type !== 'loc_pazemes_udens_objekts') {
                dispatch({ type: 'pazemes_udens_objekts_clear' })
            }
        },
        viewname: ({ viewname, kods }) => {
            const payload = { kods: kods }
            switch (viewname) {
                case 'pazemes_udens_objekts_view':
                    return { payload }
                case 'pazemes_udens_objekts_atrasanas_view':
                    return { payload, query: { tab: 'atrasanas' } }
                case 'pazemes_udens_objekts_kvalitate_kimiska':
                    return { payload, query: { tab: 'kimija' } }
                case 'pazemes_udens_objekts_kvantitate_view':
                    return { payload, query: { tab: 'stavoklis' } }
                case 'pazemes_udens_objekts_risks_tab_view':
                    return { payload, query: { tab: 'riski' } }
                case 'pazemes_udens_objekts_slodzes_tab_view':
                    return { payload, query: { tab: 'slodzes' } }
                case 'pazemes_udens_objekts_papildinformacija_view':
                    return { payload, query: { tab: 'papildinformacija' } }
                case 'uo_pasakumi_view':
                    return { payload, query: { tab: 'pasakumi' } }
                default:
                    return undefined
            }
        },
        fetchArgsToCrudParams: ({ kods }) => ['kods', kods]
    },
    loc_virszemes_slodzu_butiskums: {
        subApp: 'bais',
        parent: 'loc_udens_virszemes',
        path: '/virszemes-udens/slodzu-butiskums',
        menu: true,
        icon: 'fa fa-gavel',
        comp: 'VirszemesSlodzesSaraksts',
        name: 'Slodžu būtiskums',
        thunk: fetchListIfNeededTimeouted(loadVirszemesSlodzuButiskumi),
        viewname: 'virszemes_udens_objekts_slodzes_list'
    },
    loc_pazemes_slodzu_butiskums: {
        subApp: 'bais',
        parent: 'loc_udens_pazemes',
        path: '/pazemes-udens/slodzu-butiskums',
        menu: true,
        icon: 'fa fa-gavel',
        comp: 'PazemesSlodzesSaraksts',
        name: 'Slodžu būtiskums',
        thunk: fetchListIfNeededTimeouted(loadPazemesSlodzuButiskumi),
        viewname: 'pazemes_udens_objekts_slodzes_list'
    },
    loc_virszemes_slodzu_butiskuma_ieraksts: {
        subApp: 'bais',
        parent: 'loc_udens_virszemes',
        path: '/virszemes-udens/:kods/slodzu-butiskums/:id',
        comp: 'VirszemesSlodze',
        name: 'Slodžu būtiskums',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(virszemesSlodzuButiskumiActions.fetchAct(payload))
        },
        onBefore: (dispatch) => {
            dispatch({ type: 'virszemes_udens_objekts_slodzes_view_clear' })
        },
        viewname: 'virszemes_udens_objekts_slodzes_view'
    },
    loc_pazemes_slodzu_butiskuma_ieraksts: {
        subApp: 'bais',
        parent: 'loc_udens_pazemes',
        path: '/pazemes-udens/:kods/slodzu-butiskums/:id',
        comp: 'PazemesSlodze',
        name: 'Slodžu būtiskums',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(pazemesSlodzuButiskumiActions.fetchAct(payload))
        },
        onBefore: (dispatch) => {
            dispatch({ type: 'pazemes_udens_objekts_slodzes_view_clear' })
        },
        viewname: 'pazemes_udens_objekts_slodzes_view'
    },
    loc_virszemes_risks_un_iznemumi: {
        subApp: 'bais',
        parent: 'loc_udens_virszemes',
        path: '/virszemes-udens/risks-un-iznemumi',
        menu: true,
        icon: 'fa fa-gavel',
        comp: 'VirszemesRiskiSaraksts',
        name: 'Risks un izņēmumi',
        thunk: fetchListIfNeededTimeouted(loadVirszemesRiskiUnIznemumi),
        viewname: 'virszemes_udens_objekts_riski_list'
    },
    loc_pazemes_risks_un_iznemumi: {
        subApp: 'bais',
        parent: 'loc_udens_pazemes',
        path: '/pazemes-udens/risks-un-iznemumi',
        menu: true,
        icon: 'fa fa-gavel',
        comp: 'PazemesRiskiSaraksts',
        name: 'Risks un izņēmumi',
        thunk: fetchListIfNeededTimeouted(loadPazemesRiskiUnIznemumi),
        viewname: 'pazemes_udens_objekts_riski_list'
    },
    loc_virszemes_risks_un_iznemumi_ieraksts: {
        subApp: 'bais',
        parent: 'loc_udens_virszemes',
        path: '/virszemes-udens/:kods/risks-un-iznemumi/:id',
        comp: 'VirszemesRisks',
        name: 'Risks un izņēmumi',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(virszemesRiskiUnIznemumiActions.fetchAct(payload))
        },
        onBefore: (dispatch) => {
            dispatch({ type: 'virszemes_udens_objekts_riski_view_clear' })
        },
        viewname: 'virszemes_udens_objekts_riski_view'
    },
    loc_pazemes_risks_un_iznemumi_ieraksts: {
        subApp: 'bais',
        parent: 'loc_udens_pazemes',
        path: '/pazemes-udens/:kods/risks-un-iznemumi/:id',
        comp: 'PazemesRisks',
        name: 'Risks un izņēmumi',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(pazemesRiskiUnIznemumiActions.fetchAct(payload))
        },
        onBefore: (dispatch) => {
            dispatch({ type: 'pazemes_udens_objekts_riski_view_clear' })
        },
        viewname: 'pazemes_udens_objekts_riski_view'
    },
    loc_uzzinas: {
        path: '/uzzinas',
        parent: 'loc_zemes_dziles',
        subApp: 'zdz',
        menu: true,
        name: 'Uzziņas',
        icon: 'fa fa-lightbulb-o',
        comp: 'UzzinuSaraksts',
        thunk: fetchListIfNeededTimeouted(loadUzzinas),
        viewname: 'uzzina_list'
    },
    loc_uzzina: {
        path: '/uzzinas/:id',
        subApp: 'zdz',
        comp: 'Uzzina',
        name: 'Uzziņa',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(uzzinaActions.fetchAct(payload))
        },
        onBefore: (dispatch, getState) => {
            dispatch({ type: 'uzzina_clear' })
        },
        confirmLeave: confirmLeave(['uzzina']),
        viewname: 'uzzina_view'
    },
    loc_datnu_apmaina: {
        parent: 'loc_zemes_dziles',
        path: '/datnu_apmaina',
        subApp: 'zdz',
        icon: 'fa fa-exchange fa-rotate-90',
        menu: true,
        comp: 'DatnuApmaina',
        name: 'Datņu apmaiņa',
        thunk: fetchListIfNeededTimeouted(loadDatnuApmaina),
        viewname: 'datnu_apmaina_view',
        showForUser: (user) => user.avots !== 'G'
    },
    loc_docs: {
        parent: 'loc_zemes_dziles',
        subApp: 'zdz',
        menu: true,
        name: 'Dokumentācija',
        icon: 'fa fa-book',
    },
    loc_pases: {
        parent: 'loc_docs',
        path: '/pases',
        subApp: 'zdz',
        menu: true,
        name: 'Pases',
        icon: 'fa fa-book',
        comp: 'PaseSaraksts',
        thunk: fetchListIfNeededTimeouted(loadPases),
        viewname: 'pase_list'
    },
    loc_pase: {
        path: '/pases/:id',
        subApp: 'zdz',
        comp: 'Pase',
        name: 'Pase',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(paseActions.fetchAct(payload))
        },
        onBefore: (dispatch, getState) => {
            dispatch({ type: 'pase_clear' })
        },
        confirmLeave: confirmLeave(['pase']),
        viewname: 'pase_view'
    },
    loc_limiti: {
        parent: 'loc_docs',
        path: '/limiti',
        subApp: 'zdz',
        menu: true,
        name: 'Limiti',
        icon: 'fa fa-unlock-alt',
        comp: 'LimitsSaraksts',
        thunk: fetchListIfNeededTimeouted(loadLimiti),
        viewname: 'limits_list'
    },
    loc_limits: {
        path: '/limiti/:id',
        subApp: 'zdz',
        comp: 'Limits',
        name: 'Limits',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(limitsActions.fetchAct(payload))
        },
        onBefore: (dispatch, getState) => {
            dispatch({ type: 'limits_clear' })
        },
        confirmLeave: confirmLeave(['limits']),
        viewname: 'limits_view'
    },
    loc_licences: {
        parent: 'loc_docs',
        path: '/licences',
        subApp: 'zdz',
        menu: true,
        name: 'Licences / atļaujas',
        icon: 'fa fa-id-card-o',
        comp: 'LicenceSaraksts',
        thunk: fetchListIfNeededTimeouted(loadLicences),
        viewname: 'licence_list'
    },
    loc_licence: {
        path: '/licences/:id',
        subApp: 'zdz',
        comp: 'Licence',
        name: 'Licence / atļauja',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(licenceActions.fetchAct(payload))
        },
        onBefore: (dispatch, getState) => {
            dispatch({ type: 'licence_clear' })
        },
        confirmLeave: confirmLeave(['licence']),
        viewname: 'licence_view'
    },
    loc_krajumi: {
        parent: 'loc_zemes_dziles',
        path: '/krajumi',
        subApp: 'zdz',
        menu: true,
        name: 'Krājumi',
        icon: 'fa fa-cubes',
        comp: 'KrajumsSaraksts',
        thunk: fetchListIfNeededTimeouted(loadKrajumi),
        viewname: 'krajums_list'
    },
    loc_krajums: {
        path: '/krajumi/:id',
        subApp: 'zdz',
        comp: 'Krajums',
        name: 'Krājumi',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(krajumsActions.fetchAct(payload))
        },
        onBefore: (dispatch, getState) => {
            dispatch({ type: 'krajums_clear' })
        },
        confirmLeave: confirmLeave(['krajums']),
        viewname: 'krajums_view'
    },
    loc_limitu_atlikumi: {
        parent: 'loc_docs',
        path: '/limitu_atlikumi',
        subApp: 'zdz',
        menu: true,
        name: 'Limitu atlikumi',
        icon: 'fa fa-unlock-alt',
        comp: 'LimituAtlikumi',
        thunk: fetchListIfNeededTimeouted(loadLimituAtlikumi),
        viewname: 'limita_apjomu_atlikumi_list'
    },
    loc_izmainas: {
        subApp: 'zdz',
        parent: 'loc_zemes_dziles',
        menu: true,
        name: 'Bilance',
        icon: 'fa fa-balance-scale',
        removeIfOneChild: true
    },
    loc_bilance: {
        path: '/bilance',
        subApp: 'zdz',
        parent: 'loc_izmainas',
        menu: true,
        name: 'Bilance',
        icon: 'fa fa-balance-scale',
        comp: 'Bilance',
        thunk: fetchListIfNeededTimeouted(loadBilance),
        viewname: 'bilance'
    },
    loc_visas_izmainas: {
        path: '/izmainas',
        subApp: 'zdz',
        parent: 'loc_izmainas',
        menu: true,
        name: 'Visas izmaiņas',
        icon: 'fa fa-list',
        comp: 'IzmainaSaraksts',
        thunk: fetchListIfNeededTimeouted(loadIzmainas),
        viewname: 'izmainas_list'
    },
    loc_komersanta_izmainas: {
        path: '/izmainas_kom',
        subApp: 'zdz',
        parent: 'loc_izmainas',
        menu: true,
        name: 'Manas izmaiņas',
        icon: 'fa fa-list',
        comp: 'IzmainaSarakstsKomersants',
        thunk: fetchListIfNeededTimeouted(loadKomersantaIzmainas),
        viewname: 'izmainas_list_komersants',
        showForUser: (user) => user.ir_parstavis_atradnem === true
    },
    // Aizkomentēts pagaidām (LVG-920)
    // loc_hidrogeo_zdz: {
    //     menu: true,
    //     parent: 'loc_zemes_dziles',
    //     subApp: 'zdz',
    //     name: 'Hidroģeoloģija',
    //     icon: 'fa fa-tint',
    //     showForUser: (user) => user.tiesibas.includes("URBUMI") && user.lomas.includes("VVD")
    // },
    // loc_urbumi_zdz: {
    //     parent: 'loc_hidrogeo_zdz',
    //     path: 'urbumi',
    //     subApp: 'zdz',
    //     menu: true,
    //     name: 'Urbumi',
    //     icon: 'fa fa-ellipsis-v',
    //     comp: 'UrbumiSaraksts',
    //     thunk: fetchListIfNeededTimeouted(loadUrbumi),
    //     viewname: 'urbumi_list',
    // },
    // loc_urbums_zdz: {
    //     path: 'urbumi/:id',
    //     subApp: 'zdz',
    //     comp: 'Urbums',
    //     name: 'Urbums',
    //     thunk: (dispatch, getState) => {
    //         const { payload, query } = getState().location
    //         const currentUrbums = getState().urbums.selectedItem
    //         const tab = query ? query.tab : ''
    //         switch (tab) {
    //             case 'sastavs':
    //                 forced = true
    //                 query.gads = query.gads || new Date().getFullYear()
    //                 dispatch(fetchListIfNeededTimeouted(loadMerijumi))
    //                 break;
    //             case 'noverotie_limeni':
    //                 forced = true
    //                 query.gads = query.gads || new Date().getFullYear()
    //                 dispatch(fetchListIfNeededTimeouted(loadNoverotieLimeni))
    //                 break;
    //             case 'ieguves_limeni':
    //                 forced = true
    //                 query.gads = query.gads || new Date().getFullYear()
    //                 dispatch(fetchListIfNeededTimeouted(loadIeguvesLimeni))
    //                 break;
    //             case 'paraugi':
    //                 forced = true
    //                 dispatch(fetchListIfNeededTimeouted(loadParaugi))
    //                 break;
    //             default:
    //                 if (!currentUrbums) {
    //                     dispatch({ type: 'urbums_clear' })
    //                     dispatch(urbumiActions.fetchAct({ id: payload.id, tab: payload.tab }))
    //                 }
    //                 break;
    //         }
    //     },
    //     onBefore: (dispatch, getState) => {
    //         dispatch({ type: 'urbums_clear' })
    //     },
    //     confirmLeave: confirmLeave(['urbums']),
    //     viewname: ({ viewname, id }) => {
    //         const query = { id: id }
    //         console.log(query)
    //         switch (viewname) {
    //             case 'urbuma_merijums_view':
    //                 return { query }
    //             case 'urbuma_paraugi_list':
    //                 return { query }
    //             case 'urbumi_view':
    //                 return { query }
    //             default:
    //                 return undefined
    //         }
    //     }
    // },
    // loc_urbuma_paraugs_zdz: {
    //     path: 'urbumi/:numurs/paraugs/:id',
    //     subApp: 'zdz',
    //     comp: 'UrbumaParaugs',
    //     name: 'Urbuma Paraugs',
    //     thunk: (dispatch, getState) => {
    //         const { payload } = getState().location
    //         dispatch(paraugsActions.fetchAct(payload))
    //     },
    //     onBefore: (dispatch, getState) => {
    //         dispatch({ type: 'urbuma_paraugs_clear' })
    //     },
    //     confirmLeave: confirmLeave(['urbuma_paraugs']),
    //     viewname: 'urbuma_paraugs_edit'
    // },
    // loc_merijums_zdz: {
    //     path: 'urbumi/:numurs/merijums/:id',
    //     subApp: 'zdz',
    //     comp: 'UrbumaMerijums',
    //     name: 'Merijums',
    //     thunk: (dispatch, getState) => {
    //         const { payload } = getState().location
    //         dispatch(merijumsActions.fetchAct(payload))
    //     },
    //     onBefore: (dispatch, getState) => {
    //         dispatch({ type: 'merijums_clear' })
    //     },
    //     viewname: 'urbuma_merijums_edit'
    // },
    // loc_urbumu_atradnes_zdz: {
    //     parent: 'loc_hidrogeo_zdz',
    //     path: 'urbumu_atradnes',
    //     subApp: 'zdz',
    //     menu: true,
    //     name: 'Pazemes ūdeņu atradnes',
    //     icon: 'fa fa-industry',
    //     comp: 'UrbumuAtradnesSaraksts',
    //     thunk: fetchListIfNeededTimeouted(loadUrbumuAtradnes),
    //     viewname: 'urbumi_atradnes_list'
    // },
    // loc_urbumu_atradne_zdz: {
    //     path: 'urbumu_atradnes/:id',
    //     subApp: 'zdz',
    //     comp: 'UrbumaAtradne',
    //     name: 'Atradne',
    //     thunk: (dispatch, getState) => {
    //         const { payload } = getState().location
    //         dispatch(urbumuAtradnesActions.fetchAct(payload))
    //     },
    //     onBefore: (dispatch, getState) => {
    //         dispatch({ type: 'urbumu_atradne_clear' })
    //     },
    //     confirmLeave: confirmLeave(['urbumu_atradne']),
    //     viewname: 'urbumi_atradnes_view'
    // },
    // loc_urbumu_ieguves_zdz: {
    //     parent: 'loc_hidrogeo_zdz',
    //     subApp: 'zdz',
    //     path: 'urbumu_ieguves',
    //     menu: true,
    //     name: 'Pazemes ūdeņu ieguves',
    //     icon: 'fa fa-shower',
    //     comp: 'UrbumuIeguvjuSaraksts',
    //     thunk: fetchListIfNeededTimeouted(loadUrbumuIeguves),
    //     viewname: 'urbumi_ieguves_vietas_list'
    // },
    // loc_urbumu_ieguve_zdz: {
    //     path: 'urbumu_ieguves/:id',
    //     subApp: 'zdz',
    //     comp: 'UrbumaIeguve',
    //     name: 'Ieguve',
    //     thunk: (dispatch, getState) => {
    //         const { payload } = getState().location
    //         dispatch(urbumuIeguvesActions.fetchAct(payload))
    //     },
    //     onBefore: (dispatch, getState) => {
    //         dispatch({ type: 'urbumu_ieguve_clear' })
    //     },
    //     confirmLeave: confirmLeave(['urbumu_ieguve']),
    //     viewname: 'urbumi_ieguves_vietas_view'
    // },
    loc_geofond: {
        subApp: 'zdz',
        parent: 'loc_zemes_dziles',
        menu: true,
        name: 'VĢF Pārskatu Reģistrs',
        icon: 'fa fa-archive',
    },
    loc_geofond_parskats_list: {
        path: '/parskats/list',
        subApp: 'zdz',
        parent: 'loc_geofond',
        menu: true,
        name: 'VĢF Pārskatu Saraksts',
        icon: 'fa fa-archive',
        comp: 'GeofondParskatsList',
        thunk: fetchListIfNeededTimeouted(loadGeofondParskats),
        viewname: 'parskats_list'
    },
    loc_geofond_parskats_map: {
        path: '/parskats/map',
        subApp: 'zdz',
        parent: 'loc_geofond',
        menu: true,
        name: 'VĢF Pārskatu Karte',
        icon: 'fa fa-map-marker',
        comp: 'GeofondParskatsMap',
        thunk: fetchListIfNeededTimeouted(loadGeofondParskatsMap),
        viewname: 'parskats_map'
    },
    loc_geofond_rubrikas: {
        path: '/rubrikas',
        subApp: 'zdz',
        parent: 'loc_geofond',
        menu: true,
        name: 'VĢF Rubriku Saraksts',
        icon: 'fa fa-bars',
        comp: 'GeofondRubrikas',
        thunk: fetchListIfNeededTimeouted(loadGeofondRubrikas),
        viewname: 'rubrika_list'
    },
    loc_parskats: {
        path: '/parskats/map/:id',
        subApp: 'zdz',
        comp: 'Parskats',
        name: 'Pārskats',
        fetchName: (payload) => {
            return new Promise(resolve => {
                if (payload.id === 'jauns') {
                    resolve('Jauns pārskats')
                } else if (payload.inv_numurs) {
                    resolve(`Nr. ${payload.inv_numurs}`)
                } else {
                    resolve('Pārskats')
                }
            })
        },
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            const currentParskats = getState().parskats.selectedItem
            // clear old data before fetching new one
            if (!currentParskats || (currentParskats && currentParskats.id !== Number(payload.id))) {
                dispatch({ type: 'parskats_clear' }) // clear old values
                dispatch(parskatsActions.fetchAct(payload))
            }
        },
        confirmLeave: confirmLeave(['parskats']),
        viewname: 'parskats_view'
    },
    loc_geofond_imports: {
        parent: 'loc_geofond',
        path: '/parskats/geofond_imports',
        // subApp: 'zdz',
        menu: true,
        comp: 'GeofondImports',
        name: 'Imports',
        thunk: fetchListIfNeededTimeouted(loadGeofondImports),
        viewname: 'geofond_imports',
        showForUser: (user) => user.tiesibas.includes("PARSKATS_LABOT")
    },
    loc_rubrika: {
        path: '/rubrikas/:id',
        subApp: 'zdz',
        comp: 'Rubrika',
        name: 'Rubrika',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(rubrikaActions.fetchAct(payload))
        },
        confirmLeave: confirmLeave(['rubrikas']),
        viewname: 'rubrika_view'
    },
    loc_hidrogeo: {
        menu: true,
        subApp: 'hidro',
        name: 'Hidroģeoloģija',
        icon: 'fa fa-tint',
        // showForUser: (user) => !user.lomas.includes("VVD")
        showForUser: (user) => user.lomas.includes("URBUMI")
    },
    loc_urbumi: {
        parent: 'loc_hidrogeo',
        path: '/urbumi',
        subApp: 'hidro',
        menu: true,
        name: 'Urbumi',
        icon: 'fa fa-ellipsis-v',
        comp: 'UrbumiSaraksts',
        thunk: fetchListIfNeededTimeouted(loadUrbumi),
        viewname: 'urbumi_list'
    },
    loc_urbums: {
        path: '/urbumi/:id',
        subApp: 'hidro',
        comp: 'Urbums',
        name: 'Urbums',
        thunk: (dispatch, getState) => {
            const { payload, query } = getState().location
            const currentUrbums = getState().urbums.selectedItem
            const tab = query ? query.tab : ''
            switch (tab) {
                case 'sastavs':
                    forced = true
                    query.gads = query.gads || new Date().getFullYear()
                    dispatch(fetchListIfNeededTimeouted(loadMerijumi))
                    break;
                case 'noverotie_limeni':
                    forced = true
                    query.gads = query.gads || new Date().getFullYear()
                    dispatch(fetchListIfNeededTimeouted(loadNoverotieLimeni))
                    break;
                case 'ieguves_limeni':
                    forced = true
                    query.gads = query.gads || new Date().getFullYear()
                    dispatch(fetchListIfNeededTimeouted(loadIeguvesLimeni))
                    break;
                case 'paraugi':
                    forced = true
                    dispatch(fetchListIfNeededTimeouted(loadParaugi))
                    break;
                default:
                    if (!currentUrbums) {
                        dispatch({ type: 'urbums_clear' })
                        dispatch(urbumiActions.fetchAct({ id: payload.id, tab: payload.tab }))
                    }
                    break;
            }
        },
        onBefore: (dispatch, getState) => {
            dispatch({ type: 'urbums_clear' })
        },
        confirmLeave: confirmLeave(['urbums']),
        viewname: ({ viewname, id }) => {
            const query = { id: id }
            console.log(query)
            switch (viewname) {
                case 'urbuma_merijums_view':
                    return { query }
                case 'urbuma_paraugi_list':
                    return { query }
                case 'urbumi_view':
                    return { query }
                default:
                    return undefined
            }
        }
    },
    loc_urbuma_paraugs: {
        path: '/urbumi/:numurs/paraugs/:id',
        subApp: 'hidro',
        comp: 'UrbumaParaugs',
        name: 'Urbuma Paraugs',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(paraugsActions.fetchAct(payload))
        },
        onBefore: (dispatch, getState) => {
            dispatch({ type: 'urbuma_paraugs_clear' })
        },
        confirmLeave: confirmLeave(['urbuma_paraugs']),
        viewname: 'urbuma_paraugs_edit'
    },
    loc_merijums: {
        path: '/urbumi/:numurs/merijums/:id',
        subApp: 'hidro',
        comp: 'UrbumaMerijums',
        name: 'Merijums',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(merijumsActions.fetchAct(payload))
        },
        onBefore: (dispatch, getState) => {
            dispatch({ type: 'merijums_clear' })
        },
        viewname: 'urbuma_merijums_edit'
    },
    loc_urbumu_atradnes: {
        parent: 'loc_hidrogeo',
        path: '/urbumu_atradnes',
        subApp: 'hidro',
        menu: true,
        name: 'Atradnes',
        icon: 'fa fa-industry',
        comp: 'UrbumuAtradnesSaraksts',
        thunk: fetchListIfNeededTimeouted(loadUrbumuAtradnes),
        viewname: 'urbumi_atradnes_list'
    },
    loc_urbumu_atradne: {
        path: '/urbumu_atradnes/:id',
        subApp: 'hidro',
        comp: 'UrbumaAtradne',
        name: 'Atradne',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(urbumuAtradnesActions.fetchAct(payload))
        },
        onBefore: (dispatch, getState) => {
            dispatch({ type: 'urbumu_atradne_clear' })
        },
        confirmLeave: confirmLeave(['urbumu_atradne']),
        viewname: 'urbumi_atradnes_view'
    },
    loc_urbumu_ieguves: {
        parent: 'loc_hidrogeo',
        subApp: 'hidro',
        path: '/urbumu_ieguves',
        menu: true,
        name: 'Ieguves',
        icon: 'fa fa-shower',
        comp: 'UrbumuIeguvjuSaraksts',
        thunk: fetchListIfNeededTimeouted(loadUrbumuIeguves),
        viewname: 'urbumi_ieguves_vietas_list'
    },
    loc_urbumu_ieguve: {
        path: '/urbumu_ieguves/:id',
        subApp: 'hidro',
        comp: 'UrbumaIeguve',
        name: 'Ieguve',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(urbumuIeguvesActions.fetchAct(payload))
        },
        confirmLeave: confirmLeave(['urbumu_ieguve']),
        viewname: 'urbumi_ieguves_vietas_view'
    },
    loc_wise_param: {
        subApp: 'bais',
        menu: false,
        path: '/wise/parametri/:atskaite',
        comp: 'WiseVieluParametri',
        name: 'WISE 6 parametri',
        thunk: (dispatch, getState) => {
            const state = getState()
            dispatch(loadWiseParametri(state.location.query, state.location.payload.atskaite))
        },
        viewname: 'wise_vielu_parametri_edit',
        showForUser: (user) => user.tiesibas.includes("WISE_LABOT")
    },
    loc_wise_param_new: {
        subApp: 'bais',
        menu: false,
        path: '/wise/parametri/:atskaite/jauns',
        comp: 'WiseVieluParametri',
        name: 'WISE 6 parametri',
        thunk: (dispatch, getState) => {
            const state = getState()
            dispatch(loadWiseParametri(state.location.query, state.location.payload.atskaite))
        },
        viewname: 'wise_vielu_parametri_edit',
    },
    loc_hidrogeo_imports: {
        parent: 'loc_hidrogeo',
        subApp: 'hidro',
        path: '/hidrogeo_imports',
        menu: true,
        comp: 'HidrogeoImports',
        name: 'Imports',
        thunk: fetchListIfNeededTimeouted(loadHidrogeoImports),
        viewname: 'hidrogeo_imports'
    },
    loc_virszemes_pasakumi: {
        subApp: 'bais',
        parent: 'loc_udens_virszemes',
        path: '/virszemes-udens/pasakumi',
        menu: true,
        icon: 'fa fa-gavel',
        comp: 'PasakumiSaraksts',
        name: 'Pasākumi',
        thunk: fetchListIfNeededTimeouted(pasakumi),
        viewname: 'pasakumi_list'
    },
    loc_pazemes_pasakumi: {
        subApp: 'bais',
        parent: 'loc_udens_pazemes',
        path: '/pazemes-udens/pasakumi',
        menu: true,
        icon: 'fa fa-gavel',
        comp: 'PasakumiSaraksts',
        name: 'Pasākumi',
        thunk: fetchListIfNeededTimeouted(pasakumi),
        viewname: 'pasakumi_list'
    },
    loc_virszemes_pasakums: {
        subApp: 'bais',
        parent: 'loc_udens_virszemes',
        path: '/virszemes-udens/:kods/pasakumi/:id',
        comp: 'VirszemesPasakums',
        name: 'Pasākums',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(pasakumsVirszemesActions.fetchAct(payload))
        },
        onBefore: (dispatch) => {
            dispatch({ type: 'pasakums_clear' })
        },
        viewname: 'pasakums_view'
    },
    loc_pazemes_pasakums: {
        subApp: 'bais',
        parent: 'loc_udens_pazemes',
        path: '/pazemes-udens/:kods/pasakumi/:id',
        comp: 'PazemesPasakums',
        name: 'Pasākums',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(pasakumsPazemesActions.fetchAct(payload))
        },
        onBefore: (dispatch) => {
            dispatch({ type: 'pasakums_clear' })
        },
        viewname: 'pasakums_view'
    },
    loc_virszemes_pasakumu_klasifikators: {
        subApp: 'bais',
        parent: 'loc_udens_virszemes',
        path: '/virszemes-udens/pasakumu-klasifikators',
        menu: true,
        icon: 'fa fa-sitemap',
        comp: 'PasakumuKlasifikators',
        name: 'Pasākumu klasifikators',
        thunk: fetchListIfNeededTimeouted(loadPasakumuKlasif),
        viewname: 'pasakumu_klasifikators_edit'
    },
    loc_pazemes_pasakumu_klasifikators: {
        subApp: 'bais',
        parent: 'loc_udens_pazemes',
        path: '/pazemes-udens/pasakumu-klasifikators',
        menu: true,
        icon: 'fa fa-sitemap',
        comp: 'PasakumuKlasifikators',
        name: 'Pasākumu klasifikators',
        thunk: fetchListIfNeededTimeouted(loadPasakumuKlasif),
        viewname: 'pasakumu_klasifikators_edit'
    },
    loc_raditaji_ekologija: {
        subApp: 'bais',
        parent: 'loc_udens_virszemes',
        path: '/virszemes-udens/raditaji/ekologija',
        menu: true,
        icon: 'fa fa-leaf',
        comp: 'RaditajiEkologija',
        name: 'Rādītāji - ekoloģiskā kvalitāte',
        thunk: fetchListIfNeededTimeouted(loadRaditajiEkologija),
        viewname: 'raditajs_ekologija_edit'
    },
    loc_raditaji_kimiska: {
        subApp: 'bais',
        parent: 'loc_udens_virszemes',
        path: '/virszemes-udens/raditaji/kimiska',
        menu: true,
        icon: 'fa fa-tint',
        comp: 'RaditajiKimiska',
        name: 'Rādītāji - ķīmiskā kvalitāte',
        thunk: fetchListIfNeededTimeouted(loadRaditajiKimiska),
        viewname: 'raditajs_kimiska_edit'
    },
    loc_raditaji_ql: {
        subApp: 'bais',
        parent: 'loc_udens_virszemes',
        path: '/virszemes-udens/raditaji/ql',
        menu: true,
        icon: 'fa fa-sort',
        comp: 'RaditajiQL',
        name: 'Rādītāji - QL vērtības',
        thunk: fetchListIfNeededTimeouted(loadRaditajiQl),
        viewname: 'raditajs_ql_edit'
    },
    loc_nobides: {
        subApp: 'bais',
        parent: 'loc_udens_virszemes',
        path: '/virszemes-udens/raditaji/nobides',
        menu: true,
        icon: 'fa fa-arrow-left',
        comp: 'Nobides',
        name: 'Nobīdes periodos',
        thunk: fetchListIfNeededTimeouted(loadNobides),
        viewname: 'uo_nobides_edit'
    },
    loc_dzeramais_udens: {
        menu: true,
        name: 'Dzeramā ūdens riski',
        subApp: 'dzeramais_udens',
        iconAsComp: WaterBottleIcon,
        showForUser: (user) => user.tiesibas.includes('SATECES_BASEINI_SKATIT')
    },
    loc_dzerama_udens_sateces_baseini: {
        path: '/dzerama_udens_sateces_baseini',
        parent: 'loc_dzeramais_udens',
        subApp: 'dzeramais_udens',
        menu: true,
        name: 'Sateces baseini',
        comp: 'DzeramaUdensSatecesBaseiniSaraksts',
        thunk: fetchListIfNeededTimeouted(loadDzeramaUdensSatecesBaseini),
        viewname: 'dzerama_udens_sateces_baseins_list'
    },
    loc_dzerama_udens_sateces_baseins: {
        path: '/dzerama_udens_sateces_baseini/:id',
        subApp: 'dzeramais_udens',
        name: 'Dzeramā ūdens sateces baseins',
        comp: 'DzeramaUdensSatecesBaseinsKartina',
        alwaysInTitle: true,
        fetchName: (payload) => {
            if (!payload.id || payload.id === 'jauns') {
                return new Promise(resolve => {
                    resolve('Jauns baseins')
                })
            }
            return getDzeramaUdensSatecesBaseinsNosaukumsFromId(payload.id).then(nos => `${nos.length > 16 ? nos.substring(0, 12) + '...' : nos}`)
        },
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            const currentBaseins = getState().dzeramaUdensSatecesBaseins.selectedItem
            if (!currentBaseins || (currentBaseins && currentBaseins.id !== payload.id) || !!payload.forcedAtTime) {
                dispatch({ type: 'dzerama_udens_sateces_baseins_clear'})
                dispatch(dzeramaUdensSatecesBaseinsActions.fetchAct(payload))
            }
        },
        onBefore: (dispatch, getState) => {
            if (getState().location.type !== 'loc_dzerama_udens_sateces_baseins') {
                dispatch({ type: 'dzerama_udens_sateces_baseins_clear' })
            }
        },
        confirmLeave: confirmLeave(['dzeramaUdensSatecesBaseins']),
        viewname: 'dzerama_udens_sateces_baseins_view'
    },
    loc_udensapgades_sistemas: {
        path: '/udensapgades_sistemas',
        parent: 'loc_dzeramais_udens',
        subApp: 'dzeramais_udens',
        menu: true,
        name: 'Ūdensapgādes sistēmas',
        comp: 'UdensapgadesSistemasSaraksts',
        thunk: fetchListIfNeededTimeouted(loadUdensapgadesSistemas),
        viewname: 'udensapgades_sistemas_edit'
    },
    loc_troksni: {
        menu: true,
        subApp: 'troksni',
        path: '/troksnu-karte',
        comp: 'TroksnuSaraksts',
        name: 'Trokšņu karte',
        scrollable: true,
        viewname: 'troksnu_karte_saraksts',
        thunk: fetchListIfNeededTimeouted(loadTroksni),
        icon: 'fa fa-map'
    },
    loc_bais_virszemes_imports: {
        subApp: 'bais',
        parent: 'loc_udens_virszemes',
        path: '/virszemes-udens/imports',
        menu: true,
        comp: 'BaisImports',
        name: 'Imports',
        icon: 'fa fa-upload',
        thunk: fetchListIfNeededTimeouted(loadBaisVirszemesImports),
        viewname: 'bais_virszemes_imports'
    },
    loc_bais_imports_pazemes: {
        subApp: 'bais',
        parent: 'loc_udens_pazemes',
        path: '/pazemes-udens/imports',
        menu: true,
        comp: 'BaisPazemesImports',
        name: 'Imports',
        icon: 'fa fa-upload',
        thunk: fetchListIfNeededTimeouted(loadBaisPazemesImports),
        viewname: 'bais_pazemes_imports'
    },
    loc_udens2: {
        menu: true,
        subApp: 'udens2',
        name: 'Ūdens-2',
        icon: 'fa fa-bath'
    },
    loc_udens_nv: {
        parent: 'loc_udens2',
        path: '/udens2/nemsanas-vietas',
        subApp: 'udens2',
        menu: true,
        comp: 'UdensNV',
        name: 'Ūdens ņemšanas vietas',
        thunk: fetchListIfNeededTimeouted(loadUdensNv),
        viewname: 'udens2_nv_list'
    },
    loc_udens_ai: {
        parent: 'loc_udens2',
        path: '/udens2/attirisanas-iekartas',
        subApp: 'udens2',
        menu: true,
        comp: 'UdensAI',
        name: 'Attīrīšanas iekārtas',
        thunk: fetchListIfNeededTimeouted(loadUdensAi),
        viewname: 'udens2_ai_list'
    },
    loc_udens_nov: {
        parent: 'loc_udens2',
        path: '/udens2/novadisana',
        subApp: 'udens2',
        menu: true,
        comp: 'UdensNov',
        name: 'Notekūdeņu attīrīšana',
        thunk: fetchListIfNeededTimeouted(loadUdensNov),
        viewname: 'udens2_nov_list'
    },
    loc_udens_parskati: {
        parent: 'loc_udens2',
        path: '/udens2/parskati',
        subApp: 'udens2',
        menu: true,
        comp: 'UdensParskati',
        name: 'Pārskati',
        thunk: fetchListIfNeededTimeouted(loadUdensParskati),
        viewname: 'udens2_parskati_list'
    },
    loc_udens_vkn: {
        parent: 'loc_udens2',
        path: '/udens2/vkn',
        subApp: 'udens2',
        menu: true,
        comp: 'ParisVkn',
        name: 'Vides kvalitātes normatīvi',
        thunk: fetchListIfNeededTimeouted(loadParisVkn),
        viewname: 'paris_vkn_edit'
    },
    loc_udens_parskats: {
        path: '/udens2/parskati/:id',
        comp: 'UdensParskats',
        subApp: 'udens2',
        alwaysInTitle: true,
        name: 'Pārskats',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(udensParskatsActions.fetchAct(payload))
        },
        onBefore: (dispatch) => {
            dispatch({ type: 'udensParskats_clear' })
        },
        viewname: 'udens2_parskats_view'
    },
    loc_udens_paris_validacija: {
        menu: true,
        path: '/udens2/paris-validacija',
        subApp: 'udens2',
        comp: 'ParisValidacijaParskats',
        parent: 'loc_udens2',
        name: 'Pārskatu validācija',
        scrollable: true,
        viewname: 'paris_validacijas_procesi',
    },
    loc_udens_paris_validacija_rezultati: {
        menu: false,
        path: '/udens2/paris-validacija/:id',
        subApp: 'udens2',
        comp: 'ParisValidacijaRezultati',
        name: 'Rezultāti',
        thunk: (dispatch, getState) => {
            const state = getState()
            dispatch(loadParisValidacijasRezultati(state.location.query, +state.location.payload.id))
        },
        scrollable: true,
        viewname: 'paris_validacijas_rezultati_list',
    },
    // loc_stacijas: {
    //     menu: true,
    //     name: 'Novērojumu stacijas',
    //     icon: 'fa fa-university'
    // },
    loc_vnk: {
        menu: true,
        name: 'VNK',
        icon: 'fa fa-globe',
        subApp: 'vnk'
    },
    loc_vnk_saraksts: {
        parent: 'loc_vnk',
        path: '/vnk-iesniegumi',
        subApp: 'vnk',
        icon: 'fa fa-leaf',
        menu: true,
        comp: 'VnkSaraksts',
        name: 'Saraksts',
        thunk: fetchListIfNeededTimeouted(vnkIesniegumi),
        viewname: 'videi_nodaritie_kaitejumi_list'
    },
    loc_vnk_iesniegums: {
        path: '/vnk-iesniegumi/:id',
        subApp: 'vnk',
        alwaysInTitle: true,
        comp: 'VnkIesniegums',
        name: 'Iesniegums',
        thunk: (dispatch, getState) => {
            const { payload
                // , query
            } = getState().location
            const currentObjekts = getState().vnkIesniegums.selectedItem
            // let fetchNeeded = false
            if (!currentObjekts || (currentObjekts && currentObjekts.id !== payload.id) || !!payload.forcedAtTime) {
                dispatch({ type: 'vnk_iesniegums_clear' })
                dispatch(vnkIesniegumsActions.fetchAct(payload))
                // fetchNeeded = true
            }
            // const tab = query ? query.tab : ''
            // if(tab === 'atrasanas') {
            //     if(fetchNeeded || !getState().pazemesUdensObjektsAtrasanas.selectedItem){
            //         dispatch(pazemesUdensObjektsAtrasanasActions.fetchAct(payload))
            //     }
            // }
        },
        onBefore: (dispatch, getState) => {
            if (getState().location.type !== 'loc_vnk_iesniegums') {
                dispatch({ type: 'vnk_iesniegums_clear' })
            }
        },
        viewname: ({ viewname, id }) => {
            const payload = { id: id }
            switch (viewname) {
                case 'vnk_iesniegums_view':
                    return { payload }
                // case 'vnk_iesniegums_atrasanas_view':
                //     return { payload, query: {tab: 'atrasanas'}}
                default:
                    return undefined
            }
        }
    },
    loc_stacijas_meteo: {
        // parent: 'loc_stacijas',
        path: '/stacijas/meteo',
        subApp: 'meteo',
        menu: true,
        comp: 'MeteoStacijaList',
        name: 'Novērojumu stacijas',
        icon: 'fa fa-university',
        thunk: fetchListIfNeededTimeouted(loadStacijasMeteo),
        viewname: 'noverojumu_stacijas_list'
    },
    loc_stacija_show: {
        // parent: 'loc_stacijas',
        path: '/stacijas/meteo/:id',
        subApp: 'meteo',
        comp: 'StacijaShow',
        name: 'Novērojumu stacija',
        fetchName: (payload) => {
            if (payload.kods === 'jauns') {
                return new Promise(resolve => {
                    resolve('Jauna novērojumu stacija')
                })
            }
            return getStacijasNosaukumsFromId(payload.id)
        },
        icon: 'fa fa-university',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(stacijaActions.fetchAct(payload))
        },
        viewname: 'noverojumu_stacija_view'
    },
    loc_ad: {
        menu: true,
        name: 'Augstvērtīgie dati',
        subApp: 'ad',
        icon: 'fa fa-file',
        showForUser: (user) => user.tiesibas.includes("AD_SKATIT")
    },
    loc_ad_ieraksti: {
        path: '/ad_ieraksts',
        subApp: 'ad',
        parent: 'loc_ad',
        menu: true,
        name: 'Ieraksti',
        comp: 'AdIerakstuSaraksts',
        thunk: fetchListIfNeededTimeouted(loadAdIeraksti),
        viewname: 'ad_ieraksts_list',
        showForUser: (user) => user.tiesibas.includes("AD_SKATIT")
    },
    loc_ad_ieraksts: {
        path: '/ad_ieraksts/:id',
        subApp: 'ad',
        comp: 'AdIeraksts',
        name: 'Ieraksts',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(adIerakstsActions.fetchAct(payload))
        },
        viewname: 'ad_ieraksts_view'
    },
    loc_ad_servisi: {
        path: '/ad_serviss',
        parent: 'loc_ad',
        subApp: 'ad',
        menu: true,
        name: 'Servisi',
        comp: 'AdServisuSaraksts',
        thunk: loadAdServisi(),
        viewname: 'ad_serviss_list',
        showForUser: (user) => user.tiesibas.includes("AD_GAISS_LABOT")
    },
    loc_ad_serviss: {
        path: '/ad_serviss/:id',
        subApp: 'ad',
        comp: 'AdServiss',
        name: 'Serviss',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(loadDataSources())
            dispatch(adServissActions.fetchAct(payload))
        },
        viewname: 'ad_serviss_edit'
    },
    loc_ad_audits: {
        parent: 'loc_ad',
        path: '/ad_audits',
        subApp: 'ad',
        menu: true,
        comp: 'AdAudits',
        name: 'Ārējo saskarņu audits',
        thunk: fetchListIfNeededTimeouted(loadAdAudit),
        viewname: 'ad_audit_list',
        showForUser: (user) => user.lomas.includes("ADMIN")
    },
    loc_klasifikatori: {
        path: '/klasifikatori',
        menu: true,
        comp: 'Klasifikatori',
        name: 'Klasifikatori',
        icon: 'fa fa-sort-amount-asc',
        thunk: (dispatch, getState) => {
            dispatch(fetchListIfNeededTimeouted(loadKodifikatoraIeraksti))
        },
        confirmLeave: confirmLeave('kodifikatori'),
        viewname: 'klasifikatori_edit'
    },
    loc_adresati: {
        path: '/adresati',
        subApp: 'zdz',
        parent: 'loc_zemes_dziles',
        menu: true,
        comp: 'Adresati',
        name: 'Adresāti',
        icon: 'fa fa-address-card',
        thunk: (dispatch, getState) => {
            dispatch(fetchListIfNeededTimeouted(loadAdresati))
        },
        confirmLeave: confirmLeave('adresati'),
        viewname: 'adresats_edit'
    },
    loc_pielikumi: {
        path: '/pielikumi',
        subApp: 'zdz',
        parent: 'loc_zemes_dziles',
        menu: true,
        name: 'Pielikumi',
        comp: 'PielikumuSaraksts',
        icon: 'fa fa-paperclip',
        thunk: fetchListIfNeededTimeouted(loadPielikumi),
        viewname: 'pielikums_list'
    },
    loc_pazinojumi: {
        path: '/pazinojumi',
        menu: true,
        comp: 'PazinojumiSaraksts',
        name: 'Paziņojumi',
        icon: 'fa fa-bell-o',
        thunk: fetchListIfNeededTimeouted(loadPazinojumi),
        viewname: 'pazinojums_list'
    },
    loc_admin: {
        menu: true,
        name: 'Administrēšana',
        icon: 'fa fa-cogs'
    },
    loc_lietotaji: {
        parent: 'loc_admin',
        path: '/lietotaji',
        comp: 'Lietotaji',
        menu: true,
        name: 'Lietotāji',
        icon: 'fa fa-user-o',
        thunk: fetchListIfNeededTimeouted(loadLietotaji),
        confirmLeave: confirmLeave('lietotaji'),
        viewname: 'lietotajs_edit'
    },
    loc_audits: {
        parent: 'loc_admin',
        path: '/audits',
        menu: true,
        name: 'Audits',
        icon: 'fa fa-clock-o',
        comp: 'Audits',
        thunk: fetchListIfNeededTimeouted(loadAudit),
        viewname: 'audit_list'
    },
    loc_audits_personas: {
        parent: 'loc_admin',
        path: '/audits_personas',
        menu: true,
        name: 'Personas datu audits',
        icon: 'fa fa-clock-o',
        comp: 'AuditsPersonasDatu',
        thunk: fetchListIfNeededTimeouted(loadAuditPersonasDatuAudits),
        viewname: 'personas_datu_audit_list'
    },
    loc_audits_zdz_atskaite: {
        parent: 'loc_admin',
        path: '/audits_zdz_atskaite',
        menu: true,
        name: 'ZDz labošanas atskaite',
        icon: 'fa fa-pencil',
        comp: 'AuditsAtradnesAtskaite',
        thunk: fetchListIfNeededTimeouted(loadAuditsAtradnesAtskaite),
        viewname: 'audit_stats_by_atradne'
    },
    loc_audits_sistemas_pazinojumi: {
        parent: 'loc_admin',
        path: '/sistemas_pazinojumi',
        menu: true,
        name: 'Sistēmas paziņojumi',
        icon: 'fa fa-bell-o',
        comp: 'SistemasPazinojumi',
        thunk: fetchListIfNeededTimeouted(loadSistemasPazinojumi),
        viewname: 'sistemas_pazinojumi_edit',
        showForUser: (user) => user.lomas.includes("ADMIN")
    },
    loc_fona_slanu_administresana: {
        parent: 'loc_admin',
        path: '/fona_slanu_administresana',
        menu: true,
        name: 'Kartes slāņu administrēšana',
        icon: 'fa fa-map',
        comp: 'FonaSlanuAdministresanaSaraksts',
        thunk: fetchListIfNeededTimeouted(loadFonaSlanuAdministresanaSaraksts),
        viewname: 'fona_slanu_administresana_edit',
        showForUser: (user) => user.lomas.includes("GEO_ADMIN")
    },
    loc_izmaina: {
        path: '/izmaina/:id',
        subApp: 'zdz',
        comp: 'Izmaina',
        name: 'Izmaiņa',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(izmainaActions.fetchAct(payload))
        },
        onBefore: (dispatch, getState) => {
            dispatch({ type: 'izmaina_clear' })
        },
        confirmLeave: confirmLeave(['izmaina']),
        viewname: 'izmaina_view'
    },
    loc_izmaina_grouped: {
        path: '/izmaina_grouped/:id',
        subApp: 'zdz',
        comp: 'IzmainaGrouped',
        name: 'Izmaiņa',
        thunk: (dispatch, getState) => {
            const { payload } = getState().location
            dispatch(izmainaActionsGrouped.fetchAct(payload))
        },
        onBefore: (dispatch, getState) => {
            dispatch({ type: 'izmaina_grouped_clear' })
        },
        confirmLeave: confirmLeave(['izmaina_grouped']),
        viewname: 'izmaina_view_grouped'
    }
}
routesMap.loc_atradnes_uzzina = { ...routesMap.loc_uzzina, viewname: undefined, path: '/atradnes/:numurs/uzzina/:id' }
routesMap.loc_atradnes_pase = { ...routesMap.loc_pase, viewname: undefined, path: '/atradnes/:numurs/pase/:id' }
routesMap.loc_atradnes_dala_pase = { ...routesMap.loc_pase, viewname: undefined, path: '/atradnes/:numurs/dala/:dala/pase/:id' }
routesMap.loc_atradnes_limits = { ...routesMap.loc_limits, viewname: undefined, path: '/atradnes/:numurs/limits/:id' }
routesMap.loc_atradnes_dala_limits = { ...routesMap.loc_limits, viewname: undefined, path: '/atradnes/:numurs/dala/:dala/limits/:id' }
routesMap.loc_atradnes_licence = { ...routesMap.loc_licence, viewname: undefined, path: '/atradnes/:numurs/licence/:id' }
routesMap.loc_atradnes_dala_licence = { ...routesMap.loc_licence, viewname: undefined, path: '/atradnes/:numurs/dala/:dala/licence/:id' }
routesMap.loc_atradnes_krajums = { ...routesMap.loc_krajums, viewname: undefined, path: '/atradnes/:numurs/krajums/:id' }
routesMap.loc_atradnes_dala_krajums = { ...routesMap.loc_krajums, viewname: undefined, path: '/atradnes/:numurs/dala/:dala/krajums/:id' }
routesMap.loc_atradnes_izmaina = { ...routesMap.loc_izmaina, viewname: undefined, path: '/atradnes/:numurs/izmaina/:id' }
routesMap.loc_atradnes_izmaina_grouped = { ...routesMap.loc_izmaina_grouped, viewname: undefined, path: '/atradnes/:numurs/izmaina_grouped/:id' }
routesMap.loc_atradnes_dala_izmaina = { ...routesMap.loc_atradnes_izmaina, viewname: undefined, path: '/atradnes/:numurs/dala/:dala/izmaina/:id' }
routesMap.loc_atradnes_dala_izmaina_grouped = { ...routesMap.loc_atradnes_izmaina_grouped, viewname: undefined, path: '/atradnes/:numurs/dala/:dala/izmaina_grouped/:id' }
routesMap.loc_urbuma_merijums = { ...routesMap.loc_merijums, viewname: undefined, path: '/urbumi/:numurs/merijums/:id' }
routesMap.loc_parskats_from_list = { ...routesMap.loc_parskats, path: '/parskats/list/:id' }


//returns array with left side navigation config for current user based on users api
export const navigationLinks = (userApi, user) => {
    // show menu item when route has menu=true and no viewname or viewname included in user api
    // dont show parent menu items with no children
    const menuItemsAll = Object.keys(routesMap).map(k => {
        const e = routesMap[k]
        // mark all parent menu items
        const isParent = (Object.values(routesMap).filter((v) => v.parent === k).length > 0)
        return { ...e, url: { type: k }, id: k, isParent, class: isParent ? 'nav-item-parent' : '' }
    }).filter(i => i.menu)

    //filter out subApp menu items
    const menuItems = CURRENT_SUBAPP ? menuItemsAll.filter(i => i.subApp === CURRENT_SUBAPP) : menuItemsAll

    const topItems = menuItems.filter(i => !i.parent && (!i.viewname || userApi[i.viewname]) && (!i.showForUser || i.showForUser(user)))
    menuItems.filter(i => i.parent && (!i.viewname || userApi[i.viewname]) && (!i.showForUser || i.showForUser(user))).forEach(i => {
        const parent = topItems.find(p => p.id === i.parent)
        if (parent) {
            if (!parent.children) parent.children = []
            parent.children.push(i)
        } else {
            const subParent = menuItems.find(p => p.id === i.parent)
            if (subParent) {
                if (!subParent.children) subParent.children = []
                subParent.children.push(i)
            }
        }
    });
    const items = topItems.filter(i => !i.isParent || (i.isParent && i.children && i.children.filter(x => !x.divider &&
        (!x.isParent || (x.isParent && x.children && x.children.filter(y => !y.divider).length > 0))).length > 0))

    // if user has only one subApp, then expand it
    const userSubApps = []
    const checkSubApp = (its) => {
        its.forEach(i => {
            if (i.subApp && userSubApps.indexOf(i.subApp) < 0) {
                userSubApps.push(i.subApp)
            }
            if (i.children) {
                checkSubApp(i.children)
            }
        })
    }
    checkSubApp(items)

    if (userSubApps.length === 1) {
        const oneSubApp = items.find(i => i.subApp === userSubApps[0])
        const oneSubAppInd = items.indexOf(oneSubApp)
        items.splice(oneSubAppInd, 1, ...oneSubApp.children)
    }

    //if parent has only one child then remove parent
    items.filter(i => i.removeIfOneChild && i.children.length === 1).forEach(i => items[items.indexOf(i)] = i.children[0])

    //don't show icons for children and always show icons for parents
    const clearIcons = (its, level) => {
        its.forEach(i => {
            if (i.icon) {
                i.icon = undefined
            }
            i.class = i.class ? i.class + ' nav-item-child-level-' + level : 'nav-item-child-level-' + level
            if (i.children) {
                clearIcons(i.children, level + 1)
            }
        })
    }
    items.forEach(i => {
        if (!i.icon)
            i.icon = 'fa fa-question-circle'
        if (i.children)
            clearIcons(i.children, 1)
    })

    return { items }
}

const isAllowed = (type, state) => {
    // for public routes no auth is required
    const publicRoute = routesMap[type] && routesMap[type].hasOwnProperty('publicRoute') && routesMap[type].publicRoute === true
    if (publicRoute) return true

    // signed-in user is required
    const { user, userApi } = state
    if (!user) return false

    // we check user api for route viewname
    const viewname = routesMap[type] && routesMap[type].viewname
    if (viewname && typeof viewname !== "function" && !userApi[viewname]) {
        return false
    }
    return true
}

const onBeforeChange = (dispatch, getState, { action }) => {
    const state = getState()
    const { type } = action
    const allowed = isAllowed(type, state)
    if (!allowed) {
        if (state.user) {
            // jau ir ielogojies: access denied
            dispatch({ type: 'flash', message: 'Jums nav pieejas izvēlētajai sadaļai' })
            dispatch(redirect({ type: getSakumsLocation() }))
        } else {
            dispatch(redirect({ type: 'loc_login' }))
        }
    } else {

        //keep the same tab for atradnes dala when coming from atradne tab
        if (type === 'loc_atradnes_dala' && (!action.query || !action.query.tab) &&
            getState().location && getState().location.type === 'loc_atradne' &&
            getState().location.query && getState().location.query.tab
        ) {
            const query = action.query || {}
            query.tab = getState().location.query.tab
            dispatch(redirect({
                type,
                payload: { ...action.payload },
                query
            }))
        }

        //call onBeforeRoute
        if (routesMap[type] && routesMap[type].hasOwnProperty('onBefore')) {
            dispatch(routesMap[type].onBefore)
        }

        //always load klasifikatori and klasifikatori grupas
        if (type !== 'loc_login') {
            dispatch(loadUploadSizeLimitIfNeeded())
            dispatch(loadKodifikatorsIfNeeded())
            dispatch(loadKodifikatoruGrupasIfNeeded())
            dispatch(loadMetadataIfNeeded())
            dispatch(loadKartesFonaSlanuAdministresanaSarakstsByLocation())
            dispatch(loadVvisKodifikatorsIfNeeded())
            if (state.user.tiesibas.includes('ARCGIS_SERVISI')) {
                dispatch(loadArcgisServicesIfNeeded())
            }
            if (state.user.tiesibas.includes('PAZINOJUMI')) {
                setTimeout(() => {
                    dispatch(getJaunuPazinojumuSkaits())
                }, 2000)
            }
        }

        // set current subApp
        const subApp = Object.keys(subAppRoutes).filter(k => subAppRoutes[k] === type)
        if (subApp.length === 1) {
            CURRENT_SUBAPP = subApp[0]
        }

        // redirect to sakums
        if (type === 'loc_sakums' || Object.values(subAppRoutes).indexOf(type) > -1) {
            dispatch(guiSet('force_reload'))
            //redirect to first menu item
            let firstLink = 'loc_atradnes'
            const userLinks = navigationLinks(state.userApi, state.user)
            const userFirstLink = userLinks.items && userLinks.items[0]
            if (userFirstLink) {
                firstLink = (userFirstLink.children && userFirstLink.children[0].id) || userFirstLink.id
            }
            dispatch(redirect({ type: firstLink }))
        }

        // redirect if redirectType is set
        if (routesMap[type] && routesMap[type].hasOwnProperty('redirectType')) {
            dispatch(guiSet('force_reload'))
            dispatch(redirect({ type: routesMap[type].redirectType }))
        }

    }
}
const onAfterChange = (dispatch, getState, action) => {
    // reducers can subscribe to get to knom when browser address bar changes e.g.
    // flash message is using this to clear info message after redirects
    const { location } = getState()
    lastQueryForEachAction[location.type] = location.query
    dispatch({ type: 'redirect' })
}
export const options = {
    onBeforeChange,
    onAfterChange,
    initialDispatch: false
}
