import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  getNumberFormatter,
  getTextFilterCfg, getYearFilterCfg,
} from '../grid/ColumnHelpers'

import Karte from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';

import {guiSet} from '../../actions/uiActions'

import { udensNovSelected, loadUdensNovSpecific } from "../../actions/udens2Actions"

import Grid from '../grid/Grid'
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import UdensNovSpecific from "./UdensNovSpecific";

const getColumns = ({kodif}) => {
  return [{
    dataField: 'report_id',
    text: 'Report ID',
    width: 120,
  }, {
    dataField: 'gads',
    text: 'Gads',
    width: 120,
    filterCfg: getYearFilterCfg()
  }, {
    dataField: 'npk',
    text: 'Npk.',
    width: 60,
    // filterCfg: getTextFilterCfg()
  }, {
    dataField: 'code',
    text: 'ID numurs',
    width: 120,
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'name',
    text: 'Ūdenstilpnes nosaukums',
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'org_nosaukums',
    text: 'Organizācijas (operatora) nosaukums',
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'adrese',
    text: 'Atsevišķās ražotnes (objekta) nosaukums un faktiskā adrese',
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'tips',
    text: 'Novadīšanas vieta',
    width: 200,
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'daudzums',
    text: 'Notekūdeņu daudzums, tūkst.m3/gadā',
    formatter: getNumberFormatter()
  }, {
    dataField: 'coord_x',
    text: 'Garums',
    width: 120,
    formatter: getNumberFormatter()
  }, {
    dataField: 'coord_y',
    text: 'Platums',
    width: 120,
    formatter: getNumberFormatter()
  }]
};

class UdensNov extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalList: false
    };
  }

  toggleModalList = () => {
    this.setState({
      modalList: !this.state.modalList
    });
  }

  mapPaneSizeChange = (h) => {
    this.props.guiSet('udens_nov_saraksts_karte_height', h);
  }

  rowEvents = {
    onDoubleClick: (e, row) => {
      this.props.loadUdensNovSpecific({ ...row })
      this.toggleModalList()
    }
  }

  render() {
    const vectorLayerCfgs = {
      udens2 : {
        title: 'Notekūdeņu novadīšanas vietas',
        features: this.props.udensNov.list,
        styles: maputils.uoStyles.udens2Style,
        tooltipFunc: (feat) => feat.get('nosaukums'),
        selected: this.props.udensNov.selected
      }
    }

    const extraGridProps = {
      // keyField: 'rowid'
    }

    if(!this.columns){
      this.columns = getColumns({
        kodif: this.props.kodif
      })
    }

    return (
      <>
        <SplitterLayout
          vertical
          percentage
          primaryIndex={1}
          secondaryInitialSize={this.props.gui.udens_nov_saraksts_karte_height}
          onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

          <Karte mapId="udens2" vectorLayerCfgs={vectorLayerCfgs} />

          <Grid
            onRowSelected={this.props.udensNovSelected}
            loading={this.props.udensNov.loading}
            selectedId={this.props.udensNov.selected}
            dataList={this.props.udensNov.list}
            totalCount={this.props.udensNov.count}
            columns={this.columns}
            rowEvents={this.rowEvents}
            extraGridProps={extraGridProps}
          >
          </Grid>
        </SplitterLayout>
        <Modal
          isOpen={this.state.modalList}
          toggle={this.toggleModalList}
          className="modal-xl">
          <ModalHeader toggle={this.toggleModalList}>Atskaites dati</ModalHeader>
          <ModalBody>
            <UdensNovSpecific></UdensNovSpecific>
          </ModalBody>
        </Modal>
      </>
  )
  }
}

const mapStateToProps = ({kodif, udensNov, gui, user}) => {
  return {kodif, udensNov, gui, user}
}

export default connect(mapStateToProps, {guiSet, udensNovSelected, loadUdensNovSpecific})(UdensNov)
