import {
    callApi, 
    getDefaultEditActions,
    pushUrlToBrowserHistory
} from './helpers'
import {
  redirect
} from 'redux-first-router'

export const loadVirszemesSlodzuButiskumi = (query) => (dispatch, getState) => {
    return dispatch(callApi({
        viewName: 'virszemes_udens_objekts_slodzes_list',
        crudMethod: 'listPaging',
        crudParam: query
    }))
}

export const loadPazemesSlodzuButiskumi = (query) => (dispatch, getState) => {
    return dispatch(callApi({
        viewName: 'pazemes_udens_objekts_slodzes_list',
        crudMethod: 'listPaging',
        crudParam: query
    }))
}

export const virszemesSlodzuButiskumiActions = getDefaultEditActions({
    actionTypeName: 'virszemesSlodzuButiskums',
    stateName: 'virszemesSlodzuButiskums',
    apiViewName: 'virszemes_udens_objekts_slodzes_view',
    overridedActions: {
      createAct: () => (dispatch) => {
        dispatch({
            type: 'virszemes_udens_objekts_slodzes_view_new_success',
            payload: {
                id: null,
                udens_objekts_id: null,
                uo_kods: null,
                uo_nosaukums: null,
                gads: null,
                punktveida: false,
                izkliedeta: false,
                hidro: false,
                citas: false
            }
        })
      }
    },
    afterSave: (dispatch, getState, result, savingNew) => {
      dispatch({
        type: 'virszemes_udens_objekts_slodzes_view_clear'
      })
      dispatch(redirect({
        type: 'loc_virszemes_slodzu_butiskuma_ieraksts',
        payload: {
          id: result.id,
          kods: result.uo_kods
        }
      }))
    },
    createActArgsToCrudParams: ({ kods }) => {
        return {
            uo_kods: kods
        }
    }
})

export const pazemesSlodzuButiskumiActions = getDefaultEditActions({
    actionTypeName: 'pazemesSlodzuButiskums',
    stateName: 'pazemesSlodzuButiskums',
    apiViewName: 'pazemes_udens_objekts_slodzes_view',
    afterSave: (dispatch, getState, result, savingNew) => {
      dispatch({
        type: 'pazemes_udens_objekts_slodzes_view_clear'
      })
      dispatch(redirect({
        type: 'loc_pazemes_slodzu_butiskuma_ieraksts',
        payload: {
          id: result.id,
          kods: result.uo_kods
        }
      }))
    },
    createActArgsToCrudParams: ({ kods }) => {
        return {
            uo_kods: kods
        }
    }
})

export const virszemesSlodzuButiskumsSelected = (id) => {
    return {
      type: 'virszemesSlodzuButiskumi_selected',
      payload: id
    }
}

export const pazemesSlodzuButiskumsSelected = (id) => {
    return {
      type: 'pazemesSlodzuButiskumi_selected',
      payload: id
    }
}

export const virszemesSlodzuButiskumsDoubleSelected = ({ id, uo_kods }) => (dispatch, getState) => {
    pushUrlToBrowserHistory(getState)
    dispatch(redirect({
      type: 'loc_virszemes_slodzu_butiskuma_ieraksts',
      payload: {
        id,
        kods: uo_kods
      }
    }))
}

export const pazemesSlodzuButiskumsDoubleSelected = ({ id, uo_kods }) => (dispatch, getState) => {
    pushUrlToBrowserHistory(getState)
    dispatch(redirect({
      type: 'loc_pazemes_slodzu_butiskuma_ieraksts',
      payload: {
        id,
        kods: uo_kods
      }
    }))
}