import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import Map from "./Map"
import Accordion from './Accordion'
import LayerSwitcher from './ol-layerswitcher'
import MapSearchField from './MapSearchField'
import {
    changeMapBBox, clickedFeature, doubleClickedFeature, clickedOutsideFeature, modifiedFeature,
    tileLayerLoadingError
} from '../../actions/karteActions'
import { guiSet } from '../../actions/uiActions'
import TileLayer from 'ol/layer/Tile.js'
import OSM from 'ol/source/OSM.js'
import XYZ from 'ol/source/XYZ.js'
import * as maputils from './maputils'
import { round } from '../../utils'
import _ from 'lodash'
import FeaturesPopup from './FeaturesPopup'
import { saveLietotajsUzstadijumi } from '../../actions/lietotajsActions'
import { getCurrentSupApp } from '../../actions/helpers'
import { getFormatterForKodif, getBadgeFormatterForKodif, booleanRenderer } from '../grid/ColumnHelpers'
import { linkRenderer } from '../dokumentacija/common'
import { OTHER_VIEWNAME } from '../../constants'
import Measure from './Measure'

const currentYear = new Date().getFullYear()

// tiled background layers
const getTileLayers = () => [
    maputils.createLVMLayer('Ortofoto LVM', 'public:Orto_LKS', 'Ortofotokarte infrasarkanā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra' +
        ', 2013-2016. Autortiesības aizsargātas', 'lvm_orto'),
    maputils.createLVMLayer('Reljefs LVM', 'public:ZemeLKS', 'Digitālā zemes virsmas modeļa pamatdati © Latvijas Ģeotelpiskās informācijas aģe' +
        'ntūra, 2016', 'lvm_zeme'),
    maputils.createLVMLayer('Veģetācijas augstums LVM', 'public:CanopySurfaceHeightLKS', 'Datu sagatavošanā izmantots: Digitālā zemes virsmas modeļa pamatdati © Latvijas ' +
        'Ģeotelpiskās informācijas aģentūra, 2016', 'lvm_veg'),
    maputils.createLVMLayer('Orto infrasarkanais LVM', 'public:OrtoIR_LKS', 'Ortofotokarte infrasarkanā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra' +
        ', 2013-2016. Autortiesības aizsargātas', 'lvm_infra'),
    maputils.createLVMLayer('Zemes slīpums LVM', 'public:SlopeLKS', 'Datu sagatavošanā izmantots: Digitālā zemes virsmas modeļa pamatdati © Latvijas ' +
        'Ģeotelpiskās informācijas aģentūra, 2016', 'lvm_zeme_slipums'),
    new TileLayer({
        title: 'OpenStreetMap oriģinālā',
        id: 'osm_original',
        type: 'base',
        visible: false,
        source: new OSM(),
        backupLayerOnError: true
    }),
    new TileLayer({
        title: 'OpenStreetMap gaišā',
        id: 'osm_light',
        source: new XYZ({
            crossOrigin: 'anonymous',
            //url: 'https://{a-c}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png',
            tileUrlFunction: (t) => `https://${String.fromCharCode(97 + Math.floor(Math.random() * 4))}.basemaps.cartocdn.com/rastertiles/light_all/${t[0]}/${t[1]}/${t[2]}.png`,
            attributions: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy' +
                '; <a href="https://carto.com/attribution">CARTO</a>'
        }),
        visible: false,
        type: 'base'
    }),
    new TileLayer({
        title: 'OpenStreetMap',
        id: 'osm',
        source: new XYZ({
            crossOrigin: 'anonymous',
            tileUrlFunction: (t) => `https://${String.fromCharCode(97 + Math.floor(Math.random() * 4))}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/${t[0]}/${t[1]}/${t[2]}.png`,
            attributions: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy' +
                '; <a href="https://carto.com/attribution">CARTO</a>'
        }),
        visible: false,
        type: 'base'
    })
]

const kadastrsUrl = `${window.location.origin}/services/remote/kadastrs`

const kadastrsLayerCfg = {
    type: 'bbox',
    format: 'json',
    epsgCode: 3059,
    bboxUrl: kadastrsUrl,
    layerProps: {
        minZoom: 14
    },
    group: 'kadastrs',
    attributions: `Kadastra karte un administratīvās robežas @ Nekustamā īpašuma valsts kadastra informācijas sistēmas un Valsts adrešu reģistra dati, ${currentYear}. g.`
}

const kadastrsLayers = {
    'Administrativas_robezas': {
        type: 'GeoServerWMS',
        group: 'kadastrs',
        workspace: 'lvgmc',
        title: 'Administratīvās robežas',
        layerProps: { minZoom: 8 },
        attributions: `Kadastra karte un administratīvās robežas @ Nekustamā īpašuma valsts kadastra informācijas sistēmas un Valsts adrešu reģistra dati, ${currentYear}. g.`
    },
    'lvgmc:kk_parcel': {
        ...kadastrsLayerCfg,
        title: 'Zemes vienības',
        styles: maputils.zemesVienibasStyle,
        tooltipFunc: (feat) => `Zemes vienība: ${feat.get('code')} ${feat.get('area_scale') ? ' Platība: ' + round(feat.get('area_scale'), 1) + ' m2' : ''}`,
        legend: {
            features: [
                {
                    label: 'Uzmērīta',
                    geomType: 'polygon',
                    objectcode: '7201060110'
                },
                {
                    label: 'Ierādīta',
                    geomType: 'polygon',
                    objectcode: '7201060210'
                },
                {
                    label: 'Projektēta',
                    geomType: 'polygon',
                    objectcode: '7201060310'
                },
                {
                    label: 'Nav definēts',
                    geomType: 'polygon',
                    objectcode: '7201060410'
                }
            ]
        }
    },
    'lvgmc:kk_parcel_part': {
        ...kadastrsLayerCfg,
        title: 'Zemes vienību daļas',
        styles: maputils.zemesVienibasDalaStyle,
        tooltipFunc: (feat) => `Zemes vienības daļa: ${feat.get('code')} ${feat.get('area_scale') ? ' Platība: ' + round(feat.get('area_scale'), 1) + ' m2' : ''}`,
        legend: maputils.defaultSinglePolygonLegend
    },
    'lvgmc:kk_parcel_error': {
        ...kadastrsLayerCfg,
        title: 'Kļūdainas zemes vienības',
        styles: maputils.zemesVienibasKludainasStyle,
        tooltipFunc: (feat) => `Kļūdaina zemes vienība: ${feat.get('code')} ${feat.get('area_scale') ? ' Platība: ' + round(feat.get('area_scale'), 1) + ' m2' : ''}`,
        legend: maputils.defaultSinglePolygonLegend
    },
    'lvgmc:kk_building': {
        ...kadastrsLayerCfg,
        title: 'Ēkas',
        styles: maputils.ekasStyle,
        tooltipFunc: (feat) => `Ēka: ${feat.get('code')} ${feat.get('area_scale') ? ' Platība: ' + round(feat.get('area_scale'), 1) + ' m2' : ''}`,
        legend: maputils.defaultSinglePolygonLegend
    }
}

const lgiaLayersBase = {
    type: 'wms',
    group: 'lgia',
    epsgCode: 3059,
    layerProps: {
        visible: false
    }
}

const lgiaLayersWMTSBase = {
    type: 'wmts',
    group: 'lgia',
    layerProps: {
        visible: false
    },
    title: 'comes from DB'
}

const lgiaLayersWmtsOptions = {
    matrixSet: "default028mm",
    format: "image/jpgpng"
}

const lgiaLayersWmtsSourceBase = {
    crossOrigin: 'anonymous',
}

const lgiaLayersWMSSourceBase = {
    crossOrigin: 'anonymous',
    ratio: 1.2
}

const lgiaLyersWMSSourceParams = {
    'FORMAT': 'image/png',
    'TRANSPARENT': false
}

const lgiaKartoshemasWMSUrl = `${maputils.LGIA_URL}/arcgis/services/OPEN_METADATA/Ortofoto_metadata/MapServer/WMSServer`

const lgiaLayers = {
    "Ortofoto_v1_kartoshema": {
        ...lgiaLayersBase,
        title: 'Ortofoto 1994.-1999.g. kartoshēma',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: lgiaKartoshemasWMSUrl,
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '0',
                'TRANSPARENT': true
            },
            attributions: 'Ortofoto kartoshēma © Latvijas Ģeotelpiskās informācijas aģentūra, 1994.-1999.g.'
        }
    },
    "Ortofoto_v2_kartoshema": {
        ...lgiaLayersBase,
        title: 'Ortofoto 2001.-2005.g. kartoshēma',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: lgiaKartoshemasWMSUrl,
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '1',
                'TRANSPARENT': true
            },
            attributions: 'Ortofoto kartoshēma © Latvijas Ģeotelpiskās informācijas aģentūra, 2001.-2005.g.'
        }
    },
    "Ortofoto_v3_kartoshema": {
        ...lgiaLayersBase,
        title: 'Ortofoto 2005.-2008.g. kartoshēma',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: lgiaKartoshemasWMSUrl,
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '2',
                'TRANSPARENT': true
            },
            attributions: 'Ortofoto kartoshēma © Latvijas Ģeotelpiskās informācijas aģentūra, 2005.-2008.g.'
        }
    },
    "Ortofoto_v4_kartoshema": {
        ...lgiaLayersBase,
        title: 'Ortofoto 2010.-2011.g. kartoshēma',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: lgiaKartoshemasWMSUrl,
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '3',
                'TRANSPARENT': true
            },
            attributions: 'Ortofoto kartoshēma © Latvijas Ģeotelpiskās informācijas aģentūra, 2010.-2011.g.'
        }
    },
    "Ortofoto_v5_kartoshema": {
        ...lgiaLayersBase,
        title: 'Ortofoto 2013.-2015.g. kartoshēma',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: lgiaKartoshemasWMSUrl,
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '4',
                'TRANSPARENT': true
            },
            attributions: 'Ortofoto kartoshēma © Latvijas Ģeotelpiskās informācijas aģentūra, 2013.-2015.g.'
        }
    },
    "Ortofoto_v6_kartoshema": {
        ...lgiaLayersBase,
        title: 'Ortofoto 2016.-2018.g. kartoshēma',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: lgiaKartoshemasWMSUrl,
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '5',
                'TRANSPARENT': true
            },
            attributions: 'Ortofoto kartoshēma © Latvijas Ģeotelpiskās informācijas aģentūra, 2016.-2018.g.'
        }
    },
    "Ortofoto_v7_kartoshema": {
        ...lgiaLayersBase,
        title: 'Ortofoto 2019.-2021.g. kartoshēma',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: lgiaKartoshemasWMSUrl,
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '6',
                'TRANSPARENT': true
            },
            attributions: 'Ortofoto kartoshēma © Latvijas Ģeotelpiskās informācijas aģentūra, 2019.-2021.g.'
        }
    },
    "Ortofoto_v8_kartoshema": {
        ...lgiaLayersBase,
        title: 'Ortofoto 2022.-2025.g. kartoshēma',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: lgiaKartoshemasWMSUrl,
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '7',
                'TRANSPARENT': true
            },
            attributions: 'Ortofoto kartoshēma © Latvijas Ģeotelpiskās informācijas aģentūra, 2022.-2025.g.'
        }
    },
    "ORTO_Ortofoto8_rgb": {
        ...lgiaLayersWMTSBase,
        url: '/arcgis/rest/services/ORTO/Ortofoto8_rgb/MapServer/WMTS',
        wmtsOptions: {
            ...lgiaLayersWmtsOptions,
            layer: "ORTO_Ortofoto8_rgb",
        },
        wmtsSourceProps: {
            ...lgiaLayersWmtsSourceBase,
            attributions: 'Ortofotokarte krāsainā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 2022.-2025.g.'
        }
    },
    "ORTO_Ortofoto8_cir": {
        ...lgiaLayersWMTSBase,
        url: '/arcgis/rest/services/ORTO/Ortofoto8_cir/MapServer/WMTS',
        wmtsOptions: {
            ...lgiaLayersWmtsOptions,
            layer: "ORTO_Ortofoto8_cir",
        },
        wmtsSourceProps: {
            ...lgiaLayersWmtsSourceBase,
            attributions: 'Ortofotokarte CIR spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 2022.-2025.g.'
        }
    },
    "ORTO_Ortofoto7_rgb": {
        ...lgiaLayersWMTSBase,
        url: '/arcgis/rest/services/ORTO/Ortofoto7_rgb/MapServer/WMTS',
        wmtsOptions: {
            ...lgiaLayersWmtsOptions,
            layer: "ORTO_Ortofoto7_rgb",
        },
        wmtsSourceProps: {
            ...lgiaLayersWmtsSourceBase,
            attributions: 'Ortofotokarte krāsainā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 2019.-2021.g.'
        }
    },
    "ORTO_Ortofoto7_cir": {
        ...lgiaLayersWMTSBase,
        url: '/arcgis/rest/services/ORTO/Ortofoto7_cir/MapServer/WMTS',
        wmtsOptions: {
            ...lgiaLayersWmtsOptions,
            layer: "ORTO_Ortofoto7_cir",
        },
        wmtsSourceProps: {
            ...lgiaLayersWmtsSourceBase,
            attributions: 'Ortofotokarte CIR spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 2019.-2021.g.'
        }
    },
    "ORTO_Ortofoto6_rgb": {
        ...lgiaLayersWMTSBase,
        url: '/arcgis/rest/services/ORTO/Ortofoto6_rgb/MapServer/WMTS',
        wmtsOptions: {
            ...lgiaLayersWmtsOptions,
            layer: "ORTO_Ortofoto6_rgb",
        },
        wmtsSourceProps: {
            ...lgiaLayersWmtsSourceBase,
            attributions: 'Ortofotokarte krāsainā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 2016.-2018.g.'
        }
    },
    "ORTO_Ortofoto6_cir": {
        ...lgiaLayersWMTSBase,
        url: '/arcgis/rest/services/ORTO/Ortofoto6_cir/MapServer/WMTS',
        wmtsOptions: {
            ...lgiaLayersWmtsOptions,
            layer: "ORTO_Ortofoto6_cir",
        },
        wmtsSourceProps: {
            ...lgiaLayersWmtsSourceBase,
            attributions: 'Ortofotokarte CIR spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 2016.-2018.g.'
        }
    },
    "ORTO_Ortofoto5_rgb": {
        ...lgiaLayersWMTSBase,
        url: '/arcgis/rest/services/ORTO/Ortofoto5_rgb/MapServer/WMTS',
        wmtsOptions: {
            ...lgiaLayersWmtsOptions,
            layer: "ORTO_Ortofoto5_rgb",
        },
        wmtsSourceProps: {
            ...lgiaLayersWmtsSourceBase,
            attributions: 'Ortofotokarte krāsainā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 2013.-2015.g.'
        }
    },
    "ORTO_Ortofoto5_cir": {
        ...lgiaLayersWMTSBase,
        url: '/arcgis/rest/services/ORTO/Ortofoto5_cir/MapServer/WMTS',
        wmtsOptions: {
            ...lgiaLayersWmtsOptions,
            layer: "ORTO_Ortofoto5_cir",
        },
        wmtsSourceProps: {
            ...lgiaLayersWmtsSourceBase,
            attributions: 'Ortofotokarte CIR spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 2013.-2015.g.'
        }
    },
    "ORTO_Ortofoto4_rgb": {
        ...lgiaLayersWMTSBase,
        url: '/arcgis/rest/services/ORTO/Ortofoto4_rgb/MapServer/WMTS',
        wmtsOptions: {
            ...lgiaLayersWmtsOptions,
            layer: "ORTO_Ortofoto4_rgb",
        },
        wmtsSourceProps: {
            ...lgiaLayersWmtsSourceBase,
            attributions: 'Ortofotokarte krāsainā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 2010.-2011.g.'
        }
    },
    "ORTO_Ortofoto3_rgb": {
        ...lgiaLayersWMTSBase,
        url: '/arcgis/rest/services/ORTO/Ortofoto3_rgb/MapServer/WMTS',
        wmtsOptions: {
            ...lgiaLayersWmtsOptions,
            layer: "ORTO_Ortofoto3_rgb",
        },
        wmtsSourceProps: {
            ...lgiaLayersWmtsSourceBase,
            attributions: 'Ortofotokarte krāsainā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 2007.-2008.g.'
        }
    },
    "ORTO_Ortofoto2_rgb": {
        ...lgiaLayersWMTSBase,
        url: '/arcgis/rest/services/ORTO/Ortofoto2_rgb/MapServer/WMTS',
        wmtsOptions: {
            ...lgiaLayersWmtsOptions,
            layer: "ORTO_Ortofoto2_rgb",
        },
        wmtsSourceProps: {
            ...lgiaLayersWmtsSourceBase,
            attributions: 'Ortofotokarte krāsainā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 2003.-2005.g.'
        }
    },
    "ORTO_Ortofoto1_bw": {
        ...lgiaLayersWMTSBase,
        url: '/arcgis/rest/services/ORTO/Ortofoto1_bw/MapServer/WMTS',
        wmtsOptions: {
            ...lgiaLayersWmtsOptions,
            layer: "ORTO_Ortofoto1_bw",
        },
        wmtsSourceProps: {
            ...lgiaLayersWmtsSourceBase,
            attributions: 'Ortofoto melnbalts © Latvijas Ģeotelpiskās informācijas aģentūra, 1994.-1999.g.'
        }
    },
    "VIRSMA_Dsm_lidar": {
        ...lgiaLayersWMTSBase,
        url: '/arcgis/rest/services/VIRSMA/Dsm_lidar/MapServer/WMTS',
        wmtsOptions: {
            ...lgiaLayersWmtsOptions,
            layer: "VIRSMA_Dsm_lidar",
        },
        wmtsSourceProps: {
            ...lgiaLayersWmtsSourceBase,
            attributions: 'Vizualizēts digitālais reljefa modelis © Latvijas Ģeotelpiskās informācijas aģentūra'
        }
    },
    "VIRSMA_Lidar_dtm": {
        ...lgiaLayersWMTSBase,
        url: '/arcgis/rest/services/VIRSMA/Lidar_dtm/MapServer/WMTS',
        wmtsOptions: {
            ...lgiaLayersWmtsOptions,
            layer: "VIRSMA_Lidar_dtm",
        },
        wmtsSourceProps: {
            ...lgiaLayersWmtsSourceBase,
            attributions: 'Vizualizēts digitālais reljefa modelis © Latvijas Ģeotelpiskās informācijas aģentūra'
        }
    },
    "lgia_topo": {
        ...lgiaLayersWMTSBase,
        type: "grouped",
        layers: [{
            key: "OPEN_DATA_LGIA_pamatkarte",
            type: 'wmts',
            layerProps: {
                maxZoom: 11
            },
            url: '/arcgis/rest/services/OPEN_DATA/LGIA_pamatkarte/MapServer/WMTS',
            wmtsOptions: {
                ...lgiaLayersWmtsOptions,
                layer: "OPEN_DATA_LGIA_pamatkarte",
            },
            wmtsSourceProps: {
                ...lgiaLayersWmtsSourceBase,
                attributions: 'Pārskata karte mērogā © Latvijas Ģeotelpiskās informācijas aģentūra'
            }
        }, {
            key: "TOPO_Topo50_v2",
            type: 'wmts',
            layerProps: {
                minZoom: 11,
                maxZoom: 14
            },
            url: '/arcgis/rest/services/TOPO/Topo50_v2/MapServer/WMTS',
            wmtsOptions: {
                ...lgiaLayersWmtsOptions,
                layer: "TOPO_Topo50_v2",
            },
            wmtsSourceProps: {
                ...lgiaLayersWmtsSourceBase,
                attributions: 'Topogrāfiskā karte © Latvijas Ģeotelpiskās informācijas aģentūra'
            }
        }, {
            key: "TOPO_Topo10_v3",
            type: 'wmts',
            layerProps: {
                minZoom: 14
            },
            url: '/arcgis/rest/services/TOPO/Topo10_v3/MapServer/WMTS',
            wmtsOptions: {
                ...lgiaLayersWmtsOptions,
                layer: "TOPO_Topo10_v3",
            },
            wmtsSourceProps: {
                ...lgiaLayersWmtsSourceBase,
                attributions: 'Topogrāfiskā karte © Latvijas Ģeotelpiskās informācijas aģentūra'
            }
        }, {
            key: "TOPO_Topo10_bmap",
            type: 'wmts',
            layerProps: {
                minZoom: 14
            },
            url: '/arcgis/rest/services/TOPO/Topo10_bmap/MapServer/WMTS',
            wmtsOptions: {
                ...lgiaLayersWmtsOptions,
                layer: "TOPO_Topo10_bmap",
            },
            wmtsSourceProps: {
                ...lgiaLayersWmtsSourceBase,
                attributions: 'Topogrāfiskā karte © Latvijas Ģeotelpiskās informācijas aģentūra'
            }
        }, {
            key: "TOPO_Topo_dfdd",
            type: 'wmts',
            layerProps: {
                minZoom: 14
            },
            url: '/arcgis/rest/services/TOPO/Topo_dfdd/MapServer/WMTS',
            wmtsOptions: {
                ...lgiaLayersWmtsOptions,
                layer: "TOPO_Topo_dfdd",
            },
            wmtsSourceProps: {
                ...lgiaLayersWmtsSourceBase,
                attributions: 'Topogrāfiskā karte © Latvijas Ģeotelpiskās informācijas aģentūra'
            }
        }, {
            key: "TOPO_Topo2",
            type: 'wmts',
            layerProps: {
                minZoom: 16
            },
            url: '/arcgis/rest/services/TOPO/Topo2/MapServer/WMTS',
            wmtsOptions: {
                ...lgiaLayersWmtsOptions,
                layer: "TOPO_Topo2",
            },
            wmtsSourceProps: {
                ...lgiaLayersWmtsSourceBase,
                attributions: 'Topogrāfiskā karte © Latvijas Ģeotelpiskās informācijas aģentūra'
            }
        }]
    }
}

const featureAttributesDictionary = (kodif) => ({
    nr_db: {
        label: "DB Nr",
        renderer: (nr) => !!nr ? nr : null
    },
    nosaukums: {
        label: "Nosaukums"
    },
    name_ec: {
        label: "Nosaukums"
    },
    place_name: {
        label: "Nosaukums"
    },
    izrakteni: {
        label: "Derīgie izrakteņi"
    },
    platiba: {
        label: "Platība",
        renderer: (platiba) => `${platiba} tūkst. m2`
    },
    adresats_nosaukums: {
        label: "Adresāts"
    },
    sakuma_datums: {
        label: "No"
    },
    beigu_datums: {
        label: "Līdz"
    },
    ieraksta_veids: {
        label: "Atradne / Prognozēto resursu laukums"
    },
    nr_kf: {
        label: "Kūdras fonda Nr."
    },
    laukums: {
        label: "Laukums"
    },
    tips: {
        label: "Kūdras tips",
        geoserver_bais_tipologija: {
            label: "ŪO tips"
        }
    },
    kods_starpt: {
        label: "Starptautiskais kods"
    },
    plat_0_ha: {
        label: '"0" dziļuma robeža, ha'
    },
    plat_rup_ha: {
        label: "Rūpnieciskā dziļuma platība, ha"
    },
    izmanto: {
        label: "Izmantošanas veids"
    },
    tipa_veids: {
        label: "Botāniskais sastāvs"
    },
    kods: {
        label: "Kods"
    },
    ekologiska: {
        label: "Kopējā ekoloģiskā kvalitāte",
        renderer: getBadgeFormatterForKodif(kodif.uoKvalitate)
    },
    kategorija: {
        label: "Ūdensobjekta kategorija",
        renderer: getFormatterForKodif(kodif.virszemesUdensObjektaKategorija),
        geoserver_limitu_apjomi: {
            label: "Kategorija"
        },
        geoserver_licencu_apjomi: {
            label: "Kategorija"
        },
        geoserver_krajumi: {
            label: "Kategorija"
        }
    },
    kategorijas: {
        label: "Kategorija"
    },
    kimiska: {
        label: "Kopējā ķīmiskā kvalitāte",
        renderer: getBadgeFormatterForKodif(kodif.uoKvalitate, 'badgeColorKimiska')
    },
    kimiska_bez_pbt: {
        label: "Kopējā ķīmiskā kvalitāte bez noturīgajām, bioakumulatīvajām un toksiskajām (PBT) vielām",
        renderer: getBadgeFormatterForKodif(kodif.uoKvalitate, 'badgeColorKimiska')
    },
    name: {
        label: "Nosaukums"
    },
    category: {
        label: "Veids"
    },
    site_code: {
        label: "Kods"
    },
    thematicid: {
        label: "Kods"
    },
    lasveidigie: {
        label: "Tips",
        renderer: (value) => value === true ? 'Lašveidīgo zivju ūdeņi' : 'Karpveidīgo zivju ūdeņi'
    },
    nametext: {
        label: "Nosaukums"
    },
    periods: {
        label: "Periods"
    },
    punktveida: {
        label: "Punktveida slodze",
        renderer: booleanRenderer
    },
    izkliedeta: {
        label: "Izkliedētā slodze",
        renderer: booleanRenderer
    },
    hidro: {
        label: "Hidromorfoloģiskā slodze",
        renderer: booleanRenderer
    },
    citas: {
        label: "Citas slodzes",
        renderer: booleanRenderer
    },
    izcelsme: {
        label: "Izcelsme",
        renderer: getFormatterForKodif(kodif.udensObjektaIzcelsme),
    },
    atis_veids: {
        label: "ATIS veids"
    },
    atis_description: {
        label: "ATIS"
    },
    atis_code: {
        label: "ATIS kods"
    },
    legal_act: {
        label: "MK noteikumi",
        renderer: linkRenderer
    },
    cabinet_act: {
        label: "MK noteikumi objektam",
        renderer: linkRenderer
    },
    genus_lv: {
        label: "Ģints"
    },
    species_lv: {
        label: "Suga"
    },
    genus_latin: {
        label: "Ģints (latīniski)"
    },
    species_latin: {
        label: "Suga (latīniski)"
    },
    location: {
        label: "Novietojums"
    },
    features: {
        label: "Īpašas iezīmes"
    },
    damages: {
        label: "Bojājumi"
    },
    risk_type: {
        label: "Riski"
    },
    height: {
        label: "Augstums"
    },
    description: {
        label: "Apraksts"
    }
})

const zdzGeoServerLayerCfg = {
    type: 'GeoServerWMS',
    group: 'zdz',
    workspace: 'ZDz',
    clickableRasterLayer: true,
    wmsFeatureInfoLayer: true
}

const dapGeoServerLayerCfg = {
    ...zdzGeoServerLayerCfg,
    group: 'dap',
    workspace: 'lvgmc'
}

const baisGeoServerLayerCfg = {
    ...zdzGeoServerLayerCfg,
    layerProps: {
        zIndex: 8
    },
    group: 'bais',
    workspace: 'bais'
}

const pazemeGeoServerLayerCfg = {
    ...zdzGeoServerLayerCfg,
    layerProps: {
        zIndex: 8
    },
    group: 'pazemes_udeni',
    workspace: 'bais'
}

const ekoKvalLayer = {
    ...baisGeoServerLayerCfg,
    group: 'bais_ekologiska_kvalitate',
    featureInfoLinkParams: (feat) => !!feat.kods ? {
        loc: 'loc_virszemes_udens_objekts',
        extraPayload: { kods: feat.kods }
    } : null
}

const kimKvalLayer = {
    ...ekoKvalLayer,
    group: 'bais_kimiska_kvalitate'
}

const kimKvalBezPbtLayer = {
    ...ekoKvalLayer,
    group: 'bais_kimiska_bez_pbt_kvalitate'
}

const prioritarieZivjuUdeni = {
    ...baisGeoServerLayerCfg,
    group: 'bais_prioritarie_zivju_udeni'
}

const zdzGeoServerLayers = {
    "atis_aizsargjosla": {
        ...zdzGeoServerLayerCfg,
        group: 'noverojumi',
        workspace: 'atis',
        title: 'ATIS aizsargjoslas',
        featureInfoTitle: (feat) => `ATIS aizsargjosla: ${feat['atis_source_id']}`
    },
    "dap_habitat_poly": {
        ...dapGeoServerLayerCfg,
        title: 'Aizsargājamās dzīvotnes - biotopi',
        featureInfoTitle: (feat) => `Biotipi: ${feat['name_ec']}`
    },
    "dap_species_poly": {
        ...dapGeoServerLayerCfg,
        title: 'Aizsargājamo sugu atradnes - daudzstūri',
        featureInfoTitle: (feat) => `Aizsargājamo sugu atradnes: ${feat['species_lv'] ? feat['species_lv'] : ''} ${feat['genus_lv'] ? feat['genus_lv'] : ''}`,
    },
    "dap_species_point": {
        ...dapGeoServerLayerCfg,
        title: 'Aizsargājamo sugu atradnes - punkti',
        featureInfoTitle: (feat) => `Aizsargājamo sugu atradnes: ${feat['species_lv'] ? feat['species_lv'] : ''} ${feat['genus_lv'] ? feat['genus_lv'] : ''}`,
    },
    "dap_trees": {
        ...dapGeoServerLayerCfg,
        title: 'Aizsargājamie koki',
        featureInfoTitle: (feat) => `Aizsargājamie koki: ${feat['species_lv'] ? feat['species_lv'] : ''} ${feat['genus_lv'] ? feat['genus_lv'] : ''} ${feat['name'] ? ' - ' + feat['name'] : ''}`,
    },
    "dap_monument_sites": {
        ...dapGeoServerLayerCfg,
        title: 'Dabas pieminekļi',
        featureInfoTitle: (feat) => `Dabas pieminekļi: ${feat['place_name']}`
    },
    "dap_microres_buffer_pub": {
        ...dapGeoServerLayerCfg,
        title: 'Mikroliegumu buferzonas',
        featureInfoTitle: (feat) => feat['atis_veids']
    },
    "microreserves_pub": {
        ...dapGeoServerLayerCfg,
        title: 'Mikroliegumi',
        featureInfoTitle: (feat) => feat['atis_veids']
    },
    "dap_protected_sites": {
        ...dapGeoServerLayerCfg,
        title: 'Īpaši aizsargājamās dabas teritorijas',
        featureInfoTitle: (feat) => `ĪADT: ${feat['name']}`,
    },
    "geoserver_pazemes_monitoringa_stacijas": {
        ...pazemeGeoServerLayerCfg,
        title: 'Monitoringa stacijas',
        featureInfoTitle: (feat) => `Monitoringa stacija: ${feat['nosaukums']} `,
        featureInfoLinkParams: (feat) => ({
            loc: 'loc_stacija_show',
            extraPayload: { id: feat.id }
        }),
        legend: {
            wms_params: { style: 'bais_pazemes_monitoringa_stacijas_legendam' }
        }
    },
    "geoserver_bais_slodzes_2022": {
        ...ekoKvalLayer,
        group: 'bais_slodzes',
        title: '2022.-2027.g. plānošanas periods (2020.g. vērtējums)',
        featureInfoTitle: (feat) => 'Būtiskas slodzes ūdensobjektos 2022.-2027.g. plānošanas periods (2020.g. vērtējums)'
    },
    "prioritarie_zivju_udeni_ezeri": {
        ...prioritarieZivjuUdeni,
        title: 'Ezeri',
        featureInfoTitle: (feat) => 'Prioritārie zivju ūdeņi - ezeri'
    },
    "prioritarie_zivju_udeni_upes": {
        ...prioritarieZivjuUdeni,
        title: 'Upes',
        featureInfoTitle: (feat) => 'Prioritārie zivju ūdeņi - upes'
    },
    "geoserver_bais_kimiska_bez_pbt_kvalitate_2006_2008": {
        ...kimKvalBezPbtLayer,
        title: '2010.-2015.g. plānošanas periods (2006.-2008.g. dati)',
        featureInfoTitle: (feat) => 'Ķīmiskā kvalitāte bez noturīgajām, bioakumulatīvajām un toksiskajām (PBT) vielām 2010.-2015.g. plānošanas periods (2006.-2008.g. dati)'
    },
    "geoserver_bais_kimiska_bez_pbt_kvalitate_2009_2014": {
        ...kimKvalBezPbtLayer,
        title: '2016.-2021.g. plānošanas periods (2009.-2014.g. dati)',
        featureInfoTitle: (feat) => 'Ķīmiskā kvalitāte bez noturīgajām, bioakumulatīvajām un toksiskajām (PBT) vielām 2016.-2021.g. plānošanas periods (2009.-2014.g. dati)'
    },
    "geoserver_bais_kimiska_bez_pbt_kvalitate_2015_2020": {
        ...kimKvalBezPbtLayer,
        title: '2022.-2027.g. plānošanas periods (2015.-2020.g. dati)',
        featureInfoTitle: (feat) => 'Ķīmiskā kvalitāte bez noturīgajām, bioakumulatīvajām un toksiskajām (PBT) vielām 2022.-2027.g. plānošanas periods (2015.-2020.g. dati)'
    },
    "geoserver_bais_kimiska_kvalitate_2006_2008": {
        ...kimKvalLayer,
        title: '2010.-2015.g. plānošanas periods (2006.-2008.g. dati)',
        featureInfoTitle: (feat) => 'Ķīmiskā kvalitāte 2010.-2015.g. plānošanas periods (2006.-2008.g. dati)'
    },
    "geoserver_bais_kimiska_kvalitate_2009_2014": {
        ...kimKvalLayer,
        title: '2016.-2021.g. plānošanas periods (2009.-2014.g. dati)',
        featureInfoTitle: (feat) => 'Ķīmiskā kvalitāte 2016.-2021.g. plānošanas periods (2009.-2014.g. dati)'
    },
    "geoserver_bais_kimiska_kvalitate_2015_2020": {
        ...kimKvalLayer,
        title: '2022.-2027.g. plānošanas periods (2015.-2020.g. dati)',
        featureInfoTitle: (feat) => 'Ķīmiskā kvalitāte 2022.-2027.g. plānošanas periods (2015.-2020.g. dati)'
    },
    "geoserver_bais_ekologiska_kvalitate_2006_2008": {
        ...ekoKvalLayer,
        title: '2010.-2015.g. plānošanas periods (2006.-2008.g. dati)',
        featureInfoTitle: (feat) => 'Ekoloģiskā kvalitāte 2010.-2015.g. plānošanas periods (2006.-2008.g. dati)'
    },
    "geoserver_bais_ekologiska_kvalitate_2009_2014": {
        ...ekoKvalLayer,
        title: '2016.-2021.g. plānošanas periods (2009.-2014.g. dati)',
        featureInfoTitle: (feat) => 'Ekoloģiskā kvalitāte 2016.-2021.g. plānošanas periods (2009.-2014.g. dati)'
    },
    "geoserver_bais_ekologiska_kvalitate_2015_2020": {
        ...ekoKvalLayer,
        title: '2022.-2027.g. plānošanas periods (2015.-2020.g. dati)',
        featureInfoTitle: (feat) => 'Ekoloģiskā kvalitāte 2022.-2027.g. plānošanas periods (2015.-2020.g. dati)'
    },
    "iadt_pamatteritorijas_bais": {
        ...baisGeoServerLayerCfg,
        title: 'Natura2000 (2020.g.)',
        featureInfoTitle: (feat) => 'Natura2000 (2020.g.) ĪADT'
    },
    "geoserver_bais_tipologija": {
        ...ekoKvalLayer,
        group: 'bais',
        title: 'Tipoloģija',
        featureInfoTitle: (feat) => 'Tipoloģija'
    },
    "geoserver_virszemes_monitoringa_stacijas": {
        ...baisGeoServerLayerCfg,
        title: 'Virszemes ūdeņu kvalitātes monitoringa stacijas',
        featureInfoTitle: (feat) => `Monitoringa stacija: ${feat['nosaukums']} `,
        featureInfoLinkParams: (feat) => ({
            loc: 'loc_stacija_show',
            extraPayload: { id: feat.id }
        }),
        legend: {
            wms_params: { style: 'bais_virszemes_monitoringa_stacijas_legendas' }
        }
    },
    "kudra_eraf_s": {
        ...zdzGeoServerLayerCfg,
        title: 'Kūdras atradnes un iegulas (ERAF dati, 2013)',
        featureInfoTitle: (feat) => `Kūdras atradnes un iegulas: ${feat['nosaukums']} `,
        featureInfoColor: 'color_kudra_s',
        featureInfoLinkParams: (feat) => !!feat.nr_db ? {
            numurs: `K${feat.nr_db} `,
            isDala: false,
            loc: 'loc_atradne'
        } : null
    },
    "kudra_eraf_p": {
        ...zdzGeoServerLayerCfg,
        title: 'Kūdras atradnes un iegulas ≤ 10ha (ERAF dati, 2013)',
        featureInfoTitle: (feat) => `Kūdras atradnes un iegulas ≤ 10ha: ${feat['nosaukums']} `,
        featureInfoColor: 'color_kudra_p',
        featureInfoLinkParams: (feat) => !!feat.nr_db ? {
            numurs: `K${feat.nr_db} `,
            isDala: false,
            loc: 'loc_atradne'
        } : null
    },
    "geoserver_krajumi": {
        ...zdzGeoServerLayerCfg,
        title: 'Krājumu laukumi',
        featureInfoTitle: (feat) => `Krājumu laukumi: ${feat['nosaukums']} (${feat['numurs']}) ${feat['dalas_nosaukums'] ? feat['dalas_nosaukums'] : ''} ${feat['strukturelements'] ? feat['strukturelements'] : ''} ${feat['kategorija'] ? 'Kat. ' + feat['kategorija'] : ''} `,
        featureInfoColor: 'color_krajumi',
        featureInfoLinkParams: (feat) => ({
            numurs: feat.numurs,
            atrId: feat.dalas_id,
            isDala: !!feat.dalas_id,
            loc: 'loc_atradnes_krajums',
            locDala: 'loc_atradnes_dala_krajums',
            extraPayload: { id: feat.id }
        })
    },
    "geoserver_pases_krajumu_sadalijumi": {
        ...zdzGeoServerLayerCfg,
        title: 'Pases',
        featureInfoTitle: (feat) => `Pase: ${feat['nosaukums']} (${feat['numurs']}) ${feat['dalas_nosaukums'] ? feat['dalas_nosaukums'] : ''} ${feat['strukturelements'] ? feat['strukturelements'] : ''} ${feat['kategorijas'] ? 'Kat. ' + feat['kategorijas'] : ''} `,
        featureInfoColor: 'color_pases',
        featureInfoLinkParams: (feat) => ({
            numurs: feat.numurs,
            atrId: feat.dalas_id,
            isDala: !!feat.dalas_id,
            loc: 'loc_atradnes_pase',
            locDala: 'loc_atradnes_dala_pase',
            extraPayload: { id: feat.pase_id }
        })
    },
    "geoserver_limitu_apjomi": {
        ...zdzGeoServerLayerCfg,
        title: 'Limiti',
        featureInfoTitle: (feat) => `Limits: ${feat['nosaukums']} (${feat['numurs']}) ${feat['dalas_nosaukums'] ? feat['dalas_nosaukums'] : ''} ${feat['strukturelements'] ? feat['strukturelements'] : ''} ${feat['kategorija'] ? 'Kat. ' + feat['kategorija'] : ''} `,
        featureInfoColor: 'color_limiti',
        featureInfoLinkParams: (feat) => ({
            numurs: feat.numurs,
            atrId: feat.dalas_id,
            isDala: !!feat.dalas_id,
            loc: 'loc_atradnes_limits',
            locDala: 'loc_atradnes_dala_limits',
            extraPayload: { id: feat.limits_id }
        })
    },
    "geoserver_licencu_apjomi": {
        ...zdzGeoServerLayerCfg,
        title: 'Licences / atļaujas',
        featureInfoTitle: (feat) => `Licence / atļauja: ${feat['nosaukums']} (${feat['numurs']}) ${feat['dalas_nosaukums'] ? feat['dalas_nosaukums'] : ''} ${feat['strukturelements'] ? feat['strukturelements'] : ''} ${feat['kategorija'] ? 'Kat. ' + feat['kategorija'] : ''} `,
        featureInfoColor: 'color_licences',
        featureInfoLinkParams: (feat) => ({
            numurs: feat.numurs,
            atrId: feat.dalas_id,
            isDala: !!feat.dalas_id,
            loc: 'loc_atradnes_licence',
            locDala: 'loc_atradnes_dala_licence',
            extraPayload: { id: feat.licence_id }
        })
    },
    "geoserver_atradnes": {
        ...zdzGeoServerLayerCfg,
        title: 'Atradnes',
        featureInfoTitle: (feat) => `Atradne: ${feat['nosaukums']} (${feat['numurs']})`,
        featureInfoColor: 'light',
        featureInfoLinkParams: (feat) => ({
            numurs: feat.numurs,
            isDala: false,
            loc: 'loc_atradne'
        }),
        legend: {
            wms_params: { style: 'atradnes_css_legendam' }
        }
    }
}

class IzraktenisMap extends Component {

    constructor(props) {
        super(props)
        this.state = {
            menuVisible: true,
            selectedPopupFeatures: [],
            selectedPopupCoordinate: null,
            searchedFeatures: [],
            karteSize: [400, 300],
            olMap: null
        }
        this.layerSwitcherElement = createRef()
        this.failedTileLayers = []

        this.is_mounted = false
        this.tileLayers = getTileLayers()

        this.storedVectorLayerCfgsForMap = {}
        this.arcgisServicesServers = new Set()
    }

    componentDidUpdate(prevProps) {
        if ((!this.props.user.tiesibas.includes('ARCGIS_SERVISI') || this.props.arcgisServices.loaded) && !this.layerSwitcherRendered) {
            LayerSwitcher.renderPanel(this.map, this.layerSwitcherElement.current, { reverse: true })
            this.layerSwitcherRendered = true
        } else if (this.layerSwitcherRendered && !_.isEqual(Object.keys(prevProps.vectorLayerCfgs), Object.keys(this.props.vectorLayerCfgs))) {
            LayerSwitcher.renderPanel(this.map, this.layerSwitcherElement.current, { reverse: true })
        }
    }

    componentDidMount() {
        this.is_mounted = true
        this.hideMenuInitially()
    }

    onMapInit = (map) => {
        this.map = map
        this.setState({
            olMap: map
        })
        this.hideMenuInitially()
    }

    hideMenuInitially = () => {
        if (this.is_mounted && (this.props.menuVisible === false || (this.map && this.map.getSize() && this.map.getSize()[0] < 600))) {
            this.setState({
                menuVisible: false
            })
        }
    }

    toggleMenu = () => {
        this.setState({
            menuVisible: !this.state.menuVisible
        })
    }

    //set map size for bbox filter queries for grid
    onMapContainerResize = (mapId, size) => {
        this.setState({
            karteSize: size
        })
        this.props.guiSet(`${mapId} _karte_size`, size)
    }

    onBackgroundLayerError = (layerProps) => {
        if (layerProps.id && this.failedTileLayers.indexOf(layerProps.id) < 0) {
            this.props.tileLayerLoadingError({ error: `Kartes slāni ${layerProps.title} pašlaik nevar attēlot!`, layer: layerProps.id })
            this.failedTileLayers.push(layerProps.id)
        }
    }

    onGetFeatureInfo = ({ event, features }) => {
        // show popup with features
        // process features
        const feats = features.map(f => ({
            ...f,
            title: zdzGeoServerLayers[f.layer] ? zdzGeoServerLayers[f.layer].featureInfoTitle(f) : "Objekts",
            color: zdzGeoServerLayers[f.layer] ? zdzGeoServerLayers[f.layer].featureInfoColor : "secondary",
            featureInfoLinkParams: zdzGeoServerLayers[f.layer] && zdzGeoServerLayers[f.layer].featureInfoLinkParams ? zdzGeoServerLayers[f.layer].featureInfoLinkParams(f) : null,
        }))
        if (feats.length > 0) {
            feats[0].selected = true
        }
        this.setState({
            selectedPopupFeatures: feats,
            selectedPopupCoordinate: event.coordinate
        })
    }

    setSearchedFeatures = (features) => {
        this.setState({
            searchedFeatures: features
        })
    }

    onClickOutsideFeature = (args) => {
        this.setState({ selectedPopupFeatures: [] })
        this.props.clickedOutsideFeature(args)
    }

    onSelectFeatureInPopup = (feature) => {
        this.setState({
            selectedPopupFeatures: this.state.selectedPopupFeatures.map(f => ({ ...f, selected: f === feature && !f.selected }))
        })
    }

    onChangeLayerOpacity = ({ id, opacity }) => {
        if (!!id) {
            const subApp = this.getSubAppByLocation(this.props.location)
            const key = `map | ${subApp}| layers | ${id}| opacity`
            this.props.saveLietotajsUzstadijumi({
                nosaukums: key,
                uzstadijumi: '' + opacity
            })
        }
    }

    onChangeLayerVisibility = ({ id, visible }) => {
        if (!!id) {
            const subApp = this.getSubAppByLocation(this.props.location)
            const key = `map | ${subApp}| layers | ${id}| visible`
            this.props.saveLietotajsUzstadijumi({
                nosaukums: key,
                uzstadijumi: '' + visible
            })
        }
    }

    parseUzstadijumiValue = (key, uzst) => {
        if (key === 'opacity') {
            return parseFloat(uzst)
        }
        if (key === 'visible') {
            return uzst === 'true'
        }
        return undefined
    }

    getSubAppByLocation = (location) => {
        return getCurrentSupApp(location) ?? OTHER_VIEWNAME
    }

    render() {

        const { selectedPopupFeatures, selectedPopupCoordinate, menuVisible, searchedFeatures, karteSize } = this.state

        const { location, changeMapBBox, mapId, clickedFeature,
            doubleClickedFeature, vectorLayerCfgs, modifiedFeature, arcgisServices, kartesFonaSlaniByLocation, user, kodif } = this.props
        const { query } = location
        const { list: dbVectorLayers } = kartesFonaSlaniByLocation
        const irLGIAKartes = user.tiesibas.includes('KADASTRA_WFS')

        // loading while we have no arcgis services metadata
        //TODO add timeout in server side for arcgis metadata
        //if (!arcgisServices.loaded) {
        //return <Loading/>
        //}

        if (vectorLayerCfgs) {
            Object.keys(vectorLayerCfgs).forEach((k) => {
                if (!vectorLayerCfgs[k].group) {
                    vectorLayerCfgs[k].group = 'vektorslani'
                }
                if (!vectorLayerCfgs[k].type) {
                    vectorLayerCfgs[k].type = 'vector'
                }
            })
        }

        let vectorLayerCfgsForMap = { ...vectorLayerCfgs }

        let layerGroups = {
            dap: {
                title: 'DAP fona slāņi',
                fold: 'close',
                visible: false
            },
            kadastrs: {
                title: 'Administratīvās robežas un kadastrs',
                fold: 'close',
                visible: false
            },
            noverojumi: {
                title: 'Novērojuma fona slāņi',
                fold: 'close',
                visible: false
            },
            pazemes_udeni: {
                title: 'Pazemes ūdeņu fona slāņi',
                fold: 'close',
                visible: false
            },
            bais: {
                title: 'Virszemes ūdeņu fona slāņi',
                fold: 'close',
                visible: false
            },
            bais_kimiska_bez_pbt_kvalitate: {
                title: 'Kopējā ķīmiskā kvalitāte bez noturīgajām, bioakumulatīvajām un toksiskajām (PBT) vielām',
                fold: 'close',
                visible: false,
                group: 'bais'
            },
            bais_slodzes: {
                title: 'Būtiskas slodzes ūdensobjektos',
                fold: 'close',
                visible: false,
                group: 'bais'
            },
            bais_kimiska_kvalitate: {
                title: 'Ķīmiskā kvalitāte',
                fold: 'close',
                visible: false,
                group: 'bais'
            },
            bais_ekologiska_kvalitate: {
                title: 'Ekoloģiskā kvalitāte',
                fold: 'close',
                visible: false,
                group: 'bais'
            },
            bais_prioritarie_zivju_udeni: {
                title: 'Prioritārie zivju ūdeņi',
                fold: 'close',
                visible: false,
                group: 'bais'
            },
            zdz: {
                title: 'Zemes dzīļu fona slāņi',
                fold: 'close',
                visible: false
            },
            vektorslani: {
                title: 'Virsslāņi',
                fold: 'open',
                visible: true
            }
        }

        vectorLayerCfgsForMap = { ...vectorLayerCfgsForMap, ...zdzGeoServerLayers, ...kadastrsLayers }

        if (arcgisServices.loaded && arcgisServices.servers && arcgisServices.servers.length > 0) {
            arcgisServices.servers.forEach(serv => {
                vectorLayerCfgsForMap = { ...vectorLayerCfgsForMap, ...serv.layers }

                this.arcgisServicesServers.add(serv.name)
            })
        }

        if (irLGIAKartes) {
            vectorLayerCfgsForMap = { ...vectorLayerCfgsForMap, ...lgiaLayers }
        }

        if (selectedPopupFeatures.filter(f => f.selected).length > 0) {
            vectorLayerCfgsForMap.zdz_feature_info_object = {
                type: 'vector',
                features: selectedPopupFeatures.filter(f => f.selected),
                title: 'Zemes dzīļu izvēlētais objekts',
                styles: maputils.featureInfoStyle,
                legend: maputils.defaultSinglePolygonLegend
            }
        }

        if (searchedFeatures && searchedFeatures.length > 0) {
            vectorLayerCfgsForMap.search_field_objects = {
                type: 'vector',
                features: searchedFeatures,
                title: 'Meklēšanas ievadlauks',
                styles: maputils.searchedFeaturesStyle,
                tooltipFunc: (feat) => `Atrasts "${feat.get('search_title')}" objekts "${feat.get('search_label')}"`,
                legend: maputils.defaultSinglePolygonLegend
            }
        }

        const subApp = this.getSubAppByLocation(location)

        // get per user and subApp layer settings in a form:
        // {
        //   <layer_id_1> : {
        //         opacity: 0.2,
        //         visible: true
        //   },
        //   <layer_id_2> : {
        //         opacity: 0.5
        //   }
        // }

        // When a new map is loaded, update the vector layer config visibility based on values stored in the DB:
        this.storedVectorLayerCfgsForMap = { ...vectorLayerCfgsForMap }

        const applyVisibility = (layer, viewname, parentVisibility = true) => {
            const sadala = layer.sadalas.find(s => [viewname, OTHER_VIEWNAME].includes(s.viewname))
            const currentLayerVisible = parentVisibility && (sadala ? sadala.vai_ieslegta_sadala : false)

            const parentId = layer.parent_id

            const layerSortOrder = layer.sort_order

            const layerCode = layer.kods_for_layer_config
            const parentLayerCode = layer.parent_kods_for_layer_config

            const layerNosaukums = layer.nosaukums
            const parentLayerNosaukums = layer.parent_nosaukums

            const isLayerFolder = layer.vai_ir_mapite

            const isEmpty = (val) => [null, undefined, ''].indexOf(val) !== -1

            const isLayerMainRow = isLayerFolder
            const hasLayerCode = isEmpty(layerCode) === false
            const hasParentLayerCode = isEmpty(parentLayerCode) === false

            const parentGroup = hasParentLayerCode ? parentLayerCode : parentLayerNosaukums
            const configKey = hasLayerCode ? layerCode : layerNosaukums

            const hasMatchingConfig = this.storedVectorLayerCfgsForMap.hasOwnProperty(configKey)

            // No `sadaļas` means that the current layer should not be rendered if it exists
            if (layer.sadalas.length === 0) {
                if (hasMatchingConfig) {
                    delete this.storedVectorLayerCfgsForMap[configKey]
                }
                return
            }

            // Ignore any configs passed down within the original props
            if (hasLayerCode && vectorLayerCfgs.hasOwnProperty(layerCode)) {
                this.storedVectorLayerCfgsForMap[layerCode] = {
                    ...vectorLayerCfgs[layerCode],
                    visible: true
                }
                return;
            }

            // Check whether all folders are properly visible as `layerGroups`
            if (isLayerMainRow) {
                // Special cases for ArcGIS and LGIA layers (if those services are not loaded, do not show their layer configs):
                const arcgisServerCodes = ['ArcGIS (iekšējais serveris)', 'ArcGIS (publiskais serveris)']
                if (arcgisServerCodes.includes(configKey)) {
                    // Current folder is an ArcGIS server, hide it if it does not appear within the loaded ArcGIS services:
                    if (!this.arcgisServicesServers.has(configKey)) {
                        return
                    }
                }

                if (!irLGIAKartes && configKey === 'lgia') {
                    return
                }

                // Append the row as a layer group if it is missing by its `kods_with_layer_config` or `nosaukums`
                layerGroups[configKey] = {
                    ...layerGroups[configKey],
                    title: layerNosaukums,
                    fold: 'close',
                    visible: currentLayerVisible
                }

                // Not a main parent, therefore it must be under one, add it under that parent's group
                if (isEmpty(parentId) === false) {
                    layerGroups[configKey] = {
                        ...layerGroups[configKey],
                        group: parentGroup
                    }
                }

                // Add the sort order so that the layer groups can be sorted afterwards:
                layerGroups[configKey] = {
                    ...layerGroups[configKey],
                    sort_order: layerSortOrder
                }

            } else {
                // The `group` of the new vector layer config is either `parent_kods_for_layer_config` or `parent_nosaukums`
                // Layer not present in configs => add it as a new vector layer config by `kods_with_layer_config`, if it exists, or `nosaukums` otherwise

                if (!hasMatchingConfig) {
                    this.storedVectorLayerCfgsForMap[configKey] = {
                        group: parentGroup,
                        title: layerNosaukums,
                        layerProps: {
                            visible: currentLayerVisible,
                            sort_order: layerSortOrder
                        },
                        type: 'bbox', // TODO: Change this to appropriate layer!
                        attributions: `Jauns kartes slānis: ${layerNosaukums}`
                    }
                } else {
                    // Config already present, adjust its information accordingly
                    const cfg = this.storedVectorLayerCfgsForMap[configKey]
                    this.storedVectorLayerCfgsForMap[configKey] = {
                        ...cfg,
                        group: parentGroup,
                        title: layerNosaukums,
                        layerProps: {
                            ...cfg.layerProps,
                            visible: currentLayerVisible,
                            sort_order: layerSortOrder
                        }
                    }
                }
            }

            layer.children.forEach(child => applyVisibility(child, viewname, currentLayerVisible))
        }

        dbVectorLayers.forEach(layer => applyVisibility(layer, subApp))

        // The `Map.js` component reverses the order of the groups when rendering the map, therefore the groups need to be sorted in reverse order initially
        layerGroups = Object.fromEntries(
            Object.entries(layerGroups).sort(([, a], [, b]) => b.sort_order - a.sort_order)
        )

        const key = `map | ${subApp}| layers | `
        const uzstadijumi = user.uzstadijumi
            .filter(u => u.nosaukums.startsWith(key))
            .reduce((layerCfgs, uzst) => {
                const layerCfgParts = uzst.nosaukums.substring(key.length).split('|').map(s => s.trim())
                const layerCfg = layerCfgs[layerCfgParts[0]] || {}
                layerCfg[layerCfgParts[1]] = this.parseUzstadijumiValue(layerCfgParts[1], uzst.uzstadijumi)
                layerCfgs[layerCfgParts[0]] = layerCfg
                return layerCfgs
            }, {})

        // apply setting for base layers
        let baseVisible = false
        this.tileLayers.forEach(l => {
            const cfg = uzstadijumi[l.get("id")]
            if (cfg) {
                if (cfg.visible === true) {
                    l.setVisible(cfg.visible)
                    baseVisible = true
                }
                if (cfg.opacity) {
                    l.setOpacity(cfg.opacity)
                }
            }
        })
        // if no base layer visible set the default one visible
        if (!baseVisible) {
            this.tileLayers.find(l => l.get('id') === 'osm_original').setVisible(true)
        }

        // apply settings for vector layers
        Object.keys(this.storedVectorLayerCfgsForMap).forEach(k => {
            const cfg = uzstadijumi[k]
            if (cfg) {
                const layerProps = this.storedVectorLayerCfgsForMap[k].layerProps || {}
                this.storedVectorLayerCfgsForMap[k].layerProps = { ...layerProps, ...cfg }
            }
        })

        return (
            <div className="map-app-container">
                <Map
                    mapId={mapId}
                    center={query}
                    backgroundLayers={this.tileLayers}
                    onMapContainerResize={this.onMapContainerResize}
                    onChangeMapBBox={changeMapBBox}
                    onClickFeature={clickedFeature}
                    onClickOutsideFeature={this.onClickOutsideFeature}
                    onDoubleClickFeature={doubleClickedFeature}
                    vectorLayerCfgs={this.storedVectorLayerCfgsForMap}
                    layerGroups={layerGroups}
                    onModifyFeature={modifiedFeature}
                    onMapInit={this.onMapInit}
                    onBackgroundLayerError={this.onBackgroundLayerError}
                    onGetFeatureInfo={this.onGetFeatureInfo}
                    onPopupClose={this.onClickOutsideFeature}
                    onChangeLayerOpacity={this.onChangeLayerOpacity}
                    onChangeLayerVisibility={this.onChangeLayerVisibility}
                    popup={selectedPopupFeatures && selectedPopupFeatures.length > 0 ? {
                        content: <FeaturesPopup features={selectedPopupFeatures}
                            selectFeature={this.onSelectFeatureInPopup}
                            featureAttributesDictionary={featureAttributesDictionary(kodif)}
                        />,
                        coordinate: selectedPopupCoordinate
                    } : null} >
                    <MapSearchField setSearchedFeatures={this.setSearchedFeatures} maxHeight={(karteSize[1] - 45) + 'px'} />
                    <div className="ol-control side-menu-btn">
                        <button onClick={this.toggleMenu}><i className="fa fa-bars" aria-hidden="true"></i></button>
                    </div>
                    <div className={menuVisible ? 'side-menu' : 'side-menu hidden'} >
                        <Accordion title="Kartes slāņi">
                            <div className="layer-switcher" ref={this.layerSwitcherElement}></div>
                        </Accordion>
                        {/*<Accordion title="Vietas meklēšana">
                            <FilterForm onSearch={searchMap} loading={kadastrs_search.loading}/>
                        </Accordion>

                        <Accordion title="Apzīmējumi">
                            Te varētu ielikt kartes apzīmējumus no ArcGIS servera
                        </Accordion>
                        */}
                    </div>
                    <Measure map={this.state.olMap} />
                </Map>
            </div>
        )
    }

}

const mapStateToProps = ({ location, arcgisServices, kartesFonaSlaniByLocation, user, kodif }) =>
    ({ location, arcgisServices, kartesFonaSlaniByLocation, user, kodif })
export default connect(mapStateToProps, {
    changeMapBBox, clickedFeature, doubleClickedFeature,
    clickedOutsideFeature, guiSet, modifiedFeature, tileLayerLoadingError, saveLietotajsUzstadijumi
})(IzraktenisMap)
