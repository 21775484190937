import {
  callApi,
  getDefaultEditActions,
  pushUrlToBrowserHistory,
  addExtraFilterToQuery
} from './helpers'
import {
  redirect
} from 'redux-first-router'


export const virszemesUdensObjekti = (query) => (dispatch, getState) => {
  const mapSize = getState().gui['udens_objekti_saraksts_karte_size']
  return dispatch(callApi({
    viewName: 'virszemes_udens_objekti_list',
    crudMethod: 'list',
    crudParam: addExtraFilterToQuery(query, mapSize)
  }))
}

export const virszemesUdensObjektsSelected = (kods) => {
  return {
    type: 'virszemes_udens_objekts_selected',
    payload: kods
  }
}

export const virszemesUdensObjektsDoubleSelected = (kods) => (dispatch, getState) => {
  pushUrlToBrowserHistory(getState)
  dispatch(redirect({
    type: 'loc_virszemes_udens_objekts',
    payload: {
      kods: kods
    }
  }))
}

export const virszemesUdensObjektiActions = getDefaultEditActions({
  actionTypeName: 'virszemes_udens_objekts',
  stateName: 'virszemesUdensObjekts',
  apiViewName: 'virszemes_udens_objekts_view',
  afterSave: (dispatch, getState, result, savingNew) => {
    dispatch({
      type: 'virszemes_udens_objekts_clear'
    })
    dispatch(redirect({
      type: 'loc_virszemes_udens_objekts',
      payload: {
        kods: result.kods
      }
    }))
  },
  fetchArgsToCrudParams: ({
    kods
  }) => ['kods', kods]
})


export const virszemesUdensObjektsAtrasanasActions = getDefaultEditActions({
  actionTypeName: 'virszemes_udens_objekts_atrasanas',
  stateName: 'virszemesUdensObjektsAtrasanas',
  apiViewName: 'virszemes_udens_objekts_atrasanas_view',
  fetchArgsToCrudParams: ({
    kods
  }) => ['kods', kods]
})


export const uoPasakumiActions = getDefaultEditActions({
  actionTypeName : 'uo_pasakumi',
  stateName: 'uoPasakumi',
  combinedPage: true,
  apiViewName: 'uo_pasakumi_view',
  fetchArgsToCrudParams: ({ kods, periods }) => {
    if (periods != null) return ['kods', kods, 'periods', periods]
    else return ['kods', kods]
  }
})

export const virszemesUdensObjektsEkoActions = getDefaultEditActions({
  actionTypeName: 'virszemes_udens_objekts_eko',
  stateName: 'virszemesUdensObjektsEko',
  apiViewName: 'virszemes_udens_objekts_kvalitate_ekologija',
  fetchArgsToCrudParams: ({ kods, gads }) => {
    let y = gads
    if (!y) {
      if (new Date().getMonth() >= 4) {
        y = new Date().getFullYear() - 1
      } else {
        y = new Date().getFullYear() - 2
      }
    }
    return ['kods', kods, 'gads', y]
  }
})

export const virszemesUoEkoYearFetch = (args) => (dispatch) => {
  dispatch({ type: "virszemes_udens_objekts_eko_clear" })
  dispatch(virszemesUdensObjektsEkoActions.fetchAct(args))
}

export const virszemesUdensObjektsKimActions = getDefaultEditActions({
  actionTypeName: 'virszemes_udens_objekts_kim',
  stateName: 'virszemesUdensObjektsKim',
  apiViewName: 'virszemes_udens_objekts_kvalitate_kimiska',
  fetchArgsToCrudParams: ({ kods, gads }) => {
    let y = gads
    if (!y) {
      if (new Date().getMonth() >= 4) {
        y = new Date().getFullYear() - 1
      } else {
        y = new Date().getFullYear() - 2
      }
    }
    return ['kods', kods, 'gads', y]
  }
})

export const virszemesUoKimYearFetch = (args) => (dispatch) => {
  dispatch({ type: "virszemes_udens_objekts_kim_clear" })
  dispatch(virszemesUdensObjektsKimActions.fetchAct(args))
}

export const virszemesUdensObjektsRiskiActions = getDefaultEditActions({
  actionTypeName: 'virszemes_udens_objekts_risks_un_iznemumi_tab_view',
  stateName: 'virszemesUdensObjektsRiski',
  apiViewName: 'virszemes_udens_objekts_risks_un_iznemumi_tab_view',
  fetchArgsToCrudParams: ({ kods, gads }) => {
    let y = gads
    if (!y) {
      y = new Date().getFullYear() - 1
    }
    return ['kods', kods, 'gads', y]
  }
})

export const virszemesUdensObjektsSlodzesActions = getDefaultEditActions({
  actionTypeName: 'virszemes_udens_objekts_slodzes_tab_view',
  stateName: 'virszemesUdensObjektsSlodzes',
  apiViewName: 'virszemes_udens_objekts_slodzes_tab_view',
  fetchArgsToCrudParams: ({ kods, gads }) => {
    let y = gads
    if (!y) {
      y = new Date().getFullYear() - 1
    }
    return ['kods', kods, 'gads', y]
  }
})
