import * as helpers from './helpers'

const defaultListReducerForVirszemesSlodzuButiskumi = helpers.createListReducer({
    listViewName: 'virszemes_udens_objekts_slodzes_list',
    actionsName: 'virszemesSlodzuButiskumi',
})
export const virszemesSlodzuButiskumiList = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    return defaultListReducerForVirszemesSlodzuButiskumi({state, type, payload, reqId})
}

const defaultListReducerForPazemesSlodzuButiskumi = helpers.createListReducer({
    listViewName: 'pazemes_udens_objekts_slodzes_list',
    actionsName: 'pazemesSlodzuButiskumi',
})
export const pazemesSlodzuButiskumiList = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    return defaultListReducerForPazemesSlodzuButiskumi({state, type, payload, reqId})
}

const defaultEditReducerForVirszemesSlodzuButiskumsIeraksts = helpers.createEditReducer({
    editViewName: 'virszemes_udens_objekts_slodzes_view',
    actionsName: 'virszemesSlodzuButiskums'
})
export const virszemesSlodzuButiskums = (state = helpers.editGetInitialState(), {type, payload}) => {
    return defaultEditReducerForVirszemesSlodzuButiskumsIeraksts({state, type, payload})
}

const defaultEditReducerForPazemesSlodzuButiskumsIeraksts = helpers.createEditReducer({
    editViewName: 'pazemes_udens_objekts_slodzes_view',
    actionsName: 'pazemesSlodzuButiskums'
})
export const pazemesSlodzuButiskums = (state = helpers.editGetInitialState(), {type, payload}) => {
    return defaultEditReducerForPazemesSlodzuButiskumsIeraksts({state, type, payload})
}