import React, {Component} from 'react';
import {connect} from 'react-redux'

import Form from '../forms/Form'
import {
    getDefaultTypeaheadOptionsFromKodifArray,
    getKlasifikatorsFieldCfg
} from '../forms/FormHelpers'
import {getFormatterForKodif} from '../grid/ColumnHelpers'
import {pazemesUdensObjektiRiskiUnIznemumiActions} from '../../actions/pazemesUdensObjektiActions'
import Loading from "../Loading";

const getFormFieldCfgs = ({ metadata, kodif }) => {
    return [
        {
            name: 'gads_sagatavots',
            renderClassName: "bold",
        },
        {
            // TODO izvēle atkarīga no gada
            type: 'autocomplete',
            autocompleteCfg: {
                options: getDefaultTypeaheadOptionsFromKodifArray(kodif, 'UO_PERIODS')
            },
            name: 'periods',
            editable: false
        },
        {
            name: 'kvantitate',
            type: 'textarea'
        },
        {
            name: 'kvalitate',
            type: 'textarea'
        },
        {
            ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaIznemumi'),
            renderer: getFormatterForKodif(kodif.pazemesUdensObjektaIznemumi),
            name: 'iznemumi'
        },
        {
            name: 'piezimes',
            type: 'textarea'
        }
    ]}

class RiskiUnIznemumiComp extends Component {

    render() {
        const {pazemesUdensObjektsRiskiUnIznemumi, kodif, metadata} = this.props

        const { selectedItem, editable, submiting } = pazemesUdensObjektsRiskiUnIznemumi

        // loading while we have no udensObjekts
        if (!selectedItem) {
            return (<Loading/>)
        }

        const periodsValues = getDefaultTypeaheadOptionsFromKodifArray(kodif, 'UO_PERIODS').map(value => value.tah_id)
        const currentPeriodsIndex = periodsValues.indexOf(selectedItem.gads)

        const onNextPrevYear = (periods) => {
            this.props.fetchAct({ ...selectedItem, periods})
        }

        const nextYear = () => {
            return periodsValues[currentPeriodsIndex + 1]
        }

        const prevYear = () => {
            return periodsValues[currentPeriodsIndex - 1]
        }

        // we cache form field cfgs
        // reload form fields if udensObjekts changes
        if(!this.formFieldCfgs || pazemesUdensObjektsRiskiUnIznemumi.id !== this.prevUoId){
            this.formFieldCfgs = getFormFieldCfgs({
                metadata,
                kodif
            })
            this.prevUoId = selectedItem.id
        }

        return <div className="form-container">
            <div className="atradne-title uo-gads d-flex flex-row-reverse">
                <h4 className="num-periods">
                    <i className="fa fa-caret-left" title={prevYear()} aria-hidden="true" onClick={() => onNextPrevYear(prevYear())}></i>
                    <span>{ selectedItem.gads }</span>
                    <i className="fa fa-caret-right" title={nextYear()} aria-hidden="true" onClick={() => onNextPrevYear(nextYear())}></i>
                </h4>
            </div>
            <Form
                editable={editable}
                submiting={submiting}
                dataObj={selectedItem}
                formFieldCfgs={this.formFieldCfgs}
                onSubmit={this.props.saveAct}
                onReset={this.props.stopEditAct}
                onStartEdit={this.props.startEditAct}
                // formWrapperComp={Row}
                metadataFormCfg="pazemes_udens_objekts_risks_tab_view"
                tiesibasLabot="BAIS_PU_LABOT"
            />
        </div>
    }
}

const mapStateToProps = ({pazemesUdensObjektsRiskiUnIznemumi, kodif, metadata, user}) => ({pazemesUdensObjektsRiskiUnIznemumi, kodif, metadata, user})
const PazemesRiskiUnIznemumi =  connect(mapStateToProps, { ...pazemesUdensObjektiRiskiUnIznemumiActions })(RiskiUnIznemumiComp)
export default PazemesRiskiUnIznemumi
