import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  getTextFilterCfg,
  getEditIconColumn,
  getBooleanSelectFilterCfg,
  getBooleanFormatter,
  getSelectFilterCfg
} from '../grid/ColumnHelpers'

import {guiSet} from '../../actions/uiActions'

import Grid from '../grid/Grid'
import { 
    virszemesSlodzuButiskumsDoubleSelected, 
    virszemesSlodzuButiskumsSelected 
} from '../../actions/slodzuButiskumsActions';

const editIconColumn = getEditIconColumn((row) => (
    {
        type: `loc_virszemes_slodzu_butiskuma_ieraksts`,
        payload: {
            id: row.id,
            kods: row.uo_kods
        }
    }
))

class VirszemesSlodzesSaraksts extends Component {

    rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
            this.props.virszemesSlodzuButiskumsDoubleSelected(row)
        }
    }

    render() {
        const getColumns = ({kodif}) => {
            return [editIconColumn, {
                dataField: 'uo_kods',
                text: 'ŪO kods',
                filterCfg: getTextFilterCfg(),
                width: 100
            }, {
                dataField: 'uo_nosaukums',
                text: 'Ūdens objekts',
                filterCfg: getTextFilterCfg(),
                width: 200
            }, {
                dataField: 'gads',
                text: 'Slodžu izvērtējuma gads',
                filterCfg: {
                    type: 'autocomplete',
                    autocompleteCfg: {
                        remoteSearchViewName: 'virszemes_udens_objekts_slodzes_gads_distinct_search'
                    }
                }
            }, {
                dataField: 'periods',
                text: 'UBAP periods',
                filterCfg: getSelectFilterCfg(kodif.UO_PERIODS)
            }, {
                dataField: 'punktveida',
                text: 'Punktveida slodze',
                formatter: getBooleanFormatter(),
                filterCfg: getBooleanSelectFilterCfg()
            }, {
                dataField: 'izkliedeta',
                text: 'Izkliedētā slodze',
                formatter: getBooleanFormatter(),
                filterCfg: getBooleanSelectFilterCfg()
            }, {
                dataField: 'hidro',
                text: 'Hidromorfoloģiskie pārveidojumi',
                formatter: getBooleanFormatter(),
                filterCfg: getBooleanSelectFilterCfg()
            }, {
                dataField: 'citas',
                text: 'Citas slodzes',
                formatter: getBooleanFormatter(),
                filterCfg: getBooleanSelectFilterCfg()
            }]
        }
        
        if(!this.columns){
            this.columns = getColumns({
                kodif: this.props.kodif
            })
        }

        return (
            <div className="saraksts-wrapper">
                <Grid
                    onRowSelected={this.props.virszemesSlodzuButiskumsSelected}
                    loading={this.props.virszemesSlodzuButiskumiList.loading}
                    selectedId={this.props.virszemesSlodzuButiskumiList.selected}
                    dataList={this.props.virszemesSlodzuButiskumiList.list}
                    totalCount={this.props.virszemesSlodzuButiskumiList.count}
                    columns={this.columns}
                    rowEvents={this.rowEvents}
                />
            </div>
        )
    }
}

const mapStateToProps = ({virszemesSlodzuButiskumiList, kodif, gui, user}) => {
  return {virszemesSlodzuButiskumiList, kodif, gui, user}
}

export default connect(mapStateToProps, {virszemesSlodzuButiskumsSelected, guiSet, virszemesSlodzuButiskumsDoubleSelected})(VirszemesSlodzesSaraksts)
