import React, {Component} from 'react';
import {connect} from 'react-redux'
import {urbumuIeguvesActions, urbumuIeguveRowSelected} from '../../actions/hidrogeoActions'
import Loading from '../Loading'
import SplitterLayout from 'react-splitter-layout'
import Form from '../forms/Form'
import IzraktenisMap from '../karte/IzraktenisMap'
import {guiSet} from '../../actions/uiActions'
import Grid, {columnCfgToBootstrapTableColumn} from '../grid/Grid'
import * as maputils from '../karte/maputils'
import {
  getEditIconColumn,
  getNumberFilterCfg,
  getTextFilterCfg,
} from '../grid/ColumnHelpers'

const  getFormFieldCfgs = () => {
    return [
      { name: 'id', renderClassName: "bold"},
      { name: 'nosaukums', renderClassName: "bold"},
      { name: 'min_gads'},
      { name: 'max_gads'}
    ]
}

const editIconColumn = getEditIconColumn((row) => (
  {
      type: 'loc_urbums',
      payload: {
          id: row.urb_id
      }
  }
  )
)

const getColumns = () => {
  const columns = [editIconColumn, {
      dataField: 'gads',
      text: 'Gads',
      width: 50,
      filterCfg: getNumberFilterCfg()
  },{
      dataField: 'urb_id',
      text: 'Urbuma Nr.',
      width: 100,
      filterCfg: getTextFilterCfg()
  },{
      dataField: 'p_kods',
      text: 'P kods',
      width: 100,
      filterCfg: getTextFilterCfg()
  },{
      dataField: 'udens_nesejslanis',
      text: 'Ūdens nesējslānis',
      width: 100
  },{
      dataField: 'fakt_ieguve',
      text: 'Faktiskā ieguve, tūkst. m³/gadā',
      width: 100
  },{
      dataField: 'apr_ieguve',
      text: 'Aprēķinātā ieguve, m³/d',
      width: 100
  }]
  return columns.map( col=> columnCfgToBootstrapTableColumn(col))
}

class UrbumaIeguve extends Component {

    mapPaneSizeChange = (w) => {
      this.props.guiSet('urbumu_ieguve_kartina_karte_width', w);
    }

    mapPaneSizeChangeHor = (h) => {
      this.props.guiSet('urbumu_ieguve_kartina_karte_height', h);
    }

    handleOnSelect = (row, isSelect) => {
      this.props.urbumuIeguveRowSelected({id: row.id, isSelect})
    }

    render() {

      const {urbumu_ieguve, gui} = this.props

      const {selectedItem, selectedUrbums, selectedIeguveId} = urbumu_ieguve

      if (!selectedItem) {
          return (<Loading/>)
      }

      if(!this.formFieldCfgs){
        this.formFieldCfgs = getFormFieldCfgs()
      }

      if(!this.columns){
        this.columns = getColumns()
      }

      const vectorLayerCfg = {
        urbumi: {
          title: 'Urbumi',
          features: selectedItem.urbumi,
          styles: maputils.urbumiStyles,
          tooltipFunc: (feat) => `${feat.get('id')}: ${feat.get('urb_nr_pirmavota') ? feat.get('urb_nr_pirmavota') : ''} ${feat.get('urb_adrese') ? feat.get('urb_adrese') : ''}`,
          selected: selectedUrbums,
          legend: maputils.urbumiLegend
        }
      }

      const largeScreen = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 900

      return (
        <SplitterLayout
          key={largeScreen ? 'hor' : 'ver'}
          vertical={!largeScreen}
          customClassName="pane-paddings-hor"
          percentage
          primaryIndex={0}
          secondaryInitialSize={largeScreen ? gui.urbumu_ieguve_kartina_karte_width : gui.urbumu_ieguve_kartina_karte_height}
          onSecondaryPaneSizeChange={largeScreen ? this.mapPaneSizeChange : this.mapPaneSizeChangeHor}
        >
          <div className={'form-pane-scrollable' + (largeScreen ? '' : ' topside')}>
            <Form
                editable={false}
                dataObj={selectedItem}
                formFieldCfgs={this.formFieldCfgs}
                metadataFormCfg="urbumi_ieguves_vietas_view"
            />
            <h4>Ieguve</h4>
            <Grid
              onRowSelected={this.props.urbumuIeguveRowSelected}
              selectedId={selectedIeguveId}
              dataList={selectedItem.urbumi_ieguves}
              totalCount={selectedItem.urbumi_ieguves.length}
              columns={this.columns}
              enablePaging={false}
            />
          </div>
          <div className="rightside">
            <div className="karte-wrapper">
                <IzraktenisMap mapId="urbumu_ieguve_kartina" vectorLayerCfgs={vectorLayerCfg}/>
            </div>
          </div>
        </SplitterLayout>
      )
    }
}

const mapStateToProps = ({urbumu_ieguve, gui}) => ({urbumu_ieguve, gui})
export default connect(mapStateToProps, {...urbumuIeguvesActions, guiSet, urbumuIeguveRowSelected})(UrbumaIeguve)
