import React, {Component} from 'react';
import {connect} from 'react-redux'
import {urbumuAtradnesActions, urbumuAtradneUrbumsSelected} from '../../actions/hidrogeoActions'
import Loading from '../Loading'
import SplitterLayout from 'react-splitter-layout'
import Form from '../forms/Form'
import IzraktenisMap from '../karte/IzraktenisMap'
import {guiSet} from '../../actions/uiActions'
import BootstrapTable from 'react-bootstrap-table-next'
import {columnCfgToBootstrapTableColumn} from '../grid/Grid'
import * as maputils from '../karte/maputils'
import {
    getBadgeFormatterForKodif,
    getEditIconColumn, getSelectFilterCfg
} from '../grid/ColumnHelpers'
import { ATRADNES_PAZEMES_UDENU_OBJEKTI_NOT_FOUND } from '../../constants';

const  getFormFieldCfgs = ({kodif}) => {
    return [
      { name: 'id', renderClassName: "bold"},
      { name: 'nosaukums', renderClassName: "bold"},
      { 
        name: 'pazemes_udenu_objekti',
        renderer: (puo) => `${puo === ATRADNES_PAZEMES_UDENU_OBJEKTI_NOT_FOUND ? '-' : puo}`
      },
      { name: 'pazemes_udenu_veidi'},
      { name: 'merkis'},
      {
        name: 'udizm_kods',
        renderer: (kods, row) => `${kods} - ${row.udizm_nos}`
      },
      { name: 'atr_vieta'},
      { name: 'izpete'},
      { name: 'aizsargjoslas'},
      { name: 'papildus'},
      { name: 'prog_udens_paterins',
        renderer: (pat, row) => <>{pat} m<sup>3</sup>/dnn līdz {row.prog_udens_paterins_lidz}. gadam</>
      }
    ]
}

const editIconColumn = getEditIconColumn((row) => (
  {
      type: 'loc_urbums',
      payload: {
          id: row.id
      }
  }
  )
)

const getColumns = ({kodif}) => {
  const columns = [editIconColumn, {
      dataField: 'id',
      text: 'DB numurs',
      width: 100
  },{
      dataField: 'urb_nr_pirmavota',
      text: 'Numurs pēc pirmavota',
      width: 180
  },{
      dataField: 'urb_adrese',
      text: 'Urbuma adrese un piederība'
  },{
      dataField: 'urb_organizacija',
      text: 'Organizācija, kas veica urbšanu',
      width: 240
  }, {
      dataField: 'tehniskais_stavoklis',
      text: 'Urbuma stāvoklis',
      formatter: (cellContent) => {
          return getBadgeFormatterForKodif(kodif.urbumiTehniskaisStavoklis)(cellContent)
      },
      width: 120,
      filterCfg: getSelectFilterCfg(kodif.urbumiTehniskaisStavoklis)
  }]
  return columns.map( col=> columnCfgToBootstrapTableColumn(col))
}

class UrbumaAtradne extends Component {

    mapPaneSizeChange = (w) => {
      this.props.guiSet('urbumu_atradne_kartina_karte_width', w);
    }

    mapPaneSizeChangeHor = (h) => {
      this.props.guiSet('urbumu_atradne_kartina_karte_height', h);
    }

    handleOnSelect = (row, isSelect) => {
      this.props.urbumuAtradneUrbumsSelected({id: row.id, isSelect})
    }

    render() {

      const {urbumu_atradne, kodif, gui} = this.props

      const {selectedItem, selectedUrbums} = urbumu_atradne

      if (!selectedItem) {
          return (<Loading/>)
      }

      if(!this.formFieldCfgs){
        this.formFieldCfgs = getFormFieldCfgs({
            kodif: kodif
        })
      }

      if(!this.columns){
        this.columns = getColumns({kodif})
      }

      const vectorLayerCfg = {
        urbumi: {
          title: 'Urbumi',
          features: selectedItem.urbumi,
          styles: maputils.urbumiStyles,
          tooltipFunc: (feat) => `${feat.get('id')}: ${feat.get('urb_nr_pirmavota') ? feat.get('urb_nr_pirmavota') : ''} ${feat.get('urb_adrese') ? feat.get('urb_adrese') : ''}`,
          selected: selectedUrbums,
          legend: maputils.urbumiLegend
        }
      }

      const largeScreen = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 900

      return (
        <SplitterLayout
                  key={largeScreen ? 'hor' : 'ver'}
                  vertical={!largeScreen}
                  customClassName="pane-paddings-hor"
                  percentage
                  primaryIndex={0}
                  secondaryInitialSize={largeScreen ? gui.urbumu_atradne_kartina_karte_width : gui.urbumu_atradne_kartina_karte_height}
                  onSecondaryPaneSizeChange={largeScreen ? this.mapPaneSizeChange : this.mapPaneSizeChangeHor}>
        <div className={'form-pane-scrollable' + (largeScreen ? '' : ' topside')}>
              <Form
                  editable={false}
                  dataObj={selectedItem}
                  formFieldCfgs={this.formFieldCfgs}
                  metadataFormCfg="urbumi_atradnes_view"
              />
              <h4>Atradnes urbumi</h4>
              <div>
              <BootstrapTable
                  bootstrap4
                  striped
                  hover
                  condensed
                  keyField="id"
                  data={ selectedItem.urbumi }
                  columns={ this.columns }
                  selectRow={{
                        mode: 'radio',
                        clickToSelect: true,
                        hideSelectColumn: true,
                        classes: 'selection-row',
                        onSelect: this.handleOnSelect,
                        selected: selectedUrbums ? [selectedUrbums] : []
                  }}
              />
              </div>
        </div>
        <div className="rightside">
          <div className="karte-wrapper">
              <IzraktenisMap mapId="urbumu_atradne_kartina" vectorLayerCfgs={vectorLayerCfg}/>
          </div>
        </div>
        </SplitterLayout>
      )
    }
}

const mapStateToProps = ({urbumu_atradne, kodif, gui}) => ({urbumu_atradne, kodif, gui})
export default connect(mapStateToProps, {...urbumuAtradnesActions, guiSet, urbumuAtradneUrbumsSelected})(UrbumaAtradne)
