import {callApi, getDefaultEditActions, pushUrlToBrowserHistory, addExtraFilterToQuery} from './helpers'
import * as constants from '../constants'
import {redirect} from 'redux-first-router'

const addQueryParamsToResults = (crudParam) => (values) => {
  const parskati = {...values}
  parskati.list = parskati.list.map((p, i)=> {
    const query = {offset: i, ...crudParam}
    delete query.payload
    return {
      ...p,
      query
    }
  })
  return parskati
}

export const loadGeofondParskatsMap = (query) => (dispatch, getState) => {
  const mapSize = getState().gui['parskats_saraksts_karte_size']
  const crudParam = addExtraFilterToQuery(query, mapSize)
  return dispatch(callApi({
      viewName: 'parskats_map',
      crudMethod: 'listPaging',
      crudParam,
      resultTransformator: addQueryParamsToResults(crudParam)
  }))
}

export const loadGeofondParskats = (query) => (dispatch, getState) => {
  const crudParam = addExtraFilterToQuery(query)
  return dispatch(callApi({
      viewName: 'parskats_list',
      crudMethod: 'listPaging',
      crudParam,
      resultTransformator: addQueryParamsToResults(crudParam)
  }))
}

export const parskatsActions = getDefaultEditActions({
  actionTypeName : 'parskats',
  stateName: 'parskats',
  combinedPage: true,
  apiViewName: 'parskats_view',
  hasKoordsField: true,
  geomType: 'MultiPoint',
  overridedActions: {
    fetchAct: ((argsObj) => (dispatch, getState) => {
      const {query} = getState().location
      if(argsObj && argsObj['id'] === 'jauns'){
        return dispatch(callApi({
          viewName: 'parskats_view',
          crudMethod: 'new'
        }))
      } else if(query && query['offset'] != null){
          const crudParam = {...query}
          const page = query['p'] ?? 1
          crudParam.p = (page - 1) * constants.DEFAULT_SARAKSTS_PAGE_SIZE + 1 + parseInt(query['offset'])
          crudParam.ps = 1

          return dispatch(callApi({
              viewName: 'parskats_view',
              crudMethod: 'listPaging',
              crudParam
          }))
      } else {
        return dispatch(callApi({
            viewName: 'parskats_view',
            crudMethod: 'get',
            crudParam: argsObj['id']
        }))
    }
    })
  }
})

export const pieklutPielikumiemAction = getDefaultEditActions({
  actionTypeName : 'VGF_faila_piekļuve',
  stateName: 'VGF_faila_piekļuve',
  apiViewName: 'VGF_faila_piekļuve',
  afterSave: (dispatch, getState, result) => {
    dispatch(parskatsActions.fetchAct({id : result.id}))
  },
})

export const koordChangeAct = (koord) => {
  console.log("koordChangeAct", koord)
  return { type: 'parskats_koord_changed', payload: koord }
}

export const parskatsSelected = (id) => {
 return { type: 'parskati_karte_selected', payload:id }
}


export const openParskats = (id) => (dispatch, getState) => {
  //remember prev url
  pushUrlToBrowserHistory(getState)
  dispatch(redirect({
    type: 'loc_parskats',
    payload: {
        id: id
    }
   }))
}

export const openParskatsWithFilterFromList = (query, inv_numurs) => {
  return openParskatsWithFilter(query, inv_numurs, 'loc_parskats_from_list')
}

export const openParskatsWithFilter = (query, inv_numurs, loc = 'loc_parskats') => (dispatch, getState) => {
  //remember prev url
  pushUrlToBrowserHistory(getState)
  dispatch(redirect({
    type: loc,
    payload: {
      id: `filter${query.offset}`,
      inv_numurs
    },
    query
   }))
}

export const openParskatsTopMenu = (field, value, options) => (dispatch, getState) => {
  if (value) {
    pushUrlToBrowserHistory(getState);
    dispatch(redirect({
      type: 'loc_parskats',
      payload: {
        id: value,
      }
    }))
    dispatch({ type: 'parskats_top_menu_selected', payload: {options, id: value}})
  } else {
    dispatch({type: 'parskats_top_menu_clear'})
  }
}

export const onParskatsNextOrPrevInTopMenu = (nextOrPrev) => (dispatch, getState) => {
  pushUrlToBrowserHistory(getState);
    dispatch(redirect({
      type: 'loc_parskats',
      payload: {
        id: nextOrPrev,
      }
    }))
  dispatch({ type: 'parskats_top_menu_selected', payload: {id: nextOrPrev}})
}

export const loadGeofondRubrikas = (query) => (dispatch, getState) => {
  return dispatch(callApi({
      viewName: 'rubrika_list',
      crudMethod: 'listPaging',
      crudParam: addExtraFilterToQuery(query)
  }))
}

export const rubrikaActions = getDefaultEditActions({
  actionTypeName : 'rubrika',
  stateName: 'rubrika',
  apiViewName: 'rubrika_view'
})

export const rubrikaSelected = (id) => {
 return { type: 'rubrika_selected', payload:id }
}


export const openRubrika = (id) => (dispatch, getState) => {
  //remember prev url
  pushUrlToBrowserHistory(getState)
  dispatch(redirect({
    type: 'loc_rubrika',
    payload: {
        id: id
    }
   }))
}
