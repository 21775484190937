import * as helpers from './helpers'

const defaultListReducerForUdensObjekti = helpers.createListReducerWithMapClicking({
  listViewName: 'virszemes_udens_objekti_list',
  actionsName: 'virszemes_udens_objekts',
  mapIdName: 'udens_objekti_saraksts',
  layerIdName: ['virszemesUdensObjekti', 'virszemesUdensObjektiLabels']
})
export const virszemesUdensObjekti = (state = helpers.listGetInitialState(), {type, payload, reqId, mapId, layerId}) => {
  return defaultListReducerForUdensObjekti({state, type, payload, reqId, mapId, layerId})
}

const defaultEditReducerForUdensObjekts = helpers.createEditReducer({
  editViewName: 'virszemes_udens_objekts_view',
  actionsName: 'virszemes_udens_objekts'
})
export const virszemesUdensObjekts = (state = helpers.editGetInitialState(), {type, payload}) => {
  return defaultEditReducerForUdensObjekts({state, type, payload})
}

const defaultEditReducerForAtrasanas = helpers.createEditReducer({
  editViewName: 'virszemes_udens_objekts_atrasanas_view',
  actionsName: 'virszemes_udens_objekts_atrasanas'
})
export const virszemesUdensObjektsAtrasanas = (state = helpers.editGetInitialState(), {type, payload}) => {
  switch (type) {
    // clear atrasanas when atradne clears
    case 'virszemes_udens_objekts_clear':
      return helpers.editGetInitialState()
    default:
      return defaultEditReducerForAtrasanas({state, type, payload})
  }
}

const defaultEditReducerForUoPasakumi = helpers.createEditReducer({
  editViewName: 'uo_pasakumi_view',
  actionsName: 'uoPasakumi'
})
export const uoPasakumi = (state = helpers.editGetInitialState(), {type, payload}) => {
  switch (type) {
    // clear atrasanas when atradne clears
    case 'virszemes_udens_objekts_clear':
      return helpers.editGetInitialState()
    default:
      return defaultEditReducerForUoPasakumi({state, type, payload})
  }
}


const defaultEditReducerForUoEko = helpers.createEditReducer({
  editViewName: 'virszemes_udens_objekts_kvalitate_ekologija',
  actionsName: 'virszemes_udens_objekts_eko'
})
export const virszemesUdensObjektsEko = (state = helpers.editGetInitialState(), {type, payload}) => {
  switch (type) {
    // clear atrasanas when atradne clears
    case 'virszemes_udens_objekts_clear':
      return helpers.editGetInitialState()
    default:
      return defaultEditReducerForUoEko({state, type, payload})
  }
}

const defaultEditReducerForUoKim = helpers.createEditReducer({
  editViewName: 'virszemes_udens_objekts_kvalitate_kimiska',
  actionsName: 'virszemes_udens_objekts_kim'
})
export const virszemesUdensObjektsKim = (state = helpers.editGetInitialState(), {type, payload}) => {
  switch (type) {
    // clear atrasanas when atradne clears
    case 'virszemes_udens_objekts_clear':
      return helpers.editGetInitialState()
    default:
      return defaultEditReducerForUoKim({state, type, payload})
  }
}

const defaultEditReducerForUoRiski = helpers.createEditReducer({
  editViewName: 'virszemes_udens_objekts_risks_un_iznemumi_tab_view',
  actionsName: 'virszemes_udens_objekts_risks_un_iznemumi_tab_view'
})
export const virszemesUdensObjektsRiski = (state = helpers.editGetInitialState(), {type, payload}) => {
  switch (type) {
    // clear atrasanas when atradne clears
    case 'virszemes_udens_objekts_clear':
      return helpers.editGetInitialState()
    default:
      return defaultEditReducerForUoRiski({state, type, payload})
  }
}

const defaultEditReducerForUoSlodzes = helpers.createEditReducer({
  editViewName: 'virszemes_udens_objekts_slodzes_tab_view',
  actionsName: 'virszemes_udens_objekts_slodzes_tab_view'
})
export const virszemesUdensObjektsSlodzes = (state = helpers.editGetInitialState(), {type, payload}) => {
  switch (type) {
    // clear atrasanas when atradne clears
    case 'virszemes_udens_objekts_clear':
      return helpers.editGetInitialState()
    default:
      return defaultEditReducerForUoSlodzes({state, type, payload})
  }
}
